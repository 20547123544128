import React from "react";
import { Route } from "react-router-dom";
import Helmet from 'react-helmet';

const RouteWithTitle = ({ title, ...props }) => (
    <>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <Route {...props} />
    </>
)

export default RouteWithTitle;
