import React, { useMemo } from "react";
import { lexicons } from "../../services/lexicons";

export const Keywords = ({ data }) =>
  data.map((k, i) => (
    <span
      className="rounded bg-gray-200 inline-block px-2 py-1 mr-1 text-xs mb-1"
      key={i}
    >
      {k.text.substring(0, k.highlight[0])}
      <span className="text-red-600 font-bold">
        {k.text.substring(k.highlight[0], k.highlight[1])}
      </span>
      {k.text.substring(k.highlight[1])}
    </span>
  ));

export const Sample = ({ data, phoneme }) => (
  <span className="rounded bg-gray-200 inline-block px-2 py-1">
    <strong>{data[0]}: </strong>
    {data[1].split(" ").map((s, i) => (
      <span
        key={i}
        style={{ fontFamily: "monospace" }}
        className={
          s.replace(/%$/, "").replace(/"$/, "") === phoneme
            ? "font-bold text-red-600 px-0.5"
            : "px-0.5"
        }
      >
        {s}
      </span>
    ))}
  </span>
);

const Phoneme = ({
  ipa,
  deepzen,
  keywords,
  sample,
  onAddIPA,
  onAddDeepzen,
}) => (
  <tr className="divide-x">
    <td
      className="px-2 py-1 text-center text-base font-bold"
      style={{ fontFamily: "monospace" }}
      title="Click to add to DeepZen input"
      onClick={() => onAddIPA(ipa)}
    >
      {ipa}
    </td>
    <td
      className="px-2 py-1 text-center text-base font-bold"
      style={{ fontFamily: "monospace" }}
      onClick={() => onAddDeepzen(deepzen)}
    >
      {deepzen}
    </td>
    <td className="px-2 pt-1 max-w-40">
      <Keywords data={keywords} />
    </td>
    <td className="px-2 py-1">
      <Sample phoneme={deepzen} data={sample} />
    </td>
  </tr>
);

const Help = ({ language, onAddDeepzen, onAddIPA }) => {
  const consonants = useMemo(() =>
    lexicons[language].phonemes.filter((p) => p.type === "consonant")
  );
  const vowels = useMemo(() =>
    lexicons[language].phonemes.filter((p) => p.type === "vowel")
  );

  return (
    <div className="ml-8">
      <div className="text-xl mb-2 mt-1">Phone Scheme</div>

      <div className="shadow rounded-lg bg-white self-start">
        <table>
          <thead>
            <tr className="border-b divide-x">
              <th className="px-2 py-1 w-20 text-center">IPA</th>
              <th className="px-2 py-1 w-20 text-center">DeepZen</th>
              <th className="px-2 py-1" style={{ width: 400 }}>
                Key Words
              </th>
              <th className="px-2 py-1">Transcription Example</th>
            </tr>
          </thead>
          <tbody className="divide-y">
            <tr>
              <td
                colSpan={4}
                className="bg-gray-100 text-center font-bold py-1"
              >
                Consonants
              </td>
            </tr>
            {consonants.map((p, i) => (
              <Phoneme
                key={i}
                onAddDeepzen={onAddDeepzen}
                onAddIPA={onAddIPA}
                {...p}
              />
            ))}
            <tr>
              <td
                colSpan={4}
                className="bg-gray-100 text-center font-bold py-1"
              >
                Vowels
              </td>
            </tr>
            {vowels.map((p, i) => (
              <Phoneme
                key={i}
                onAddDeepzen={onAddDeepzen}
                onAddIPA={onAddIPA}
                {...p}
              />
            ))}
          </tbody>
        </table>
      </div>

      <div className="shadow rounded-lg bg-white mt-4">
        <table className="w-full">
          <tbody className="divide-y">
            <tr>
              <td
                colSpan={4}
                className="bg-gray-100 text-center font-bold py-1"
              >
                Additional Symbols
              </td>
            </tr>
            <tr className="border-b divide-x">
              <th className="px-2 py-1 w-20 text-center"></th>
              <th className="px-2 py-1 w-20 text-center">IPA</th>
              <th className="px-2 py-1 w-20 text-center">DeepZen</th>
            </tr>
            <tr className="divide-x">
              <td className="px-2 py-1 text-center font-bold">
                Primary Stress
              </td>
              <td
                className="px-2 py-1 text-center font-bold hover:bg-gray-300 cursor-pointer"
                style={{ fontFamily: "monospace" }}
                onClick={() => onAddIPA("ˈ")}
              >
                '
              </td>
              <td
                className="px-2 py-1 text-center font-bold hover:bg-gray-300 cursor-pointer"
                style={{ fontFamily: "monospace" }}
                onClick={() => onAddDeepzen('"')}
              >
                "
              </td>
            </tr>
            <tr className="divide-x">
              <td className="px-2 py-1 text-center font-bold">
                Secondary Stress
              </td>
              <td
                className="px-2 py-1 text-center font-bold hover:bg-gray-300 cursor-pointer"
                style={{ fontFamily: "monospace" }}
                onClick={() => onAddIPA("ˌ")}
              >
                ˌ
              </td>
              <td
                className="px-2 py-1 text-center font-bold hover:bg-gray-300 cursor-pointer"
                style={{ fontFamily: "monospace" }}
                onClick={() => onAddDeepzen("%")}
              >
                %
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Help;
