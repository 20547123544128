export const JOB_API_URL = window._env_["REACT_JOB_API_URL"];
export const API_URL = window._env_["REACT_APP_API_URL"]
export const AUTH_URL = window._env_["REACT_APP_AUTH_URL"]
export const AUTH_CLIENT = window._env_["REACT_APP_AUTH_CLIENT"]
export const PUSHER_TOKEN = window._env_["PUSHER_TOKEN"]
export const STRIPE_KEY = window._env_["REACT_APP_STRIPE_KEY"] 
export const INSPECTLET_KEY = window._env_["REACT_APP_INSPECTLET_KEY"] || "832473528";
export const SENTRY_DSN = window._env_["SENTRY_DSN"];
export const ENVIRONMENT = window._env_["ENVIRONMENT"];
export const SENTRY_SAMPLING_RATE = window._env_["SENTRY_SAMPLING_RATE"] ?? 1.0;
export const STREAMING_URL = window._env_["STREAMING_URL"];
