import React from "react";
import { useUser } from "../../common/auth";
import Card, { CardBody } from "../../common/Card";
import PageHeader from "../../common/PageHeader";
import Alert from "../../common/Alert";
import { SubscriptionInfo } from "../../common/SubscriptionInfo";

const Developers = () => {
  const user = useUser();

  if (!user) return null;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    
      <SubscriptionInfo user={user} />
      
      <PageHeader title="Developers" className="mt-12" />

      <Card title="API Documentation" className="mb-4">
        <CardBody>
          <div className="flex justify-between items-center">
            DeepZen offers a text-to-speech API.
            <a
              target="_blank"
              href="https://developers.deepzen.io"
              className="inline-flex items-center px-4 py-1 rounded-md bg-blue-600 text-white"
            >
              <svg
                class="w-4 h-4 inline mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                ></path>
              </svg>
              View text-to-speech API documentation
            </a>
          </div>
        </CardBody>
      </Card>

      <Card title="API Key" className="mb-4">
        <CardBody>
          {user.business.subscription?.plan !== "professional" ? (
            <input
              className="form-input w-full"
              readOnly
              value={user?.business.api_token}
              onClick={(e) => e.target.select()}
            />
          ) : (
            <Alert type="warning" className="my-1" link="/pricing">
              <span>
                Upgrade your account to Start-Up/Scale-Up to get api key access
              </span>
            </Alert>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default Developers;
