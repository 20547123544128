import React from "react";
import AppIndex from "./layouts/AppIndex/";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./tailwind.output.css";
import PrivateRoute from "./common/PrivateRoute";
import LoginStart from "./screens/Login/Start";
import LoginFinish from "./screens/Login/Finish";
import Logout from "./screens/Logout";
import RouteWithTitle from "./common/RouteWithTitle";
import Streaming from "./screens/Streaming";
import ActivateAccount from "./screens/ActivateAccount";
import BusinessInvitationsAccept from "./screens/BusinessInvitationsAccept";
import Payment3DCallback from "./screens/PaymentMethods/Payment3DCallback";

function App() {
  return (
    <Router>
      <Switch>
        <RouteWithTitle
          exact={true}
          title="DeepZen App"
          component={LoginStart}
          path="/auth/login/start"
        />
        <RouteWithTitle
          exact={true}
          title="DeepZen App"
          component={LoginFinish}
          path="/auth/login/finish"
        />
        <RouteWithTitle
          exact={true}
          title="Activate Your Account"
          component={ActivateAccount}
          path="/auth/activate/:token"
        />
        <RouteWithTitle
          exact={true}
          title="DeepZen App"
          component={Logout}
          path="/auth/logout"
        />
        <RouteWithTitle
          exact={true}
          title="DeepZen App"
          component={BusinessInvitationsAccept}
          path="/invitations/:id/accept"
        />
        <RouteWithTitle
          exact={true}
          title="DeepZen App"
          component={Streaming}
          path="/streaming"
        />
        <RouteWithTitle
          exact={true}
          title="DeepZen App"
          component={Payment3DCallback}
          path="/billing/3ds"
        />
        <PrivateRoute path="/">
          <AppIndex />
        </PrivateRoute>
      </Switch>
    </Router>
  );
}

export default App;
