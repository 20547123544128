import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import PageHeader from "../../common/PageHeader";
import Button from "../../common/Button";
import Spinner from "../../common/Spinner";
import { Icon, Menu, Dropdown } from "antd";
import Collections from "../../common/Collections/list";
import CollectionBulkActions, {
  bulkActionTypes
} from "../../common/Collections/bulkActions";
import Pagination from "../../common/Pagination";
import classNames from "classnames";
import Search from "../../common/Search";
import CloseIcon from "../../Icons/CloseIcon";
import { useUser, hasUserFullAccess } from "../../common/auth";
import { SubscriptionInfo } from "../../common/SubscriptionInfo";
import { InspectletSendApiError } from "../../common/Inspectlet";
const SubMenu = Menu.SubMenu;

const SlideShowIndex = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const user = useUser();
  const collectionRef = useRef();
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);
  const [resultCount, setResultCount] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [ordering, setOrdering] = useState("");
  const [sortData, setSortData] = useState({ sort: null, sortDirIndex: 0 });
  const [selectedItems, setSelectedItems] = useState({});
  const [selectedBulkAction, setSelectedBulkAction] = useState(null);
  const [selectAllResults, setSelectAllResults] = useState(false);
  
  const fetchSlideShows = (
    _page,
    _ordering,
    _searchValue,
    _selectedCollection
  ) => {
    setTableLoading(true);
    let pg = _page && _page.page ? _page.page : 1;
    axios
      .get(
        `slideshows/?page=${pg}&ordering=${_ordering}&voice_maker_only=1&search=${_searchValue}&collectionId=${
          _selectedCollection ? _selectedCollection.id : ""
        }`
      )
      .then((res) => {
        setTableLoading(false);
        setSelectAllResults(false);
        setSelectedItems({});
        setData(res.data.results);
        setResultCount(res.data.count);
      })
      .catch((e) => {
        setError(e);
        setTableLoading(false);
        setSelectAllResults(false);
        setSelectedItems({});
        InspectletSendApiError(e);
      });
  };

  useEffect(() => {
    fetchSlideShows(page, ordering, searchQuery, selectedCollection);
  }, []);

  if (!user) return null;

  const isButtonsActive = hasUserFullAccess(user);

  const handleCollection = (e) => {
    setSelectedCollection(e);
    fetchSlideShows(page, ordering, searchQuery, e);
  };

  const onClickPanelHeader = (column) => {
    if (column === sortData.sort) {
      sortData.sortDirIndex =
        sortData.sortDirIndex === 2 ? 0 : sortData.sortDirIndex + 1;
    } else {
      sortData.sortDirIndex = 0;
    }
    sortData.sort = column;
    setSortData({ ...sortData });

    let _ordering = "";
    if (sortData.sortDirIndex === 0) {
      _ordering = "-" + sortData.sort;
    } else if (sortData.sortDirIndex === 1) {
      _ordering = sortData.sort;
    }

    setOrdering(_ordering);
    fetchSlideShows(page, _ordering, searchQuery, selectedCollection);
  };

  const onChangePage = (e) => {
    setPage(e);
    fetchSlideShows(e, ordering, searchQuery, selectedCollection);
  };

  const onSearch = (e) => {
    setSearchQuery(e);
    fetchSlideShows(page, ordering, e, selectedCollection);
  };

  const onChangeSelectAll = (e) => {
    const items = {};
    if (e.target.checked) {
      data
        .filter((result) => !!result.title)
        .map((result) => (items[result.id] = e.target.checked));
    }
    setSelectedItems(items);
    setSelectAllResults(false);
  };

  const onChangeSelectedInfoCheckbox = (e) => {
    if (e.target.checked) {
      const items = {};
      if (data && data.length > 0) {
        data
          .filter((result) => !!result.title)
          .map((result) => (items[result.id] = e.target.checked));
      }
      setSelectedItems(items);
    } else {
      setSelectedItems({});
      setSelectAllResults(false);
    }
  };

  const onClickSelectAllResults = (e) => {
    if (e) {
      const items = {};
      data
        .filter((result) => !!result.title)
        .map((result) => (items[result.id] = true));
      setSelectedItems(items);
    }
    setSelectAllResults(e);
  };

  const onRowCheckboxSelect = (e, id) => {
    let items = {};
    if (e.target.checked) {
      items = { ...selectedItems };
      items[id] = e.target.checked;
    } else {
      Object.keys(selectedItems)
        .filter((key) => key !== id)
        .map((key) => (items[key] = true));
    }
    setSelectedItems(items);
    setSelectAllResults(false);
  };

  const reload = () => {
    collectionRef.current.reload();
    fetchSlideShows(page, ordering, searchQuery, selectedCollection);
  };

  const onClearCollection = () => {
    collectionRef.current.unSelectCollection();
    setSelectedCollection(null);
    fetchSlideShows(page, ordering, searchQuery, null);
  };

  const onClickNewVideo = (e) => {
    if (!isButtonsActive) {
      e.preventDefault();
    }
  };

  const panelHeader = () => (
    <div className="w-full">
      {Object.keys(selectedItems).length == 0 ? (
        <div className="flex w-full items-center md:grid md:grid-cols-2 md:gap-4">
          <button
            disabled={tableLoading}
            onClick={() => onClickPanelHeader("title")}
            className="w-full py-3 pr-4 text-base font-medium text-left focus:outline-none flex items-center"
          >
            <input
              type="checkbox"
              defaultChecked={false}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => onChangeSelectAll(e)}
              className="focus:ring-indigo-500 h-4 w-4 mx-5 text-indigo-600 border-gray-300 rounded indeterminate:ring-500"
            />

            <span className="mr-auto">Title</span>
            {sortData.sort === "title" && sortData.sortDirIndex !== 2 && (
              <Icon
                type={sortData.sortDirIndex === 0 ? "arrow-up" : "arrow-down"}
                style={{ fontSize: 12 }}
              />
            )}
          </button>
          <button
            disabled={tableLoading}
            onClick={() => onClickPanelHeader("created_at")}
            className="w-full py-3 px-0 pr-4 text-base font-medium text-left focus:outline-none flex items-center"
          >
            <span className="mr-auto">Date</span>
            {sortData.sort === "created_at" && sortData.sortDirIndex !== 2 && (
              <Icon
                type={sortData.sortDirIndex === 0 ? "arrow-up" : "arrow-down"}
                style={{ fontSize: 12 }}
              />
            )}
          </button>
        </div>
      ) : (
        <div className="flex w-full items-center border-gray-200 border-t">
          <div className="flex items-center py-3">
            <input
              ref={(input) => {
                if (input) {
                  if (Object.keys(selectedItems).length === data.length) {
                    input.indeterminate = false;
                    input.checked = true;
                  } else {
                    input.indeterminate = true;
                    input.checked = false;
                  }
                }
              }}
              type="checkbox"
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => onChangeSelectedInfoCheckbox(e)}
              className="focus:ring-indigo-500 h-4 w-4 mx-5 text-indigo-600 border-gray-300 rounded"
            />

            {!selectAllResults && (
              <span className="pr-5 text-gray-700 font-medium">
                {Object.keys(selectedItems).length} selected
              </span>
            )}

            {selectAllResults && (
              <span className="pr-5 text-gray-700 font-medium">
                {resultCount} selected
              </span>
            )}
          </div>

          <div className="flex items-center py-3 border-gray-200 border-l border-r">
            <Dropdown
              overlay={
                <Menu onClick={(e) => setSelectedBulkAction(e.key)}>
                  <Menu.Item key={bulkActionTypes.delete}>
                    <span className="pl-3">Remove from collection(s)</span>
                  </Menu.Item>
                  <SubMenu
                    title={<span className="pl-3">Add to collection(s)</span>}
                  >
                    <Menu.Item key={bulkActionTypes.existing}>
                      <span className="px-3">Existing Collection</span>
                    </Menu.Item>
                    <Menu.Item key={bulkActionTypes.new}>
                      <span className="px-3">New Collection</span>
                    </Menu.Item>
                  </SubMenu>
                  <Menu.Item key={bulkActionTypes.deleteItems}>
                    <span className="pl-3">Delete item(s)</span>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <a
                className="ant-dropdown-link w-36 flex justify-center items-center text-gray-700 font-medium"
                href="#"
              >
                Bulk Action{" "}
                <Icon
                  type="down"
                  className="flex justify-center items-center pl-3"
                />
              </a>
            </Dropdown>
          </div>

          <div className="flex items-center">
            {!selectAllResults && (
              <Button
                type="native"
                className="ml-5"
                onClick={(e) => onClickSelectAllResults(true)}
              >
                Select all results ({resultCount})
              </Button>
            )}

            {selectAllResults && (
              <div className="flex items-center">
                <span className="text-gray-700 font-medium ml-5">
                  All ({resultCount}) results are selected.
                </span>
                <Button
                  type="native"
                  className="ml-3"
                  onClick={(e) => onClickSelectAllResults(false)}
                >
                  <span className="text-blue-400 font-medium">Undo</span>
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className="max-w-screen-2xl mx-auto flex flex-col flex-1">
      <div className="flex overflow-hidden">
        <div className="hidden lg:block lg:w-1/5 border-r border-t bg-cool-gray-800 min-h-screen">
          <Collections
            ref={collectionRef}
            type="videoMaker"
            handleCollection={(e) => handleCollection(e)}
            handleDefaultCollectionId={(e) => {
              if(e){
                sessionStorage.setItem("videoMakerDefaultCollectionId", e);
              }else{
                sessionStorage.removeItem("videoMakerDefaultCollectionId");
              }
            }}
          />
        </div>
        <div className="w-full md:w-4/5 mx-auto px-4 sm:px-6 lg:px-8">
          <SubscriptionInfo user={user} />

          <PageHeader
            title="VideoMaker"
            className="mt-5"
            extra={
              <NavLink
                to="/video/create"
                onClick={(e) => onClickNewVideo(e)}
                className={classNames(
                  "relative inline-flex items-center px-4 py-1.5 my-1 self-end border border-transparent text-sm leading-5 font-medium rounded-full text-white bg-blue-600 shadow-sm hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition ease-in-out duration-150",
                  !isButtonsActive && "cursor-not-allowed opacity-50 focus"
                )}
              >
                <svg
                  className="-ml-1 mr-2 h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                    clipRule="evenodd"
                  />
                </svg>
                <span>New Video Project</span>
              </NavLink>
            }
            subtitle={
              <a
                target="_blank"
                href="https://deepzen.io/guide-videomaker/"
                className="inline-flex items-center px-1 py-1 rounded-md font-medium text-lg leading-5 text-gray-500"
              >
                View VideoMaker user guide for instructions
                <svg
                  class="w-4 h-4 inline ml-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                  ></path>
                </svg>
              </a>
            }
          />

          <div className="shadow-md bg-white rounded-md mb-10">
            {error !== null && <div>An error occurred.</div>}
            {data === null && (
              <div className="p-4 flex">
                <Spinner size={6} />
                <span className="ml-3">Loading projects...</span>
              </div>
            )}

            {data !== null && (
              <div className="block">
                <div className="flex justify-between border-gray-200 border-b py-4">
                  <div className="flex items-center px-4 sm:px-6 leading-6 font-medium text-lg bg-white text-gray-900">
                    {!selectedCollection && (
                      <span className="mr-3">Slide Shows</span>
                    )}
                    {selectedCollection && (
                      <span className="mr-3 flex items-center">
                        Slide Shows - ({selectedCollection.name})
                        <Button
                          type="button"
                          className=""
                          onClick={(e) => onClearCollection()}
                        >
                          <CloseIcon size="1em" />
                        </Button>
                      </span>
                    )}

                    {tableLoading && (
                      <div>
                        <Spinner color="gray" size={5} />
                        <span className="ml-2">Loading..</span>
                      </div>
                    )}
                  </div>
                  <div className="w-72 px-3">
                    <Search label="Search..." onSearch={(e) => onSearch(e)} />
                  </div>
                </div>

                {data.length > 0 && panelHeader()}
              </div>
            )}

            {data !== null && data.length === 0 && (
              <div className="bg-white border-t border-gray-200">
                <div className="p-10 text-center text-lg">No Data</div>
              </div>
            )}

            {data !== null &&
              data.map((slideshow) => {
                const link =
                  slideshow.status === null || slideshow.status === "fail"
                    ? `/video/${slideshow.id}/edit`
                    : `/video/${slideshow.id}`;

                return (
                  <div className="flex-1 items-center border-gray-200 border-t py-5 min-w-0 md:grid md:grid-cols-2 md:gap-4">
                    <div className="flex items-center">
                      <div>
                        <input
                          type="checkbox"
                          onClick={(e) => e.stopPropagation()}
                          checked={
                            selectedItems ? selectedItems[slideshow.id] : false
                          }
                          onChange={(e) => onRowCheckboxSelect(e, slideshow.id)}
                          className="focus:ring-indigo-500 h-4 w-4 mx-5 text-indigo-600 border-gray-300 rounded"
                        />
                      </div>
                      <div className="flex-shrink-0">
                        <div className="w-24">
                          <NavLink to={link}>
                            <img
                              className="max-h-12 max-w-full"
                              src={
                                slideshow &&
                                slideshow.slides[0] &&
                                slideshow.slides[0].image_url
                              }
                              alt=""
                            />
                          </NavLink>
                        </div>
                      </div>
                      <div className="min-w-0">
                        <NavLink
                          to={link}
                          className="text-base leading-5 font-medium text-blue-600 truncate"
                        >
                          {slideshow.title}
                        </NavLink>
                        <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
                          <svg
                            className="w-4 h-4 mr-1"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                            ></path>
                          </svg>
                          <span className="truncate">
                            {slideshow.slides.length} slides
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="items-center grid grid-cols-1 md:grid md:grid-cols-2">
                      <div className="hidden md:block">
                        <div className="text-sm leading-5 text-gray-900">
                          Created on{` `}
                          <time dateTime={slideshow.created_at}>
                            {new Intl.DateTimeFormat().format(
                              new Date(slideshow.created_at)
                            )}
                          </time>
                        </div>
                        <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
                          {slideshow.status === "rendering" && (
                            <>
                              <Spinner color="orange-400" size={4} />
                              <span className="ml-1.5">Rendering video</span>
                            </>
                          )}
                          {!slideshow.status && (
                            <>
                              <svg
                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-blue-400"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                ></path>
                              </svg>
                              Not rendered yet
                            </>
                          )}
                          {slideshow.status === "fail" && (
                            <>
                              <svg
                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                ></path>
                              </svg>
                              Failed
                            </>
                          )}

                          {slideshow.status === "ready" && (
                            <>
                              <svg
                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              Ready to download
                            </>
                          )}
                        </div>
                      </div>
                      <div className="flex items-center justify-end md:justify-center mr-10 mt-5 md:mx-auto md:mt-0 text-sm leading-5 text-gray-500">
                        <div className="flex md:block">
                          <div className="flex">
                            <NavLink
                              to={`/video/${slideshow.id}/edit`}
                              className="relative md:pb-2 pr-3 inline-flex items-center border-none text-sm leading-5 font-medium text-gray-500 focus:outline-none transition ease-in-out duration-150"
                            >
                              <Icon type="edit" style={{ paddingRight: 5 }} />
                              <span>Edit video</span>
                            </NavLink>
                          </div>
                          {slideshow.status === "ready" && (
                            <div className="flex">
                              <NavLink
                                to={link}
                                className="relative inline-flex items-center border-none font-medium text-sm leading-5 text-gray-500 focus:outline-none transition ease-in-out duration-150"
                              >
                                <Icon
                                  type="download"
                                  style={{ paddingRight: 5 }}
                                />
                                <span>Download</span>
                              </NavLink>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            {selectedBulkAction && (
              <CollectionBulkActions
                collectionType="videoMaker"
                bulkActionType={selectedBulkAction}
                selectedItems={selectedItems}
                selectedAll={selectAllResults}
                clearBulkActionType={(e) => setSelectedBulkAction(null)}
                totalItemCount={resultCount}
                searchFilterResults={searchQuery}
                parentCollection={selectedCollection}
                refreshParents={(e) => reload()}
              />
            )}
            <div className="px-5">
              <Pagination
                totalSize={resultCount}
                page={1}
                pageSize={25}
                onChangePage={onChangePage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlideShowIndex;
