import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  parseTranscription,
  convertTranscription,
  alphabets,
} from "../../services/lexicons";
import classNames from "classnames";
import Help, { Keywords, Sample } from "./Help";
import Button from "../../common/Button";
import Alert from "../../common/Alert";
import { Icon, message, Popover, Radio } from "antd";
import axios from "axios";
import PageHeader from "../../common/PageHeader";
import { useDebounce } from "use-debounce/lib";

const Match = ({ match, onChangeStressLevel }) => {
  const content = match.entry ? (
    <div style={{ maxWidth: 400 }}>
      <table className="w-full">
        <tr>
          <th className="w-1/2">DeepZen</th>
          <th className="w-1/2 text-right">IPA</th>
        </tr>
        <tr className="text-lg" style={{ fontFamily: "monospace" }}>
          <td>{match.entry.deepzen}</td>
          <td className="text-right">{match.entry.ipa}</td>
        </tr>
        <tr>
          <th colSpan={2}>Keywords</th>
        </tr>
        <tr>
          <td colSpan={2}>
            <Keywords data={match.entry.keywords} />
          </td>
        </tr>
        <tr>
          <th colSpan={2}>Example</th>
        </tr>
        <tr>
          <td colSpan={2}>
            <Sample phoneme={match.entry.deepzen} data={match.entry.sample} />
          </td>
        </tr>
      </table>

      <div className="mt-4">
        <div className="font-bold">Stress:</div>

        <Radio.Group
          size="small"
          defaultValue={match.stressLevel ?? "no"}
          buttonStyle="solid"
          onChange={(e) =>
            onChangeStressLevel(e.target.value === "no" ? null : e.target.value)
          }
        >
          <Radio.Button value="no">No stress</Radio.Button>
          <Radio.Button value="primary">Primary</Radio.Button>
          <Radio.Button value="secondary">Secondary</Radio.Button>
        </Radio.Group>
      </div>
    </div>
  ) : null;

  const visual = (
    <span
      style={{ fontFamily: "monospace" }}
      className={classNames(
        "mr-1 rounded inline-block py-0.5 px-1.5 font-bold cursor-pointer",
        match.valid
          ? match.stressLevel === "primary"
            ? "bg-green-300"
            : match.stressLevel === "secondary"
            ? "bg-blue-300"
            : "bg-gray-300"
          : "bg-red-300"
      )}
    >
      {match.phoneme}
    </span>
  );

  return match.valid ? (
    <Popover
      content={content}
      title={match.entry.type}
      trigger="hover"
      placement="bottom"
    >
      {visual}
    </Popover>
  ) : (
    visual
  );
};

const Matches = ({ data, onChangeStressLevel }) => {
  return (
    <div>
      {data.map((p, i) => (
        <Match
          key={i}
          match={p}
          onChangeStressLevel={(value) => onChangeStressLevel(i, value)}
        />
      ))}
    </div>
  );
};

const Form = React.forwardRef(
  (
    {
      pos,
      value,
      onChange,
      matches,
      alphabet,
      onChangeAlphabet,
      language,
      isValid,
    },
    ref
  ) => {
    const [synthesizing, setSynthesizing] = useState(false);
    const [playing, setPlaying] = useState(false);

    const synthesize = useCallback(() => {
      const transcription = convertTranscription(
        language,
        alphabet,
        "combilex",
        matches
      );

      setSynthesizing(true);

      axios
        .post("lexicon/synthesize/", { language, transcription, pos })
        .then((res) => {
          const player = new Audio();
          player.src = res.data.wav_url;
          player.onplay = () => setPlaying(true);
          player.onended = () => setPlaying(false);
          player.play();
        })
        .finally(() => setSynthesizing(false));
    }, [matches]);

    const handleStressLevelChange = useCallback((i, stressLevel) => {
      const newMatches = [...matches];
      newMatches[i].stressLevel = stressLevel;

      // Generate transcription with updated stress level
      const newTranscription = convertTranscription(
        language,
        alphabet,
        alphabet,
        newMatches
      );

      onChange(newTranscription);
    });

    return (
      <div className="bg-white p-4 rounded-lg shadow mt-4">
        <div className="flex space-x-4 mb-2 relative">
          <label className="flex items-center">
            <input
              type="radio"
              checked={alphabet === "deepzen"}
              value="deepzen"
              className="mr-1"
              onChange={(e) => onChangeAlphabet(e.target.value)}
            />
            DeepZen
          </label>
          <label className="flex items-center">
            <input
              type="radio"
              checked={alphabet === "ipa"}
              value="ipa"
              className="mr-1"
              onChange={(e) => onChangeAlphabet(e.target.value)}
            />
            IPA
          </label>

          {isValid && (
            <a
              onClick={synthesize}
              className="cursor-pointer absolute right-3 top-10 text-blue-500"
            >
              <Icon
                type={
                  synthesizing
                    ? "loading"
                    : playing
                    ? "pause-circle"
                    : "play-circle"
                }
                spin={synthesizing}
                className="text-2xl z-50"
              />
            </a>
          )}

          {isValid && (
            <a
              onClick={synthesize}
              className="cursor-pointer absolute right-3 top-10 text-blue-500"
            >
              <Icon
                type={
                  synthesizing
                    ? "loading"
                    : playing
                    ? "pause-circle"
                    : "play-circle"
                }
                spin={synthesizing}
                className="text-2xl z-50"
              />
            </a>
          )}
        </div>

        <input
          ref={ref}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="w-full shadow rounded px-4 py-2 text-xl"
          style={{ fontFamily: "monospace" }}
          placeholder="Pronunciation"
        />

        {matches.length > 0 && (
          <div className="h-6 mt-4 flex items-center">
            <Matches
              data={matches}
              onChangeStressLevel={handleStressLevelChange}
            />
          </div>
        )}
      </div>
    );
  }
);

const LexiconConverter = () => {
  const [language, setLanguage] = useState("us");
  const [word, setWord] = useState("");
  const [pos, setPos] = useState("NNP");
  const [debouncedWord] = useDebounce(word, 500);
  const [transcription, setTranscription] = useState("");
  const [matches, setMatches] = useState([]);
  const transcriptionRef = useRef();
  const [alphabet, setAlphabet] = useState("deepzen"); // deepzen, ipa, xsampa, combilex

  const validationErrors = useMemo(() => {
    let errors = [];

    const primaryStressCount = matches.filter(
      (m) => m.stressLevel === "primary"
    ).length;

    const vowels = matches.filter((m) => m.entry?.type === "vowel");
    const invalidEntries = matches.filter((m) => !m.valid);
    const alphabetConfig = alphabets[alphabet];
    const stressSymbolsAsInvalidEntry = invalidEntries.filter(
      (m) =>
        m.phoneme.trim() === alphabetConfig.stress.primary ||
        m.phoneme.trim() === alphabetConfig.stress.secondary
    );

    const optionalStress =
      vowels.filter((m) => m.entry.optionalStress).length > 0;

    if (primaryStressCount > 1) {
      errors.push("There can't be more than one primary stress");
    } else if (stressSymbolsAsInvalidEntry.length > 0) {
      errors.push("The stress should be added to right of the stressed vowel.");
    } else if (invalidEntries.length > 0) {
      errors.push("Please fix invalid phonemes.");
    } else if (primaryStressCount === 0 && !optionalStress) {
      errors.push("There must be one primary stress.");
    } else if (
      vowels.length > 0 &&
      vowels.filter((m) => m.stressLevel === "primary").length === 0 &&
      !optionalStress
    ) {
      errors.push("Primary stress should be after a vowel.");
    }

    return errors;
  }, [matches]);

  const handleAlphabetChange = (newAlphabet) => {
    const newTranscription = convertTranscription(
      language,
      alphabet,
      newAlphabet,
      matches
    );
    const newMatches = parseTranscription(
      language,
      newAlphabet,
      newTranscription
    );

    setTranscription(newTranscription);
    setMatches(newMatches);
    setAlphabet(newAlphabet);
  };

  const handleTranscriptionChange = (value) => {
    const matches = parseTranscription(language, alphabet, value);

    setTranscription(value);
    setMatches(matches);
  };

  useEffect(() => {
    handleTranscriptionChange("");

    if (debouncedWord.trim() === "") return;

    axios
      .post("lexicon/predict/", { word: debouncedWord, language })
      .then((res) => {
        handleTranscriptionChange(
          convertTranscription(
            language,
            "combilex",
            alphabet,
            parseTranscription(language, "combilex", res.data.transcription)
          )
        );
        setPos(res.data.pos);
      })
      .catch((e) => {
        message.error("An error occurred while fetching predictions.");
      });
  }, [debouncedWord, language]);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-12">
      <div className="flex justify-center pb-20">
        <div className="sticky top-4 self-start" style={{ width: 400 }}>
          <div className="font-bold text-2xl text-black mb-2">
            New pronunciation
          </div>
          <div className="relative z-0 flex shadow-sm rounded-md w-full mb-4">
            <button
              type="button"
              onClick={() => setLanguage("us")}
              className={classNames(
                "relative flex-1 flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium",
                language === "us"
                  ? "bg-blue-500 text-white"
                  : "text-gray-700 hover:bg-gray-50"
              )}
            >
              American (en-US)
            </button>
            <button
              type="button"
              onClick={() => setLanguage("uk")}
              className={classNames(
                "-ml-px relative flex-1 flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium",
                language === "uk"
                  ? "bg-blue-500 text-white"
                  : "text-gray-700 hover:bg-gray-50"
              )}
            >
              British (en-UK)
            </button>
          </div>

          <div>
            <input
              className="w-full shadow rounded px-4 py-2 text-xl"
              placeholder="Word"
              value={word}
              onChange={(e) => setWord(e.target.value)}
            />
          </div>

          <Form
            ref={transcriptionRef}
            pos={pos}
            value={transcription}
            onChange={handleTranscriptionChange}
            language={language}
            alphabet={alphabet}
            onChangeAlphabet={handleAlphabetChange}
            matches={matches}
            isValid={transcription.length > 0 && validationErrors.length === 0}
            autoFocus
          />

          <div className="mt-4 flex justify-between text-xs">
            <div className="flex items-center mb-1">
              <span className="inline-block bg-red-300 mr-2 w-3 h-3 rounded-full" />{" "}
              Invalid phoneme
            </div>
            <div className="flex items-center mb-1">
              <span className="inline-block bg-green-300 mr-2 w-3 h-3 rounded-full" />{" "}
              Primary stress
            </div>
            <div className="flex items-center mb-1">
              <span className="inline-block bg-blue-300 mr-2 w-3 h-3 rounded-full" />{" "}
              Secondary stress
            </div>
          </div>

          <Button
            disabled={validationErrors.length > 0}
            type="primary"
            block
            size="xlarge"
            className="mt-4"
          >
            Save
          </Button>

          {transcription.length > 0 && validationErrors.length > 0 && (
            <Alert className="rounded" type="info">
              {validationErrors.join(" ")}
            </Alert>
          )}
        </div>

        <Help
          language={language}
          onAddDeepzen={(v) => {
            //
          }}
          onAddIPA={(v) => {
            //
          }}
        />
      </div>
    </div>
  );
};

export default LexiconConverter;
