import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Spinner from "../../common/Spinner";
import axios from "axios";
import { message } from "antd";
import { InspectletSendApiError } from "../../common/Inspectlet";

const ActivateAccount = () => {
  const { token } = useParams();

  useEffect(() => {
    axios
      .post("auth/activate/", { token })
      .then((res) => {
        message.success("Account activated successfully.");

        setTimeout(() => (window.location.href = "/"), 1000);
      })
      .catch((e) => {
        message.error("This activation token has been used.");
        InspectletSendApiError(e);
      });
  }, []);

  return (
    <div className="flex flex-col w-screen h-screen justify-center items-center">
      <Spinner size="5" />
      <div className="mt-2">Activating your account</div>
    </div>
  );
};

export default ActivateAccount;
