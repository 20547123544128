import React, { useState, useEffect } from "react";
import Badge from "../../common/Badge";
import Pagination from "../../common/Pagination";
import Spinner from "../../common/Spinner";
import { Collapse, Icon, Menu, Dropdown } from "antd";
import moment from "moment";
import "antd/dist/antd.css";
import TextEditor from "../../common/TextEditor";
import Player from "../Streaming/Player";
import Button from "../../common/Button";
import CollectionBulkActions, { bulkActionTypes } from "../../common/Collections/bulkActions";

const { Panel } = Collapse;
const SubMenu = Menu.SubMenu;
const statusColors = {
  fail: "red",
  queued: "orange",
  success: "green",
};

const Results = ({user, onEdit, count, data, loadData, loading, searchQuery, selectedCollection, reload }) => {
  
  const [sortData, setSortData] = useState({ sort: null, sortDirIndex: 0 });
  const [sortDir, setSortDir] = useState(["desc", "asc", ""]);
  const [page, setPage] = useState(null);
  const [activePlayerId, setActivePlayerId] = useState(null);
  const [selectedItems, setSelectedItems] = useState({});
  const [selectedBulkAction, setSelectedBulkAction] = useState(null);
  const [selectAllResults, setSelectAllResults] = useState(false);
  
  useEffect(() => {
      setSelectedItems({});
      setSelectAllResults(false);
  }, [data]);

  const panelRow = (job) => (
    <div className="flex w-full items-center">
      <div className="w-2/5 pr-3 flex items-center">
        <input 
          type="checkbox" 
          onClick={(e) => e.stopPropagation()}
          checked={selectedItems ? selectedItems[job.api_id] : false}
          onChange={(e) => onRowCheckboxSelect(e, job.api_id)}
          className="focus:ring-indigo-500 h-4 w-4 ml-1 mr-5 text-indigo-600 border-gray-300 rounded"/>

        <span className="truncate">{job.title}</span>
      </div>
      <div className="w-1/5 pr-3 flex items-center">
        <span title={job.created_at}>{moment(job.created_at).format("DD/MM/YYYY HH:mm:ss")}</span>
      </div>

      {job.status !== "success" && job.status !== "queued" && (
        <div className="w-2/5 pr-3 pl-3 flex items-center">
          <Badge color={statusColors[job.status]} size="large">
            {job.status}
          </Badge>
        </div>
      )}

      {job.status !== "fail" && (
        <div className="w-2/5 pr-3 pl-3 flex justify-between">
          <Player 
              id={job.api_id} 
              width="300"
              pausePlayer={job.api_id != activePlayerId} 
              audioUrl={job.data && job.data[0] && job.data[0].session.wav_url}
              emitPlayerId={(e) => setActivePlayerId(e)}
          />

          {job.status === "success" && (
            <a
              className="flex items-center"
              href={job.data && job.data[0] && job.data[0].session.wav_url}
              onClick={(e) => e.stopPropagation()}
              download
            >
              <Icon type="download" style={{ paddingRight: 5, paddingLeft: 5 }} /> Download
            </a>
          )}

          {job.status === "queued" && (
            <Badge color={statusColors[job.status]} size="small">
              <Spinner size="5" color="black" /> Synthesizing...
            </Badge>
          )}

        </div>
      )}
    </div>
  );

  const onChangeSelectAll = (e) => {
    const items = {};
    if(e.target.checked){
        data.results
          .map((result) => items[result.api_id] = e.target.checked);
    }
    setSelectedItems(items); 
    setSelectAllResults(false);
  }

  const onRowCheckboxSelect = (e, id) => {
    let items = {};
    if(e.target.checked){
      items = {...selectedItems};
      items[id] = e.target.checked;
    }else{
      Object.keys(selectedItems)
          .filter((key) => key !== id)
          .map((key) => items[key] = true);
    }
    setSelectedItems(items);  
    setSelectAllResults(false);
  }

  const onChangeSelectedInfoCheckbox = (e) => {
    if(e.target.checked){
      const items = {};
      if(data && data.results && data.results.length > 0){
        data.results
          .map((result) => items[result.api_id] = e.target.checked);
      }
      setSelectedItems(items);
    }else{
        setSelectedItems({});
        setSelectAllResults(false);
    }
  }

  const onClickSelectAllResults = (e) => {
    if(e){
      const items = {};
      data.results
          .map((result) => items[result.api_id] = true);
      setSelectedItems(items);
    }
    setSelectAllResults(e);
  }

  const panelHeader = () => (
    <div className="w-full">
      {(Object.keys(selectedItems).length == 0) ? (
        <div className="flex w-full items-center">
            <button
                disabled={loading}
                onClick={() => onClickPanelHeader("title")}
                className="w-2/5 py-3 pr-4 text-base font-medium text-left focus:outline-none flex items-center"
            >

            <input 
                type="checkbox"
                defaultChecked={false} 
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => onChangeSelectAll(e)}
                className="focus:ring-indigo-500 h-4 w-4 mx-5 text-indigo-600 border-gray-300 rounded indeterminate:ring-500 text-indigo-600"/> 

              <span className="mr-auto">Title</span>
              {sortData.sort === "title" && sortData.sortDirIndex !== 2 && (
                  <Icon
                      type={sortData.sortDirIndex === 0 ? "arrow-up" : "arrow-down"}
                      style={{ fontSize: 12 }}
                  />
              )}  
            </button>
            <button
                disabled={loading}
                onClick={() => onClickPanelHeader("date")}
                className="w-1/5 py-3 px-0 pr-4 text-base font-medium text-left focus:outline-none flex items-center"
            >
                <span className="mr-auto">Date</span>
                {sortData.sort === "date" && sortData.sortDirIndex !== 2 && (
                    <Icon
                        type={sortData.sortDirIndex === 0 ? "arrow-up" : "arrow-down"}
                        style={{ fontSize: 12 }}
                    />
                )}
            </button>
            <button
                disabled={loading}
                onClick={() => onClickPanelHeader("status")}
                className="w-2/5 py-3 px-0 pr-4 text-base font-medium text-left focus:outline-none flex items-center"
            >
                <span className="mr-auto">Result</span>
                {sortData.sort === "status" && sortData.sortDirIndex !== 2 && (
                    <Icon
                        type={sortData.sortDirIndex === 0 ? "arrow-up" : "arrow-down"}
                        style={{ fontSize: 12 }}
                    />
                )}
            </button>
        </div>
      ):(
        <div className="flex w-full items-center">
        <div className="flex items-center py-3 border-l border-t">
            <input 
                ref={input => {
                    if (input) {
                      if(Object.keys(selectedItems).length === data.results.length){
                        input.indeterminate = false;
                        input.checked = true;
                      }else{
                        input.indeterminate = true;
                        input.checked = false;
                      }
                    }
                }}
                type="checkbox" 
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => onChangeSelectedInfoCheckbox(e)}
                className="focus:ring-indigo-500 h-4 w-4 mx-5 text-indigo-600 border-gray-300 rounded"/> 

            {!selectAllResults && (
              <span className="pr-5 text-gray-700 font-medium">
                {Object.keys(selectedItems).length} selected
              </span>    
            )}

            {selectAllResults && (
              <span className="pr-5 text-gray-700 font-medium">
                {count} selected
              </span>    
            )}
            
            </div>

            <div className="flex items-center py-3 border-l border-t border-r">
              <Dropdown overlay={
                <Menu onClick={(e) => setSelectedBulkAction(e.key)}>
                    <Menu.Item key={bulkActionTypes.delete}><span className="pl-3">Remove from collection(s)</span></Menu.Item>
                    <SubMenu title={<span className="pl-3">Add to collection(s)</span>}>
                        <Menu.Item key={bulkActionTypes.existing}><span className="px-3">Existing Collection</span></Menu.Item>
                        <Menu.Item key={bulkActionTypes.new}><span className="px-3">New Collection</span></Menu.Item>
                    </SubMenu>
                    <Menu.Item key={bulkActionTypes.deleteItems}><span className="pl-3">Delete item(s)</span></Menu.Item>
                </Menu>
              } trigger={["click"]}>
                  <a className="ant-dropdown-link w-36 flex justify-center items-center text-gray-700 font-medium" href="#">
                      Bulk Action <Icon type="down" className="flex justify-center items-center pl-3" />
                  </a>
              </Dropdown>   
            </div>

            <div className="flex items-center">
                {!selectAllResults && (
                    <Button type="native" className="ml-5" onClick={(e) => onClickSelectAllResults(true)}>
                        Select all results ({count})
                    </Button>
                )}

                {selectAllResults && (
                    <div className="flex items-center">
                        <span className="text-gray-700 font-medium ml-5">All ({count}) results are selected.</span>
                        <Button type="native" className="ml-3" onClick={(e) => onClickSelectAllResults(false)}>
                            <span className="text-blue-400 font-medium">Undo</span>
                        </Button>
                    </div>
                )}
                
            </div>
        </div>
      )}
    </div>
  );

  const onClickPanelHeader = (column) => {
    if (column === sortData.sort) {
      sortData.sortDirIndex =
        sortData.sortDirIndex === 2 ? 0 : sortData.sortDirIndex + 1;
    } else {
      sortData.sortDirIndex = 0;
    }
    sortData.sort = column;
    setSortData({ ...sortData });
    loadData({
      sortData: {
        sort: sortData.sort,
        sortDir: sortDir[sortData.sortDirIndex],
      },
      page: page,
    });
  };

  const onChangePage = (e) => {
    setPage(e);
    loadData({
      sortData: {
        sort: sortData.sort,
        sortDir: sortDir[sortData.sortDirIndex],
      },
      page: e,
    });
  };

  return (
    <div>
      {data !== null && data.results !== null && data.results.length > 0 && (
        <>
          {panelHeader()}

          <Collapse className="bg-white" accordion expandIconPosition="right">
            {data.results
              .map((result) => (
                <Panel
                  key={result.api_id}
                  className="bg-white"
                  header={panelRow(result)}
                  messageKey={result.api_id}
                >
                  {result.data === null && (
                    <div className="p-10 text-center text-lg">No Data</div>
                  )}
                  {result.data !== null &&  result.data.length > 0 && (
                    <div className="w-full p-8">
                      <div className="w-full flex pb-8">
                        <div className="w-1/2">
                          <span className="font-bold">Id:</span>
                          <span className="ml-3">
                            {result.data[0].session.id}
                          </span>
                        </div>
                        <div className="w-1/3">
                          <span className="font-bold">Voice:</span>
                          <span className="ml-3">
                            {result.data[0].session.voice}
                          </span>
                        </div>
                      </div>
                      <div className="w-full">
                        <div className="p-0">
                          <TextEditor
                            viewMode={true}
                            onEdit={(e) => onEdit(result)}
                            user={user}
                            paragraphsData={
                              result.data[0].session
                                ? result.data[0].session.synthesis_json
                                : null
                            }
                            text={result.data[0].session.text}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </Panel>
              ))}
          </Collapse>
          <Pagination
            totalSize={count}
            page={1}
            pageSize={25}
            onChangePage={onChangePage}
          />

          {selectedBulkAction && (
            <CollectionBulkActions 
                collectionType="voiceMaker" 
                bulkActionType={selectedBulkAction} 
                selectedItems={selectedItems}
                selectedAll={selectAllResults}
                clearBulkActionType={(e) => setSelectedBulkAction(null)}
                totalItemCount={count} 
                searchFilterResults={searchQuery}
                parentCollection={selectedCollection}
                refreshParents={(e) => reload()}
            />
          )}
          
        </>
      )}

      {(data == null || data.results == null || data.results.length == 0) && (
        <>
          <div className="bg-white border-t border-gray-200">
            <div className="p-10 text-center text-lg">No Data</div>
          </div>
        </>
      )}

    </div>
  );
};

export default Results;
