import React, { useState, useEffect } from "react";
import Spinner from "../../common/Spinner";
import { message } from "antd";
import Hero from "../../common/Hero";
import { getUser, hasUserFullAccess } from "../../common/auth";
import Create from "../PaymentMethods/Create";
import Modal from "../../common/Modal";
import classNames from "classnames";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { InspectletSendApiError } from "../../common/Inspectlet";

const key = "updatable";

const Pricing = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [subscriptionType, setSubscriptionType] = useState(null);
  const [cardModalVisible, setCardModalVisible] = useState(false);
  const [warningModalVisible, setWarningModalVisible] = useState(false);
  const location = useLocation();
  const urlActionParam = new URLSearchParams(location.search).get("action");
  
  useEffect(() => {
    (async () => setUser(await getUser()))();
  }, []);

  if (!user) {
    return null;
  }

  const getPriceButtonState = (type) => {
    
    if(type === "professional"){
      
      if(user.business.subscription){
        
        if(user.business.subscription.plan === "professional"){
          
          if(((user.business.subscription?.status === "trialing" 
                && user.business.subscription.credit?.used < user.business.subscription.credit?.total) 
                || (user.business.subscription?.status === "active"))){

            if(user.business.subscription.status === "trialing"){
              if(urlActionParam === "subscription"){
                return {text: "Continue Using Professional", status: true };
              }else{
                return {text: "In Trial", status: false};
              }
            }else{
              return {text: "Buy Professional", status: false};
            }

          }else{
            return {text: "Continue Using Professional", status: true};
          }
        }else{
          return {text: "Buy Professional", status: true};
        }
      }else{
        return {text: "Start 7-Day Free Trial", status: true};
      }

   }else if(type === "startup"){
      if(user.business.subscription){
        if(user.business.subscription.plan === "startup"){
          if(user.business.subscription.status === "active"){
            return {text: "Buy Start-Up/Scale-Up", status: false};
          }else{
            return {text: "Continue Using Start-Up/Scale-Up", status: true};
          }
        }else{
          return {text: "Buy Start-Up/Scale-Up", status: true};
        }
      }else{
        return {text: "Buy Start-Up/Scale-Up", status: true};
      }
   
   }else if(type === "enterprise"){
      if(user.business.subscription){
        if(user.business.subscription.plan === "enterprise"){
          if(user.business.subscription.status === "active"){
            return {text: "Contact us", status: false};
          }else{
            return {text: "Continue Using Enterprise", status: true};
          }
        }else{
          return {text: "Contact us", status: true};
        }
      }else{
        return {text: "Contact us", status: true};
      }
   }      
  }

  const professionalButton = getPriceButtonState("professional");
  const startUpButton = getPriceButtonState("startup");
  const enterpriseButton = getPriceButtonState("enterprise");

  
  const pricingPlans = [
    {
      name: "Professional",
      description: "All the basics for starting a new business",
      price: "$35",
      button: (
        <div className="relative">
          <button
            onClick={(e) => subscribe("professional")}
            disabled={
              loading || !professionalButton.status
            }
            className={classNames(
              "mt-2 block w-full bg-cool-gray-800 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-cool-gray-700",
              !professionalButton.status &&
                "cursor-not-allowed opacity-50 focus"
            )}
          >
            {subscriptionType === "professional" && loading && (
              <Spinner size={4} color="white" />
            )}
            {professionalButton.text}
          </button>
          {!!user.business.trial_ends_at || (
            <div className="text-center mt-2 italic text-xs absolute right-0 bottom-full pb-4">
              No credit cards required
            </div>
          )}
        </div>
      ),
      items: [
        ...(user.business.trial_ends_at
          ? []
          : ["10 minutes of audio or video mix use during 7-day trial"]),
        "2 hours of audio or video - mix use",
        "Built-in speech & pause control",
        "Fast Synthesis",
        "Emotion Control",
        "VideoMaker with unlimited edits",
        "Additional usage billed separately",
      ],
    },
    {
      name: "Start-Up/Scale-Up",
      description: "All the basics for starting a new business",
      price: "$169",
      button: (
        <button
          onClick={(e) => subscribe("startup")}
          disabled={loading || !startUpButton.status}
          className={classNames(
            "mt-2 block w-full bg-cool-gray-800 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-cool-gray-700",
            !startUpButton.status &&
              "cursor-not-allowed opacity-50 focus"
          )}
        >
          {subscriptionType === "startup" && loading && (
            <Spinner size={4} color="white" />
          )}
          {startUpButton.text}
        </button>
      ),
      items: [
        "Everything in Professional",
        "API access-$99 monthly fee",
        "10 hours of audio or video - mix use",
        "Up to 5 users",
        "Capability to build custom voices (POA)",
        "Additional usage billed separately",
      ],
    },
    {
      name: "Enterprise",
      description: "All the basics for starting a new business",
      price: null,
      button: (
        <a
          href="mailto:hello@deepzen.io"
          disabled={
            loading || !enterpriseButton.status
          }
          className={classNames(
            "hover:text-white mt-15 block w-full bg-cool-gray-800 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-cool-gray-700",
            !enterpriseButton.status &&
              "cursor-not-allowed opacity-50 focus"
          )}
        >
          {subscriptionType === "enterprise" && loading && (
            <Spinner size={4} color="white" />
          )}
          {enterpriseButton.text}
        </a>
      ),
      items: [
        "Everything in Start-up/Scale-up",
        "Full Flexible & customized approach to scaled voice needs",
        "Comprehensive customer support and training",
        "On premise deployment, or in cloud",
        "Enterprise SLAs",
        "Discounted bulk usage",
      ],
    },
  ];

  const makeSubscriptionRequest = (type) => {
    setLoading(true);
    axios
      .post("billing/upgrade/", { product: type })
      .then((res) => {
            message.success({
                content: "Successful",
                duration: 2,
                key,
            });

            setWarningModalVisible(false);
            setCardModalVisible(false);
            setSubscriptionType(null);
            setLoading(false);

            setTimeout(() => { window.location.href = "/dashboard"; }, 1000);
        
      })
      .catch((e) => {
        message.error({
          content: "An error occurred.",
          duration: 2,
          key,
        });

        setSubscriptionType(null);
        setLoading(false);
        setWarningModalVisible(false);
        setCardModalVisible(false);
        InspectletSendApiError(e);
      });
  };

  const subscribe = (type) => {
    setSubscriptionType(type);

    if (
      user.business.payment_method ||
      (type === "professional" && !user.business.trial_ends_at)
    ) {
      setWarningModalVisible(true);
    } else {
      setCardModalVisible(true);
    }
  };

  const confirmSubscription = (e) => {
    if (e) {
      makeSubscriptionRequest(subscriptionType);
    } else {
      setWarningModalVisible(false);
    }
  };

  const handleAddCreditCard = (e) => {
    setCardModalVisible(e);
  };

  const onCardCreate = (e) => {
    if (e) {
      setUser({ ...user, payment_method: "credit card" });
       if(subscriptionType === "professional" && user.business.subscription?.status === "trialing"){
            axios
              .post("billing/end-trial/")
              .then((res) => {
        
                message.success({
                    content: "Successful",
                    duration: 2,
                    key,
                });

                setWarningModalVisible(false);
                setCardModalVisible(false);
                
                setLoading(false);

                setTimeout(() => { window.location.href = "/dashboard"; }, 1000);
        
              })
              .catch((e) => {
                message.error({
                  content: "An error occurred.",
                  duration: 2,
                  key,
                });

                setLoading(false);
                setWarningModalVisible(false);
                setCardModalVisible(false);
                InspectletSendApiError(e);
              });
        }else{
          setCardModalVisible(false);
          setWarningModalVisible(true);
        }
    }
  };

  return (
    <div>
      <div className="w-auto mx-auto px-4 sm:px-6 lg:px-8 bg-cool-gray-800 pb-5">
        <Hero
          title="Pricing Plans"
          description="Start building for free, then add a site plan to go live. Account plans unlock additional features."
        />
      </div>
      <div className="bg-white pb-10">
        <div className="bg-gradient-to-b from-white to-gray-50">
          <div className="max-w-7xl mx-auto pt-4 px-4 sm:px-6 lg:px-8">
            <div className="mt-6 space-y-4 sm:mt-6 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3">
              {pricingPlans &&
                pricingPlans.map((plan, index) => (
                  <div
                    key={index}
                    className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200"
                  >
                    <div className="p-6">
                      <h2 className="text-lg leading-6 font-medium text-gray-900">
                        {plan.name}
                      </h2>
                      <p className="mt-4 text-sm text-gray-500">
                        {plan.description}
                      </p>
                      {plan.price && (
                        <p className="mt-2">
                          <span className="text-3xl font-extrabold text-gray-900">
                            {plan.price}
                          </span>
                          <span className="text-base font-medium text-gray-500">
                            /Month
                          </span>
                        </p>
                      )}
                      {plan.button}
                    </div>
                    <div className="pt-6 pb-8 px-6">
                      <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">
                        What's included
                      </h3>
                      <ul className="mt-6 space-y-4">
                        {plan.items.map((item, i) => (
                          <li key={i} className="flex space-x-3">
                            <svg
                              className="flex-shrink-0 h-5 w-5 text-green-500"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                            <span className="text-sm text-gray-500">
                              {item}
                            </span>
                          </li>
                        ))}
                      </ul>
                      {plan.name === "Professional" && (
                        <div className="text-xs text-gray-500 pt-5">*0.0049 per 1 second of audio or video for additional usage</div>
                      )}
                      {plan.name === "Start-Up/Scale-Up" && (
                        <div className="text-xs text-gray-500 pt-5">*0.0019 per 1 second of audio or video for additional usage</div>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      <Modal
        hideFooter={true}
        title="Billing Details"
        visible={cardModalVisible}
        loading={loading}
        onCancel={() => handleAddCreditCard(false, null)}
        onConfirm={() => handleAddCreditCard(true, null)}
      >
        <Create withBilling onCreated={(e) => onCardCreate(e)} />
      </Modal>

      <Modal
        hideFooter={false}
        title="New Subscription"
        visible={warningModalVisible}
        confirmButtonText="Yes"
        closeButtonText="No"
        loading={loading}
        onCancel={() => confirmSubscription(false)}
        onConfirm={() => confirmSubscription(true)}
      >
        <p className="flex justify-start items-center py-5 px-6 font-normal text-xl">
          Are you sure you want to subscribe to&nbsp;
          <span className="font-bold">{subscriptionType}</span>&nbsp;plan?
        </p>
      </Modal>
    </div>
  );
};

export default Pricing;
