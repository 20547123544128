import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { logout } from "../../common/auth";
import { AUTH_URL } from "../../constants";

const Logout = () => {
  logout();
  useEffect(() => {
    window.location.href = `${AUTH_URL}/accounts/logout?next=${window.location.origin}/`;
  },[]);
  
  return null;
};

export default Logout;
