import React from "react";
import Card from "../../common/Card";
import PageHeader from "../../common/PageHeader";
import VoiceCatalog from "./VoiceCatalog";
import { useUser } from "../../common/auth";
import { SubscriptionInfo } from "../../common/SubscriptionInfo";

const Voice = () => {

const user = useUser();

return (

  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

    <SubscriptionInfo user={user} />

    <PageHeader
      title="Voice Library"
      className="mt-12"
    />

    <Card className="mt-5 bg-gray-100">
      <VoiceCatalog />
    </Card>
  </div>
);
}

export default Voice;
