import React from "react";
import Sentence from "./Sentence";

const Paragraph = ({
    paragraphId,
    paragraph,
    editedParagraph,
    disabled,
    onClickSentence,
}) => {

   return (
        <div className="pb-3">
            {paragraph && paragraph.map((sentence, i) => {
                return <Sentence 
                            key={`P#${paragraphId}-S#${i}`} 
                            disabled={disabled}
                            sentence={sentence}
                            editedSentence={editedParagraph[i]} 
                            sentenceId={i}
                            paragraphId={paragraphId}
                            onClickSentence={(e) => onClickSentence(e)}
                            isActive={editedParagraph[i].active} />
            })}
            
        </div>
   );   
}

export default Paragraph;