import React from "react";
import classNames from "classnames";
import { INSPECTLET_KEY } from "../constants";
import { getApiErrors } from "./ApiError";

export const InspectletInit = (user) => {

    if(user && !user.is_staff){
        window.__insp = window.__insp || [];
        window.__insp.push(['wid', INSPECTLET_KEY]);
        window.__insp.push(['identify', user.email]);
        
        var ldinsp = function () {
            if (typeof window.__inspld != "undefined") return; 
            
            window.__inspld = 1; 
            var insp = document.createElement('script'); 
            insp.type = 'text/javascript'; 
            insp.async = true; 
            insp.id = "inspsync"; 
            insp.src = ('https:' == document.location.protocol ? 'https' : 'http') 
                + '://cdn.inspectlet.com/inspectlet.js?wid=' + INSPECTLET_KEY 
                + '&r=' + Math.floor(new Date().getTime() / 3600000); 
            var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(insp, x);
        };
        setTimeout(ldinsp, 0);
    }

}

export const InspectletSendApiError = (error) => {

    if (typeof window.__inspld == "undefined" 
        || typeof window.__insp == "undefined"
        || !error) return; 

    let [message, errors] = getApiErrors(error);    
    
    window.__insp.push(['tagSession', { message: message, errors: errors }]);
}

export default InspectletInit;
