import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import Spinner from "../../common/Spinner";
import { PUSHER_TOKEN } from "../../constants";
import Card, { CardBody } from "../../common/Card";
import { message } from "antd";
import Form from "./Form";
import Results from "./Results";
import "antd/dist/antd.css";
import Pusher from "pusher-js";
import { getUser } from "../../common/auth";
import PageHeader from "../../common/PageHeader";
import Modal from "../../common/Modal";
import Collections from "../../common/Collections/list";
import Search from "../../common/Search";
import Button from "../../common/Button";
import CloseIcon from "../../Icons/CloseIcon";
import SubscriptionInfo from "../../common/SubscriptionInfo";
import { InspectletSendApiError } from "../../common/Inspectlet";
import { 
  DEFAULT_COLLECTION_NAME, 
  addToExistingCollections, 
  addToNewCollections } from "../../common/Collections/bulkActions";

const pusher = new Pusher(PUSHER_TOKEN, {
  cluster: "eu",
  forceTLS: true,
});

Pusher.logToConsole = true;

let temp = null;
const key = "updatable";
const collectionType = "voiceMaker";

const VoiceSynthesisIndex = () => {
  const [synthesisResults, _setSynthesisResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [resultCount, setResultCount] = useState(null);
  const [formData, setFormData] = useState(null);
  const [formNewButton, setFormNewButton] = useState(false);
  const [newFormModal, setNewFormModal] = useState(false);
  const formRef = useRef();
  const resultsRef = useRef();
  const collectionRef = useRef();
  const jobEventTypes = { updated: "vm-job-updated" };
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [ordering, setOrdering] = useState("");
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [defaultCollectionId, setDefaultCollectionId] = useState(null);

  const scrollToRef = (ref) =>
    window.scrollTo({
      top: ref.current?.offsetTop,
      left: 0,
      behavior: "smooth",
    });

  const setSynthesisResults = (data) => {
    _setSynthesisResults(data);
    temp = JSON.parse(JSON.stringify(data));
  };

  useEffect(() => {
    fetchSynthesisResults(page, ordering, searchQuery, selectedCollection);
  }, [searchQuery]);

  const fetchSynthesisResults = (
    _page,
    _ordering,
    _searchValue,
    _selectedCollection
  ) => {
    setTableLoading(true);
    axios
      .get(
        `voicemaker/?page=${_page}&ordering=${_ordering}&voice_maker_only=1&search=${_searchValue}&collectionId=${
          _selectedCollection ? _selectedCollection.id : ""
        }`
      )
      .then((res) => {
        setTableLoading(false);
        setSynthesisResults(res.data);
        setResultCount(res.data.count);
      })
      .catch((e) => {
        setTableLoading(false);
        InspectletSendApiError(e);
      });
  };

  const onLoadTableData = (e) => {
    let _page = e && e.page && e.page.page ? e.page.page : 1;
    let _ordering = "";

    if (e && e.sortData) {
      if (e.sortData.sortDir) {
        if (e.sortData.sort === "date") {
          e.sortData.sort = "created_at";
        }
        if (e.sortData.sortDir === "desc") {
          _ordering = "-" + e.sortData.sort;
        } else {
          _ordering = e.sortData.sort;
        }
      }
    }

    setPage(_page);
    setOrdering(_ordering);
    fetchSynthesisResults(_page, _ordering, searchQuery, selectedCollection);
  };

  const fetchSynthesisResultById = (jobId, jobEventType) => {
    axios
      .get(`/voicemaker/${jobId}/`)
      .then((res) => {
        if (jobEventType === jobEventTypes.updated) {
          const results = [...(temp ? temp.results : [])].map((row) =>
            row.api_id === res.data.api_id
              ? { ...row, status: res.data.status, data: res.data.data }
              : row
          );

          setSynthesisResults({
            ...temp,
            results,
          });
        }
      })
      .catch((e) => {
        message.error({
          content: "An error occurred while fetching synthesis result!",
          duration: 2,
          key,
        });
        InspectletSendApiError(e);
      });
  };

  useEffect(() => {
    const run = async () => {
      const user = await getUser();
      setUser(user);
    };

    run();
  }, []);

  useEffect(() => {
    if (user?.business.api_token) {
      fetchSynthesisResults(page, ordering, searchQuery, selectedCollection);

      const channel = pusher.subscribe(user.business.api_token);

      channel.bind(jobEventTypes.updated, (data) => {
        fetchSynthesisResultById(data.id, jobEventTypes.updated);
      });
    }

    return () => {
      if (user?.business.api_token) {
        pusher.unsubscribe(user.business.api_token);
      }
    };
  }, [user]);

  if (!user) {
    return null;
  }

  const onSubmit = (e) => {
    setLoading(true);
    message.loading({ content: "Loading...", key });

    setFormData(e);
    let _editedParagraphs = null;
    if (e.synthesis_json && e.synthesis_json.length > 0) {
      _editedParagraphs = [
        ...e.synthesis_json.map((p, i) =>
          p.map((s, j) => (s.dirty ? { ...s } : s))
        ),
      ];
    }

    const session = {
      webhook_url: "https://vm.deepzen.io",
      title: e.title,
      sessions: [
        {
          preview: e.preview,
          ssml: e.ssml,
          text: e.text,
          synthesis_json: _editedParagraphs,
          voice: e.voice,
        },
      ],
    };

    axios
      .post(`voicemaker/`, session)
      .then((res) => {
        const results = [res.data, ...(temp ? temp.results : [])];
        setSynthesisResults({
          ...temp,
          results,
        });

        setLoading(false);
        message.success({
          content: "Synthesis has been queued.",
          duration: 2,
          key,
        });
        scrollToRef(resultsRef);
        setFormNewButton(true);

        if(defaultCollectionId){
          addToExistingCollections({
                  items: [results[0]['api_id']],
                  collectionId: defaultCollectionId,
                  type: collectionType
              }).then((res) => collectionRef.current.reload())
              .catch((e) => {
                message.error("An error occurred.");
              });
        }else{
          addToNewCollections({
                  items: [results[0]['api_id']],
                  name: DEFAULT_COLLECTION_NAME,
                  type: collectionType
              }).then((res) => collectionRef.current.reload())
              .catch((e) => {
                message.error("An error occurred.");
              });
        }
      })
      .catch((e) => {
        setLoading(false);
        setFormNewButton(true);
        const errorMessage = e?.response?.data;
        message.error({
          content: (errorMessage && (errorMessage.trial || errorMessage.detail)) || "An error occurred",
          duration: 2,
          key,
        });

        InspectletSendApiError(e);
      });
  };

  const onClearCollection = () => {
    collectionRef.current.unSelectCollection();
    setSelectedCollection(null);
    fetchSynthesisResults(page, ordering, searchQuery, null);
  };

  const synthesisResultsTitle = () => (
    <div className="flex justify-between items-center">
      <div className="w-3/4">
        <div className="flex items-center">
          {!selectedCollection && (
            <span className="mr-3">Synthesis Results</span>
          )}
          {selectedCollection && (
            <span className="mr-3">
              Synthesis Results - ({selectedCollection.name})
              <Button
                type="button"
                className=""
                onClick={(e) => onClearCollection()}
              >
                <CloseIcon size="1em" />
              </Button>
            </span>
          )}

          {tableLoading && (
            <div className="">
              <Spinner color="gray" size={5} />
              <span className="ml-2">Loading..</span>
            </div>
          )}
        </div>
      </div>

      <Search label="Search..." onSearch={(e) => setSearchQuery(e)} />
    </div>
  );

  const getTitleWithVersion = (e) => {
    let title = "";
    if (e.indexOf("-V") !== -1) {
      let titleArr = e.split("-V");
      let version = 1 * titleArr[1] + 1;
      title = titleArr[0] + "-V" + version;
    } else {
      title = e + "-V1";
    }
    return title;
  };

  const onEdit = (e) => {
    setFormData({
      ...formData,
      title: getTitleWithVersion(e.title),
      text: e.data[0].session.text,
      voice: e.data[0].session.voice,
      synthesis_json: e.data[0].session.synthesis_json,
    });

    scrollToRef(formRef);
    setFormNewButton(true);
  };

  const confirmNewFormModal = (e) => {
    if (e) {
      setNewFormModal(false);
      setFormNewButton(false);
      setFormData(null);
    } else {
      setNewFormModal(false);
    }
  };

  const handleCollection = (e) => {
    setSelectedCollection(e);
    scrollToRef(resultsRef);
    fetchSynthesisResults(page, ordering, searchQuery, e);
  };

  const reload = () => {
    collectionRef.current.reload();
    fetchSynthesisResults(page, ordering, searchQuery, selectedCollection);
  };

  return (
    <div className="max-w-screen-2xl mx-auto flex flex-col flex-1 overflow-hidden">
      <div className="flex overflow-hidden">
        <div className="w-1/5 border-r border-t bg-cool-gray-800">
          <Collections
            ref={collectionRef}
            type={collectionType}
            handleCollection={(e) => handleCollection(e)}
            handleDefaultCollectionId={(e) => setDefaultCollectionId(e)}
          />
        </div>
        <div className="w-4/5 mx-auto px-4 sm:px-6 lg:px-8">
          <SubscriptionInfo user={user} />

          <div ref={formRef} className="">
            <PageHeader
              title="VoiceMaker"
              className="mt-5"
              extra={
                formNewButton && (
                  <button
                    type="button"
                    onClick={(e) => setNewFormModal(true)}
                    className="relative inline-flex items-center px-4 py-1.5 my-1 self-end border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 shadow-sm hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition ease-in-out duration-150"
                  >
                    <svg
                      className="-ml-1 mr-2 h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>Create New</span>
                  </button>
                )
              }
              subtitle={
                <a
                  target="_blank"
                  href="https://deepzen.io/guide-voicemaker/"
                  className="inline-flex items-center px-1 py-1 rounded-md font-medium leading-5 text-lg text-gray-500"
                >
                  View VoiceMaker user guide for instructions
                  <svg
                    className="w-4 h-4 inline ml-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                    ></path>
                  </svg>
                </a>
              }
            />

            <Card>
              <CardBody>
                <Form
                  data={formData}
                  handleSubmit={onSubmit}
                  user={user}
                  loading={loading}
                />
              </CardBody>
            </Card>
          </div>

          <div ref={resultsRef} className="pb-1">
            <Card title={synthesisResultsTitle()} className="mt-10 mb-10">
              <CardBody>
                <Results
                  user={user}
                  onEdit={onEdit}
                  count={resultCount}
                  data={synthesisResults}
                  loadData={onLoadTableData}
                  loading={tableLoading}
                  searchQuery={searchQuery}
                  selectedCollection={selectedCollection}
                  reload={(e) => reload()}
                />
              </CardBody>
            </Card>
          </div>

          <Modal
            hideFooter={false}
            title="Warning!"
            visible={newFormModal}
            confirmButtonText="Yes"
            closeButtonText="No"
            loading={loading}
            onCancel={() => confirmNewFormModal(false)}
            onConfirm={() => confirmNewFormModal(true)}
          >
            <div>
              <p className="flex pb-5 px-6 font-normal text-xl">
                Your changes will be lost. Are you sure you want to create a new
                form?
              </p>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default VoiceSynthesisIndex;
