import React, { useEffect, useRef, useState } from "react";
import Streamer from "./Streamer";

const playIcon = (
  <svg
    className="w-9 h-9"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
    ></path>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
    ></path>
  </svg>
);

const pauseIcon = (
  <svg
    className="w-9 h-9"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z"
    ></path>
  </svg>
);

function formatSeconds(time) {
  // Hours, minutes and seconds
  var hrs = ~~(time / 3600);
  var mins = ~~((time % 3600) / 60);
  var secs = ~~time % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = "";
  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }
  ret += "" + String(mins).padStart(2, "0") + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;
  return ret;
}

const Player = ({ id, width, emitPlayerId, pausePlayer, audioUrl }) => {
  const streamer = useRef();
  const [ready, setReady] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [completed, setCompleted] = useState(null);
  const [total, setTotal] = useState(null);

  useEffect(() => {
    streamer.current = new Streamer(id, {
      onProgress(completed, total) {
        console.log("[UI] Progress", completed, total);

        setProgress(Math.round((completed / total) * 100));
        setCompleted(completed);
        setTotal(total);
      },
      onPlay() {
        setPlaying(true);
        if(emitPlayerId) emitPlayerId(id);
      },
      onPause() {
        setPlaying(false);
      },
      onReady() {
        setReady(true);
      },
    }, audioUrl);

    return () => streamer.current.destroy();
  }, []);

  useEffect(() => {
    if(streamer.current && pausePlayer){
      streamer.current.pause();
    }
  }, [pausePlayer]);

  return (
    <div
      className={`bg-white rounded-lg shadow-md p-2 flex items-center ${
        !ready && "opacity-50 pointer-events-none"
      }`}
      style={{ width: width ? width : 400  }}
    >
      <button
        onClick={(e) => {
            e.stopPropagation();
            playing ? streamer.current.pause() : streamer.current.play()
          }
        }
        className="w-9 mr-2 focus:outline-none"
      >
        {playing ? pauseIcon : playIcon}
      </button>
      <div className="w-14 text-center text-sm text-gray-500">
        {completed !== null && formatSeconds(Math.floor(completed))}
      </div>
      <input
        type="range"
        step={1}
        min={0}
        max={100}
        value={progress}
        disabled={!ready}
        onChange={(e) => streamer.current.seekTo(e.target.value)}
        className="flex-grow"
      />
      <div className="w-14 text-center text-sm text-gray-500">
        {total !== null && formatSeconds(Math.floor(total))}
      </div>
    </div>
  );
};

export default Player;
