import React, { useState, useEffect } from "react";
import Search from "../Search";
import Spinner from "../Spinner";
import Modal from "../Modal";
import SearchIcon from "../../Icons/SearchIcon";
import SettingsIcon from "../../Icons/SettingsIcon";
import Button from "../Button";
import classNames from "classnames";
import axios from "axios";
import { Menu, Dropdown, message } from "antd";
import Alert from "../Alert";
import { getCollections } from "./list";
import { InspectletSendApiError } from "../Inspectlet";

export const bulkActionTypes = {
  delete: "1",
  existing: "2",
  new: "3",
  deleteItems: "4",
};

export const DEFAULT_COLLECTION_NAME = "Default";

export const addToExistingCollections = (payload) => 
    axios.put(`collections/${payload.collectionId}/`, {
          items: payload.isSelectedAllItems ? [] : payload.items,
          selectedAllItems: payload.isSelectedAllItems,
          parentCollectionId: payload.isSelectedAllItems
            ? payload.parentCollection
              ? payload.parentCollection.id
              : null
            : null,
          searchFilter: payload.searchFilter,
          type: payload.type
    });

export const addToNewCollections = (payload) => 
    axios.post(`collections/`, {
          items: payload.isSelectedAllItems ? [] : payload.items,
          selectedAllItems: payload.isSelectedAllItems,
          parentCollectionId: payload.isSelectedAllItems
            ? payload.parentCollection
              ? payload.parentCollection.id
              : null
            : null,
          searchFilter: payload.searchFilter,
          type: payload.type,
          name: payload.name
    });    
   

const CollectionBulkActions = ({
  collectionType,
  bulkActionType,
  selectedItems,
  selectedAll,
  totalItemCount,
  clearBulkActionType,
  searchFilterResults,
  parentCollection,
  refreshParents,
}) => {
  const [collections, setCollections] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [newCollectionName, setNewCollectionName] = useState(null);
  const [warningModalVisible, setWarningModalVisible] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [bulkActionModalTitle, setBulkActionModalTitle] = useState(null);
  const [collectionsLoading, setCollectionsLoading] = useState(false);
  const [itemSize, setItemSize] = useState("");
  const [itemSuffix, setItemSuffix] = useState("");

  useEffect(() => {
    if (selectedItems && bulkActionType) {
      const _itemSize = selectedAll
        ? totalItemCount
        : Object.keys(selectedItems).length;
      const _itemSuffix = _itemSize > 1 ? "s" : "";
      setItemSize(_itemSize);
      setItemSuffix(_itemSuffix);
      switch (bulkActionType) {
        case bulkActionTypes.delete:
          setBulkActionModalTitle(
            "Remove " + _itemSize + " item" + _itemSuffix + " from collection"
          );
          setWarningModalVisible(true);
          break;
        case bulkActionTypes.existing:
          setBulkActionModalTitle(
            "Add " + _itemSize + " item" + _itemSuffix + " to collection"
          );
          setWarningModalVisible(true);
          break;
        case bulkActionTypes.new:
          setBulkActionModalTitle(
            "Add " + _itemSize + " item" + _itemSuffix + " to new collection"
          );
          setWarningModalVisible(true);
          break;
        case bulkActionTypes.deleteItems:
          setBulkActionModalTitle(
            "Delete " + _itemSize + " item" + _itemSuffix + "?"
          );
          setWarningModalVisible(true);
          break;
        default:
          break;
      }
    }
  }, bulkActionType);

  const handleSearch = (e) => {
    setCollectionsLoading(true);
    setSearchQuery(e);
    fetchCollections(e);
  };

  const fetchCollections = (s) => {
    setCollections(null);
    setCollectionsLoading(true);
    getCollections(collectionType, s)
      .then((res) => {
        setCollectionsLoading(false);
        setCollections(res.data.results);
      })
      .catch((e) => {
        setCollectionsLoading(false);
        message.error("An error occurred.");
      });
  };

  const removeFromCollections = () => {
    setLoading(true);
    axios
      .delete(`collections/${selectedCollection.id}/`, {
        data: {
          items: selectedAll ? [] : Object.keys(selectedItems),
          selectedAllItems: selectedAll,
          parentCollectionId: selectedAll
            ? parentCollection
              ? parentCollection.id
              : null
            : null,
          searchFilter: searchFilterResults,
          type: collectionType,
        },
      })
      .then((res) => {
        setLoading(false);
        message.success("Successful");
        setWarningModalVisible(false);
        clearBulkActionType();
        refreshParents();
      })
      .catch((e) => {
        setLoading(false);
        refreshParents();
        message.error("An error occurred.");
        InspectletSendApiError(e);
      });
  };

  const deleteItems = () => {
    setLoading(true);
    axios
      .request({
        method: "delete",
        url: collectionType === "voiceMaker" ? "voicemaker/" : "slideshows/",
        data: {
          items: selectedAll ? [] : Object.keys(selectedItems),
          selectedAllItems: selectedAll,
          searchFilter: searchFilterResults,
          type: collectionType,
          parentCollectionId: selectedAll
            ? parentCollection
              ? parentCollection.id
              : null
            : null,
        },
      })
      .then((res) => {
        setLoading(false);
        message.success("Successful");
        setWarningModalVisible(false);
        clearBulkActionType();
        refreshParents();
      })
      .catch((e) => {
        setLoading(false);
        refreshParents();
        message.error("An error occurred.");
        InspectletSendApiError(e);
      });
  };

  const confirmBulkActionModal = (e) => {
    if (e) {
      switch (bulkActionType) {
        case bulkActionTypes.delete:
          if (selectedCollection) {
            removeFromCollections();
          } else {
            message.warning("Please select collection!");
          }

          break;
        case bulkActionTypes.existing:
          if (selectedCollection) {
              setLoading(true);
              addToExistingCollections({
                  items: Object.keys(selectedItems),
                  collectionId: selectedCollection.id,
                  isSelectedAllItems: selectedAll,
                  parentCollection: parentCollection,
                  searchFilter: searchFilterResults,
                  type: collectionType
              }).then((res) => {
                  setLoading(false);
                  message.success("Successful");
                  setWarningModalVisible(false);
                  clearBulkActionType();
                  refreshParents();
              }).catch((e) => {
                  setLoading(false);
                  refreshParents();
                  message.error("An error occurred.");
                  InspectletSendApiError(e);
              }); 

          } else {
            message.warning("Please select collection!");
          }

          break;
        case bulkActionTypes.new:
          if (newCollectionName) {
            setLoading(true);
            addToNewCollections({
                  items: Object.keys(selectedItems),
                  isSelectedAllItems: selectedAll,
                  parentCollection: parentCollection,
                  searchFilter: searchFilterResults,
                  type: collectionType,
                  name: newCollectionName
              }).then((res) => {
                  setLoading(false);
                  message.success("Successful");
                  setWarningModalVisible(false);
                  clearBulkActionType();
                  refreshParents();
              }).catch((e) => {
                  setLoading(false);
                  refreshParents();
                  message.error("An error occurred.");
                  InspectletSendApiError(e);
              }); 
          } else {
            message.warning("Please type collection name!");
          }

          break;
        case bulkActionTypes.deleteItems:
          deleteItems();
          break;
        default:
          break;
      }
    } else {
      setWarningModalVisible(false);
      clearBulkActionType();
    }
  };

  if (!warningModalVisible) return null;

  return (
    <div className="">
      <Modal
        hideFooter={false}
        title={bulkActionModalTitle}
        visible={warningModalVisible}
        confirmButtonText={
          bulkActionType === bulkActionTypes.delete
            ? "Save"
            : bulkActionType === bulkActionTypes.existing
            ? "Save"
            : bulkActionType === bulkActionTypes.new
            ? "Save"
            : "Delete"
        }
        closeButtonText="Cancel"
        loading={loading}
        onCancel={() => confirmBulkActionModal(false)}
        onConfirm={() => confirmBulkActionModal(true)}
      >
        {(bulkActionType === bulkActionTypes.delete ||
          bulkActionType === bulkActionTypes.existing) && (
          <div className="flex flex-col w-full border-t py-4 px-5">
            <Search
              label="Search for collections"
              type="primary"
              onSearch={(e) => handleSearch(e)}
            />
            {collectionsLoading && (
              <div className="mt-2 flex justify-center items-center py-5">
                <Spinner size={5} />
                <span className="ml-2 text-base text-cool-gray-900">
                  Loading collections...
                </span>
              </div>
            )}

            {collections === null ||
              (collections.length === 0 && (
                <div className="mt-2 flex justify-center items-center py-5">
                  <span className="ml-2 text-base text-cool-gray-900">
                    No Data
                  </span>
                </div>
              ))}

            {collections && (
              <div className="py-5">
                {collections.map((result) => (
                  <div key={result.id} className="flex items-center">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        name="radio"
                        onClick={(e) => setSelectedCollection(result)}
                        className="focus:ring-indigo-500 h-4 w-4 mx-5 text-indigo-600 border-gray-300 rounded indeterminate:ring-500"
                      />
                      <span className="text-base">{result.name}</span>
                    </label>
                  </div>
                ))}
                {selectedCollection && (
                  <Alert type="warning" className="my-5">
                    <div className="flex flex-col">
                      {bulkActionType === bulkActionTypes.delete && (
                        <span>
                          You are about to remove {itemSize} result{itemSuffix}{" "}
                          from collection
                        </span>
                      )}

                      {bulkActionType === bulkActionTypes.existing && (
                        <span>
                          You are about to add {itemSize} result{itemSuffix} to
                          collection
                        </span>
                      )}

                      <ul className="list-outside px-3">
                        <li className="py-1">{selectedCollection.name}</li>
                      </ul>
                    </div>
                  </Alert>
                )}
              </div>
            )}
          </div>
        )}
        {bulkActionType === bulkActionTypes.new && (
          <div className="relative w-full text-gray-400 focus-within:text-gray-600 my-10 px-5">
            <input
              onChange={(e) => setNewCollectionName(e.target.value)}
              autocomplete="off"
              className={classNames(
                "block w-full text-gray-900 h-full px-2 py-3 rounded-lg border-cool-gray-400 placeholder-gray-500 shadow-outline-gray outline-none focus:outline-none focus:shadow-outline-gray focus:placeholder-gray-400 focus:ring-0 focus:border sm:text-sm bg-white"
              )}
              placeholder="Collection name"
              type="input"
              name="createNew"
            />
          </div>
        )}

        {bulkActionType === bulkActionTypes.deleteItems && (
          <div className="relative w-full text-gray-900 focus-within:text-gray-600 my-5">
            <span className="text-base px-5">This can't be undone.</span>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default CollectionBulkActions;
