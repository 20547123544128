import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Spinner from "../../common/Spinner";
import { API_URL } from "../../constants";
import Rendering from "./Rendering";
import classNames from "classnames";
import Ready from "./Ready";
import TextEditor from "../../common/TextEditor";
import { getUser, hasUserFullAccess } from "../../common/auth";
import { getVoices, getCustomEmotionsById } from "../../common/voices";
import { SubscriptionInfo } from "../../common/SubscriptionInfo";
import { InspectletSendApiError } from "../../common/Inspectlet";
import { message } from "antd";

const key = "updatable";

const SlideShowEditor = () => {
  const { id, action } = useParams();
  const [user, setUser] = useState(null);
  const [slideshow, setSlideshow] = useState(null);
  const [editedSlideshow, setEditedSlideshow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [rendering, setRendering] = useState(false);
  const [redirectToDowload, setRedirectToDowload] = useState(false);
  const [voices, setVoices] = useState(null);
  const [customEmotions, setCustomEmotions] = useState(null);

  useEffect(() => {
    setLoading(true);
    setError(null);

    if (action === "edit") {
      const run = async () => {
        const _voices = await getVoices();
        setVoices(_voices);
        fetchSlideShow(false, _voices);
      };
      run();
    } else {
      fetchSlideShow(false, null);
    }
  }, [id]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (slideshow.status === "rendering") {
        fetchSlideShow(true, null);
      }
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [slideshow]);

  useEffect(() => {
    const run = async () => {
      const user = await getUser();
      setUser(user);
    };

    run();
  }, []);

  const fetchSlideShow = (e, _voices) => {
    axios
      .get(`slideshows/${id}/`)
      .then((res) => {
        setSlideshow({ ...res.data });
        setEditedSlideshow({ ...res.data });
        setLoading(false);
        setCustomEmotions(
          getCustomEmotionsById(_voices, res.data ? res.data.voice : null)
        );
        // Auto-save
        if (res.data.status !== "rendering") {
          setRendering(false);
          if (e) {
            setRedirectToDowload(true);
          }
        }
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
        InspectletSendApiError(e);
      });
  };

  if (loading)
    return (
      <div className="text-center mt-12">
        <Spinner size={6} />
        <span className="ml-2">Loading project...</span>
      </div>
    );

  if (!slideshow)
    return <div className="text-center mt-12 text-xl">Not found.</div>;

  const handleSlideShowAttrChange = (attr) => (e) => {
    if (attr == "voice") {
      if (e.target.value !== slideshow.voice) {
        setCustomEmotions(getCustomEmotionsById(voices, e.target.value));
      }
    }
    setEditedSlideshow({ ...editedSlideshow, [attr]: e.target.value });
    setSlideshow({ ...slideshow, [attr]: e.target.value });
  };

  const checkSlideShowStatus = () => {
    const timer = setTimeout(() => {
      fetchSlideShow(true);
    }, 3000);
  };

  const render = () => {
    setRendering(true);
    axios
      .post(`slideshows/${id}/render/`, editedSlideshow)
      .then((res) => {
        setSlideshow(res.data);
        setEditedSlideshow({ ...res.data });
      })
      .catch((e) => {
        const errorMessage = e?.response?.data;
        message.error({
          content: (errorMessage && (errorMessage.trial || errorMessage.detail)) || "An error occurred",
          duration: 2,
          key,
        });
        setRendering(false);
        InspectletSendApiError(e);
      });
  };

  const handleSlideTextChange = (slideId, e) => {
    setEditedSlideshow({
      ...editedSlideshow,
      slides: editedSlideshow.slides.map((s) =>
        s.id === slideId
          ? { ...s, text: e.text, synthesis_json: e.synthesis_json }
          : s
      ),
    });

    if (e.synthesis_json === null) {
      setSlideshow({
        ...slideshow,
        slides: slideshow.slides.map((s) =>
          s.id === slideId
            ? { ...s, text: e.text, synthesis_json: e.synthesis_json }
            : s
        ),
      });
    }
  };

  const onRefreshParagraphs = (slideId, e) => {
    let _filteredSlideshow = slideshow.slides.filter(
      (slide, i) => slide.id === slideId
    )[0];
    if (
      _filteredSlideshow.synthesis_json &&
      _filteredSlideshow.synthesis_json.length > 0
    ) {
      let _slideshowSynthesisData = [
        ..._filteredSlideshow.synthesis_json.map((p, i) =>
          i === e.paragraphId
            ? p.map((s, j) =>
                j === e.sentenceId
                  ? { ...s, text: e.sentence.text, words: e.sentence.words }
                  : s
              )
            : p.map((s, j) => s)
        ),
      ];

      let slideshowText = "";
      _slideshowSynthesisData.map((p) => {
        p.map((s) => {
          slideshowText += s.text + " ";
        });
        slideshowText += "\n";
      });

      _filteredSlideshow = {
        ..._filteredSlideshow,
        text: slideshowText,
        synthesis_json: _slideshowSynthesisData,
      };

      const uptoDateSlideshow = {
        ...slideshow,
        slides: slideshow.slides.map((slide) =>
          slide.id === slideId ? { ...slide, ..._filteredSlideshow } : slide
        ),
      };
      setSlideshow(uptoDateSlideshow);
    }

    let _filteredEditedSlideshow = editedSlideshow.slides.filter(
      (slide, i) => slide.id === slideId
    )[0];
    if (
      _filteredEditedSlideshow.synthesis_json &&
      _filteredEditedSlideshow.synthesis_json.length > 0
    ) {
      let _editedSlideshowSynthesisData = [
        ..._filteredEditedSlideshow.synthesis_json.map((p, i) =>
          i === e.paragraphId
            ? p.map((s, j) =>
                j === e.sentenceId
                  ? { ...s, text: e.sentence.text, words: e.sentence.words }
                  : s
              )
            : p.map((s, j) => s)
        ),
      ];

      let editedSlideshowText = "";
      _editedSlideshowSynthesisData.map((p) => {
        p.map((s) => {
          editedSlideshowText += s.text + " ";
        });
        editedSlideshowText += "\n";
      });

      _filteredEditedSlideshow = {
        ..._filteredEditedSlideshow,
        text: editedSlideshowText,
        synthesis_json: _editedSlideshowSynthesisData,
      };
      const uptoDateEditedSlideshow = {
        ...editedSlideshow,
        slides: slideshow.slides.map((slide) =>
          slide.id === slideId
            ? { ...slide, ..._filteredEditedSlideshow }
            : slide
        ),
      };
      setEditedSlideshow(uptoDateEditedSlideshow);
    }
  };

  const handleSlideRemoval = (id) => {
    if (window.confirm("Are you sure?")) {
      axios
        .post(`slideshows/${slideshow.id}/delete_slide/`, { id })
        .then((res) => {
          setSlideshow({
            ...slideshow,
            slides: slideshow.slides.filter((s) => s.id !== id),
          });
          setEditedSlideshow({
            ...editedSlideshow,
            slides: editedSlideshow.slides.filter((s) => s.id !== id),
          });
        })
        .catch((e) => {
          alert("An error occurred.");
          InspectletSendApiError(e);
        });
    }
  };

  if (!user) return null;

  return (
    <>
      <div className="mt-10 mx-auto container px-8 pb-6">
        <div className="mb-5">
          <SubscriptionInfo user={user} />
        </div>

        {slideshow.status === "rendering" && (
          <Rendering slideshow={slideshow} />
        )}

        {!redirectToDowload &&
          slideshow.status === "ready" &&
          action !== "edit" && <Ready slideshow={slideshow} />}

        {redirectToDowload && <Ready slideshow={slideshow} />}

        {(slideshow.status === null ||
          slideshow.status === "fail" ||
          action === "edit") &&
          !rendering &&
          !redirectToDowload && (
            <>
              <div className="shadow-md rounded-lg bg-white p-3 mb-5">
                <input
                  className="form-input text-xl w-full"
                  value={slideshow.title}
                  onChange={handleSlideShowAttrChange("title")}
                />
              </div>

              <div className="mb-5 flex">
                <div className="shadow-md rounded-lg bg-white p-3 flex-1 mr-2">
                  <label className="block text-sm font-semibold mb-1 text-gray-600">
                    Voice
                  </label>
                  <select
                    value={slideshow.voice}
                    onChange={handleSlideShowAttrChange("voice")}
                    className="form-select w-full"
                  >
                    {voices &&
                      voices.results &&
                      voices.results.map((voice) => (
                        <option value={voice.tts_voice_id}>
                          {voice.name_of_narrator} ({voice.language},{" "}
                          {voice.gender === "m" ? "Male" : "Female"})
                        </option>
                      ))}
                  </select>
                </div>

                <div className="shadow-md rounded-lg bg-white p-3 flex-1 ml-2">
                  <label className="block text-sm font-semibold mb-1 text-gray-600">
                    Pause between slides
                  </label>
                  <select
                    value={slideshow.slide_padding}
                    onChange={handleSlideShowAttrChange("slide_padding")}
                    className="form-select w-full"
                  >
                    <option value="500">1 sec</option>
                    <option value="1000">2 secs</option>
                    <option value="1500">3 secs (default)</option>
                    <option value="2000">4 secs</option>
                    <option value="2500">5 secs</option>
                  </select>
                </div>
              </div>

              <div className="shadow-md rounded-lg bg-white">
                {slideshow.slides.map((slide) => (
                  <div
                    key={slide.id}
                    className="border-b p-3 border-gray-200 flex relative group"
                  >
                    <button
                      onClick={() => handleSlideRemoval(slide.id)}
                      className="hidden group-hover:block origin-top-left absolute left-4 top-4 p-2 text-red-500 rounded-md shadow-md bg-white border-t border-l border-b border-gray-200 hover:bg-red-500 hover:text-white transition ease-in-out duration-150"
                    >
                      <svg
                        className="w-4 h-4"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        ></path>
                      </svg>
                    </button>
                    <div className="w-48">
                      <img
                        src={slide.image_url}
                        className="h-32"
                        alt={slide.id}
                      />
                    </div>

                    <div className="flex-grow w-full ml-3">
                      {
                        <TextEditor
                          customEmotions={customEmotions}
                          lineHeight={3}
                          text={slide.text}
                          isDrawerLarge={true}
                          user={user}
                          refreshParagraphs={(e) =>
                            onRefreshParagraphs(slide.id, e)
                          }
                          paragraphsData={slide.synthesis_json}
                          onDataChanged={(e) =>
                            handleSlideTextChange(slide.id, e)
                          }
                        />
                      }
                    </div>
                  </div>
                ))}
              </div>

              <div className="w-60 mx-auto mt-8">
                <button
                  onClick={render}
                  disabled={!hasUserFullAccess(user)}
                  className={classNames(
                    "bg-green-600 hover:bg-green-500 active:bg-green-700 w-full h-16 text-xl text-white rounded-full shadow-md transition ease-in-out duration-150 mb-12",
                    (rendering || !hasUserFullAccess(user)) &&
                      "opacity-75 pointer-events-none"
                  )}
                >
                  {rendering && <Spinner size={5} color="white" />} Render
                </button>
              </div>
            </>
          )}
      </div>
    </>
  );
};

export default SlideShowEditor;
