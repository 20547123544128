import React from "react";
import { API_URL } from "../../constants";

const Ready = ({ slideshow }) => (
  <div className="bg-green-100 mb-5 px-4 py-3 rounded-lg shadow-md">
    <div className="flex">
      <div className="w-12">
        <svg
          className="w-10 h-10"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
          ></path>
        </svg>
      </div>
      <div className="text-xl font-semibold pt-1.5">{slideshow.title}</div>
    </div>

    <div>
      <video
        src={slideshow.rendered_video_url}
        className="w-full h-auto mt-4"
        controls
      />
    </div>

    <a
      href={slideshow.rendered_video_url}
      className="mt-10 mb-8 mx-auto w-48 block bg-green-600 hover:bg-green-500 active:bg-green-700 text-center text-white py-3 rounded-full"
      download
    >
      Download video
    </a>
  </div>
);

export default Ready;
