import { message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Card from "../../common/Card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from "../../common/Table";
import moment from "moment";
import { InspectletSendApiError } from "../../common/Inspectlet";

const Invoices = () => {
  const [data, setData] = useState();

  useEffect(() => {
    axios
      .get("invoices/")
      .then((res) => setData(res.data))
      .catch((e) => {
        message.error("An error occurred.");
        InspectletSendApiError(e);
      });
  }, []);

  return (
    <div className="max-w-7xl mx-auto px-8 pb-10">
      <Card title="Invoices" className="mt-5">
        <Table className="border-t border-gray-200">
          <TableHead>
            <TableRow>
              <TableHeadCell>Date</TableHeadCell>
              <TableHeadCell>Amount</TableHeadCell>
              <TableHeadCell>Description</TableHeadCell>
              <TableHeadCell></TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, i) => (
              <TableRow key={i}>
                <TableCell>
                  {moment(row.created_at).format("MMMM Do YYYY, h:mm:ss a")}
                </TableCell>
                <TableCell>${(row.amount / 100).toFixed(2)}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell className="text-right">
                  <a href={row.download_url} target="_blank">
                    Download
                  </a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </div>
  );
};

export default Invoices;
