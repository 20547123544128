import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Spinner from "../../common/Spinner";
import { InspectletSendApiError } from "../../common/Inspectlet";

const BusinessInvitationsAccept = () => {
  const { id } = useParams();
  const [error, setError] = useState(false);

  useEffect(() => {
    axios
      .post(`auth/invitations/${id}/accept/`)
      .then(() => {
        window.location = "/";
      })
      .catch((e) => {
        setError(true);
        InspectletSendApiError(e);
      });
  }, [id]);

  return (
    <div className="flex justify-center items-center w-screen h-screen">
      {error ? (
        "An error occurred."
      ) : (
        <>
          <Spinner size="5" color="black" />
          <span className="ml-2">Accepting invitation</span>
        </>
      )}
    </div>
  );
};

export default BusinessInvitationsAccept;
