import React from "react";
import classNames from "classnames";
import Spinner from "./Spinner";

const Modal = ({
  children,
  visible,
  hideFooter,
  onConfirm,
  onCancel,
  title,
  closeButtonText,
  confirmButtonText,
  closeButtonClass,
  confirmButtonClass,
  loading,
  containerClass = "relative w-1/2 my-6 mx-auto max-w-3xl max-h-screen",
}) => {
  return (
    <>
      {visible ? (
        <>
          <div className="my-4 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className={containerClass}>
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                  <h3 className="text-2xl font-semibold">{title}</h3>
                  <button
                    disabled={loading}
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={onCancel}
                    type="button"
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-0 flex-auto overflow-y-auto max-h-96">
                  {children}
                </div>
                {/*footer*/}
                {!hideFooter && (
                  <div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
                    {closeButtonText && (
                      <button
                        className={classNames(
                          "text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1",
                          closeButtonClass
                        )}
                        type="button"
                        disabled={loading}
                        style={{ transition: "all .15s ease" }}
                        onClick={onCancel}
                      >
                        {closeButtonText}
                      </button>
                    )}

                    {confirmButtonText && (
                      <button
                        className={classNames(
                          "bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1",
                          confirmButtonClass,
                          loading && "cursor-not-allowed opacity-50 focus"
                        )}
                        type="button"
                        disabled={loading}
                        style={{ transition: "all .15s ease" }}
                        onClick={onConfirm}
                      >
                        {loading && <Spinner size={4} color="white" />}
                        {confirmButtonText}
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default Modal;
