import React from "react";
import Spinner from "../../common/Spinner";

const Rendering = ({ slideshow }) => (
  <div className="bg-blue-100 mb-5 px-4 py-3 rounded-lg shadow-md">
    <div className="flex">
      <div className="w-12">
        <svg
          className="w-10 h-10"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
          ></path>
        </svg>
      </div>
      <div className="text-xl font-semibold pt-1.5">{slideshow.title}</div>
    </div>

    <div>
      <div className="font-medium text-lg mt-4">
        Your video is being prepared now... <Spinner size={5} color="black" />
      </div>
      <p>
        This is going to take a while. You will get an email when your video is
        ready or you can always login to your dashboard and check statuses.
      </p>
    </div>
  </div>
);

export default Rendering;
