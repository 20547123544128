import React, { useState } from "react";
import { Tooltip } from "antd";
import classnames from "classnames";
import Modal from "../Modal";

const WordTagList = ({className}) => {

    const [modalVisible, setModalVisible] = useState(false);
    
    const features = [
    {
        name: "Emphasis",
        tag: "[E]",
        childs: [
            {
                name: "Yes",
                tag: "[__]"
            }
        ]
    },
    {
        name: "Pause",
        tag: "[P]",
        childs: [
            {
                name: "Short",
                tag: "[P-S]"
            },
            {
                name: "Medium",
                tag: "[P-M]"
            },
            {
                name: "Medium-Long",
                tag: "[P-ML]"
            },
            {
                name: "Long",
                tag: "[P-L]"
            },
            {
                name: "Extra-Long",
                tag: "[P-EL]"
            }
        ]
    },
    {
        name: "Emotion",
        tag: "[EM]",
        childs: [
            {
                name: "Down",
                tag: "[EM-D]"
            },
            {
                name: "Serious",
                tag: "[EM-S]"
            },
            {
                name: "Up",
                tag: "[EM-U]"
            }
        ]
    },
    {
        name: "Intensity",
        tag: "[I]",
        childs: [
            {
                name: "Low",
                tag: "[I-L]"
            },
            {
                name: "Medium",
                tag: "[I-M]"
            },
            {
                name: "High",
                tag: "[I-H]"
            }
        ]
    }
];

   
    return (
        <div className={classnames("flex", className)}>
            <div className="flex">
                <a  className=""
                    onClick={(e) => setModalVisible(true)}>
                    <span className="underline">Click to see word tagging shortcuts</span>
                </a>
            </div>
            <Modal
                hideFooter={false}
                title="Word Tags Shortcuts"
                visible={modalVisible}
                closeButtonText="Close"
                onCancel={() => setModalVisible(false)}
            >
                <ol className="list-decimal ml-10 py-5">
                    {features.map((item, i) => {
                        return (
                            <li className="focus:outline-none mb-2">
                                <span className="font-bold">
                                    {item.name} 
                                </span>
                                <ol className="list-disc ml-10">
                                    {item.childs.map((child, j) => {
                                        return (
                                            <li className="focus:outline-none">
                                                <span className="font-bold">
                                                    {child.name}: {" "} 
                                                    <span className="font-bold text-red-500">
                                                        {child.tag}
                                                    </span>         
                                                </span>
                                            </li>
                                        )
                                    })}
                                </ol>
                            </li>
                        )
                    })}
                </ol>   
            </Modal>
        </div>
    )
}

export default WordTagList;