import React from "react";

const Hero = ({ title, description }) => (
  <div className="max-w-7xl bg-cool-gray-800 px-4 py-12 sm:px-10 mx-auto sm:py-20">
    <div className="container">
      <h1 className="text-2xl sm:text-4xl mb-1 text-white">{title}</h1>
      <p className="text-base sm:text-lg text-gray-100">{description}</p>
    </div>
  </div>
);

export default Hero;
