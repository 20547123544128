import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import SlideShowList from "../../screens/SlideShowList";
import SlideShowCreate from "../../screens/SlideShowCreate";
import SlideShowEditor from "../../screens/SlideShowEditor";

const SlideShowIndex = () => {
  const match = useRouteMatch();

  return (
      <Switch>
        <Route path={`${match.url}/create`}>
          <SlideShowCreate />
        </Route>
        <Route path={`${match.url}/:id/:action?`}>
          <SlideShowEditor />
        </Route>
        <Route path={match.path}>
          <SlideShowList />
        </Route>
      </Switch>
    
  );
};

export default SlideShowIndex;
