import React, { useEffect } from "react";

const Payment3DCallback = () => {
  useEffect(() => {
    window.top.postMessage("3ds-complete");
  }, []);

  return (
    <div className="flex w-screen h-screen bg-blue-50 text-xl justify-center items-center">
      Please wait...
    </div>
  );
};

export default Payment3DCallback;
