import React from "react";
import classNames from "classnames";

const PageHeader = ({ title, className, extra, subtitle }) => (
  <div className={classNames(
      "mb-4",
      className
    )}>
    <h1 className="font-bold text-4xl flex flex-row">
      <div className="flex-grow">{title}</div>
      {extra}
    </h1>
    {subtitle && (
      <h3 className="flex flex-row">{subtitle}</h3>
    )}
  </div>
);

export default PageHeader;
