import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import Dashboard from "../../screens/Dashboard";
import Developers from "../../screens/Developers";
import VoiceSynthesisIndex from "../../screens/VoiceSynthesis";
import SlideShowIndex from "../SlideShowIndex";
import Header from "./Header";
import RouteWithTitle from "../../common/RouteWithTitle";
import Voice from "../../screens/Voices";
import Pricing from "../../screens/Pricing";
import { getUser } from "../../common/auth";
import MembersIndex from "../MembersIndex";
import Billing from "../../screens/Billing";
import Invoices from "../../screens/Billing/Invoices";
import InspectletInit from "../../common/Inspectlet";
import LexiconCreate from "../../screens/LexiconCreate";

const AppIndex = () => {
  const [user, setUser] = useState(null);
  const [hideMenu, setHideMenu] = useState(false);
  const location = useLocation();

  useEffect(() => {
    (async () => setUser(await getUser()))();
  }, []);

  if (!user) return null;

  InspectletInit(user);
  
  if (!user.business.subscription) {
    if (location.pathname !== "/pricing") {
      return (
        <Redirect
          to={{
            pathname: "/pricing",
            state: { from: location },
          }}
        />
      );
    }
  }

  return (
    <div className="bg-gray-100 min-h-screen">
      <Header user={user} hideMenu={!user.business.subscription} />
      <Switch>
        <RouteWithTitle
          title="DeepZen App | Voice Maker"
          exact={true}
          component={VoiceSynthesisIndex}
          path="/voice"
        />
        <RouteWithTitle
          title="DeepZen App | Voice Library"
          exact={true}
          component={Voice}
          path="/voice-library"
        />
        <RouteWithTitle
          title="DeepZen App | Developers"
          exact={true}
          component={Developers}
          path="/developers"
        />
        <RouteWithTitle
          title="DeepZen App | Video Maker"
          component={SlideShowIndex}
          path="/video"
        />
        <RouteWithTitle
          title="DeepZen App | Dashboard"
          exact={true}
          component={Dashboard}
          path="/"
        />
        <RouteWithTitle
          title="DeepZen App | Dashboard"
          exact={true}
          component={Dashboard}
          path="/dashboard"
        />
        <RouteWithTitle
          title="DeepZen App | Pricing"
          exact={true}
          component={Pricing}
          path="/pricing"
        />
        <RouteWithTitle
          title="DeepZen App | Members"
          component={MembersIndex}
          path="/members"
        />
        <RouteWithTitle
          title="DeepZen App | Billing"
          exact={true}
          component={Billing}
          path="/billing"
        />
        <RouteWithTitle
          title="DeepZen App | Invoices"
          exact={true}
          component={Invoices}
          path="/billing/invoices"
        />
        <RouteWithTitle
          title="DeepZen App | Pronunciations"
          exact={true}
          component={LexiconCreate}
          path="/lexicon"
        />
      </Switch>
    </div>
  );
};

export default AppIndex;