import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Select } from "antd";
import Spinner from "../../common/Spinner";
import TextEditor from "../../common/TextEditor";
import classnames from "classnames";
import { useVoices, getCustomEmotionsById } from "../../common/voices";
import { hasUserFullAccess } from "../../common/auth";

const { Option } = Select;

function Form({ data, user, handleSubmit, loading }) {
  const [formButtonDisabled, setFormButtonDisabled] = useState(false);
  const [customEmotions, setCustomEmotions] = useState(null);

  const voices = useVoices();
  const synthesisTypeList = [
    "High Quality - Slow synthesis",
    "Basic Quality - Fast synthesis",
  ];
  
  const synthesisChildren = [];
  for (let i = 0; i < synthesisTypeList.length; i++) {
    synthesisChildren.push(
      <Option key={synthesisTypeList[i]}>{synthesisTypeList[i]}</Option>
    );
  }

  const [formData, setFormData] = useState({
    title: "",
    text: "",
    synthesisType: synthesisTypeList[1],
    voice: voices
      ? voices.results
        ? voices.results[0].tts_voice_id
        : null
      : null,
  });

  const [formValidation, setFormValidation] = useState({
    title: "",
    text: "",
    voice: "",
  });

  const [paragraphs, setParagraphs] = useState(null);

  useEffect(() => {
    if (voices) {
      if (formData) {
        if(!formData.voice){
          let _formData = {
            ...formData,
            voice: voices.results
              ? voices.results[0].tts_voice_id
              : null,
          };
          setFormData(_formData);
          checkFormValidation(_formData);
          setCustomEmotions(getCustomEmotionsById(voices, _formData.voice));
        }else{
          setCustomEmotions(getCustomEmotionsById(voices, formData.voice));
        }
      }
    }
  }, [voices]);

  useEffect(() => {
    if (data) {
      let _formData = {
        ...formData,
        title: data.title,
        voice: data.voice,
        text: data.text,
      };
      
      setFormData(_formData);
      checkFormValidation(_formData);

      if (data.synthesis_json) {
        setParagraphs([...data.synthesis_json]);
      } else {
        setParagraphs(null);
      }

      setCustomEmotions(getCustomEmotionsById(voices, _formData.voice));

    } else {
      let _formData = {
        title: "",
        text: "",
        synthesisType: synthesisTypeList[1],
        voice: voices
          ? voices.results
            ? voices.results[0].tts_voice_id
            : null
          : null,
      };
      setFormData(_formData);
      checkFormValidation(_formData);
      setParagraphs(null);
      setCustomEmotions(getCustomEmotionsById(voices, _formData.voice));
    }
  }, [data]);

  const checkFormValidation = (e) => {
    formValidation.title = !e.title ? "*Required" : "";
    formValidation.voice = !e.voice ? "*Required" : "";
    formValidation.text = !e.text ? "*Required" : "";

    setFormValidation({ ...formValidation });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    checkFormValidation(formData);
    let valid = true;
    for (let key in formValidation) {
      valid = valid && !formValidation[key];
    }

    if (valid) {
      handleSubmit({
        title: formData.title,
        text: formData.text,
        synthesis_json: formData.synthesis_json,
        voice: formData.voice,
      });
    }
  };

  const onFormChange = (e) => {

    if(e.voice !== formData.voice){
      setCustomEmotions(getCustomEmotionsById(voices, e.voice));
    }
    setFormData(e);
    checkFormValidation(e);
  };

  const handleTextEditorData = (e) => {
    let _formData = {
      ...formData,
      text: e.text,
      synthesis_json: e.synthesis_json,
    };
    setFormData(_formData);
    checkFormValidation(_formData);
  };

  const onRefreshParagraphs = (e) => {
    setFormData({ ...formData, text: e.paragraphsText });
  };

  if(!user) return null;
 
  return (
    <div>
      <form onSubmit={onSubmit} className="px-2">
        <div className="w-full flex mb-10">
          <div className="w-2/3 mr-10">
            <label
              id="name-label"
              className="block text-sm font-medium text-gray-700"
            >
              Title
            </label>
            <input
              name="title"
              type="text"
              value={formData.title}
              onChange={(e) =>
                onFormChange({ ...formData, title: e.target.value })
              }
              className="form-input block w-full text-sm"
            />
            {formValidation.title && (
              <label className="block text-sm font-medium text-red-400">
                {formValidation.title}
              </label>
            )}
          </div>
          <div className="w-1/3">
            <label
              id="voice-label"
              className="block text-sm font-medium text-gray-700"
            >
              Voice
            </label>
            <Select
              defaultValue={formData.voice}
              value={formData.voice}
              size="large"
              onChange={(e) => onFormChange({ ...formData, voice: e })}
              style={{ width: "100%" }}
            >
              {voices &&
                voices.results &&
                voices.results.map((voice) => (
                  <Option
                    key={voice.id}
                    value={voice.tts_voice_id}
                  >
                    {voice.name_of_narrator} ({voice.language},{" "}
                    {voice.gender === "m" ? "Male" : "Female"})
                  </Option>
                ))}
            </Select>
            {formValidation.voice && (
              <label className="block text-sm font-medium text-red-400">
                {formValidation.voice}
              </label>
            )}
          </div>
        </div>

        <div className="w-full mb-10">
          <TextEditor
            customEmotions={customEmotions}
            user={user}
            text={formData.text}
            onDataChanged={handleTextEditorData}
            paragraphsData={paragraphs}
            refreshParagraphs={(e) => onRefreshParagraphs(e)}
            resetEvents={true}
            onModeChanged={(e) => setFormButtonDisabled(e)}
          />

          {formValidation.text && (
            <label className="block text-sm font-medium text-red-400">
              {formValidation.text}
            </label>
          )}
        </div>

        <div className="clearfix mt-4 text-right">
          <button
            type="submit"
            disabled={loading || formButtonDisabled || !hasUserFullAccess(user)}
            className={classnames(
              "inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2",
              (formButtonDisabled || !hasUserFullAccess(user))
                ? "bg-gray-400 pointer-events-none focus:ring-gray-300"
                : "bg-blue-600 hover:bg-blue-700 focus:ring-blue-500"
            )}
          >
            {!loading && <span>Synthesize</span>}
            {loading && (
              <>
                <Spinner color="white" size={6} />
                <span className="ml-3">Loading...</span>
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
}

export default Form;
