import React, { useEffect, useState } from "react";
import { useUser } from "../../common/auth";
import BookOutlinedIcon from "../../Icons/BookOutlinedIcon";
import RightArrow from "../../Icons/RightArrow";
import CloseIcon from "../../Icons/CloseIcon";
import AudioIcon from "../../Icons/AudioIcon";
import VideoIcon from "../../Icons/VideoIcon";
import MoneyIcon from "../../Icons/MoneyIcon";
import moment from "moment";
import axios from "axios";
import { message } from "antd";
import Card, { CardBody } from "../../common/Card";
import { Link } from "react-router-dom";
import AccountBookIcon from "../../Icons/AccountBookIcon";
import Modal from "../../common/Modal";
import Create from "../PaymentMethods/Create";
import BillingForm from "./Form";
import Button from "../../common/Button";
import { SubscriptionInfo } from "../../common/SubscriptionInfo";
import { InspectletSendApiError } from "../../common/Inspectlet";

export const packageTitles = {
  enterprise: "Enterprise",
  professional: "Professional",
  startup: "Start-Up/Scale-Up",
};

const timeFormat = (seconds) =>
  new Date(seconds * 1000).toISOString().substr(11, 8);

export const handleCancelSubscription = () => {
  if (window.confirm("Are you sure you want to cancel?")) {
    axios
      .post("billing/cancel/")
      .then((res) => {
        message.success(
          "Your subscription has been canceled successfully and won't renew after current period."
        );
        setTimeout(() => window.location.reload(), 1000);
      })
      .catch((e) => {
        message.error("An error occurred.");
        InspectletSendApiError(e);
      });
  }
};

const Billing = () => {
  const user = useUser();
  const [billingData, setBillingData] = useState(null);

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showBillingModal, setShowBillingModal] = useState(false);

  useEffect(() => {
    axios
      .get("billing/")
      .then((res) => setBillingData(res.data))
      .catch((e) => {
        message.error("An error occurred.");
        InspectletSendApiError(e);
      });
  }, []);

  if (!user || !billingData) return null;

  return (
    <div className="max-w-7xl mx-auto px-8 pb-10">
      <SubscriptionInfo user={user} />
      <Card
        title={`Usage between ${moment(
          user.business.subscription.starts_at
        ).format("YYYY-MM-DD")} and ${moment(
          user.business.subscription.ends_at
        ).format("YYYY-MM-DD")}`}
        className="mt-5"
      >
        <CardBody padding="p-0">
          {user.business.subscription?.plan !== "enterprise" &&
            user.business.subscription?.status !== "trialing" && (
              <div className="w-full flex border-b border-gray-200 px-4 pt-3 pb-4">
                <MoneyIcon size="1.5em" className="mt-1" />
                <div className="flex-shrink pl-5 w-full">
                  <div className="bold text-lg">Usage</div>
                  <div className="capitalize">
                    ${billingData.credit.used_dollars.toFixed(2)} / $
                    {billingData.credit.total_dollars.toFixed(2)}
                  </div>
                </div>
              </div>
            )}
          <div className="w-full flex px-4 pt-3 pb-4">
            <AudioIcon size="1.5em" className="mt-1" />
            <div className="flex-shrink pl-5 w-full">
              <div className="bold text-lg">Audio</div>
              <div className="capitalize">
                {timeFormat(billingData.usage.audio)}
              </div>
            </div>
          </div>
          <div className="w-full border-t border-gray-200 flex px-4 pt-3 pb-4">
            <VideoIcon size="1.5em" className="mt-1" />
            <div className="flex-shrink pl-5 w-full">
              <div className="bold text-lg">Video</div>
              <div className="capitalize">
                {timeFormat(billingData.usage.video)}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>

      <Card title="Subscription" className="mt-5">
        <CardBody padding="p-0">
          <Link
            to="/pricing"
            className="w-full flex cursor-pointer hover:text-gray-700 px-4 pt-3 pb-4"
          >
            <AccountBookIcon size="1.5em" className="mt-1" />
            <div className="flex-shrink pl-5 w-full">
              <div className="bold text-lg">Current Plan</div>
              <div className="capitalize">
                {user.business.subscription?.plan &&
                  packageTitles[user.business.subscription.plan]}
              </div>
            </div>
            <div className="flex justify-end items-center">
              <RightArrow size="1.5em" />
            </div>
          </Link>
          <Link
            to="/billing/invoices"
            className="w-full flex cursor-pointer hover:text-gray-700 px-4 pt-3 pb-4 border-t border-gray-200"
          >
            <AccountBookIcon size="1.5em" className="mt-1" />
            <div className="flex-shrink pl-5 w-full">
              <div className="bold text-lg">Invoices</div>
              <div className="capitalize">All past payments</div>
            </div>
            <div className="flex justify-end items-center">
              <RightArrow size="1.5em" />
            </div>
          </Link>
          {user.business.subscription.plan !== "enterprise" && (
            <>
              <a
                onClick={() => setShowPaymentModal(true)}
                className="w-full flex border-t border-gray-200 px-4 pt-3 pb-4 cursor-pointer hover:text-gray-700"
              >
                <BookOutlinedIcon size="1.5em" className="mt-1" />
                <div className="flex-shrink pl-5 w-full">
                  <div className="bold text-lg">Update Payment Method</div>
                  <div className="pt-0">
                    {user.business.payment_method && (
                      <span className="">
                        {user.business.payment_method.brand.toUpperCase()}{" "}
                        ending with {user.business.payment_method.last4}
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex justify-end items-center">
                  <RightArrow size="1.5em" />
                </div>
              </a>

              <a
                onClick={() => setShowBillingModal(true)}
                className="w-full flex border-t border-gray-200 px-4 pt-3 pb-4 cursor-pointer hover:text-gray-700"
              >
                <BookOutlinedIcon size="1.5em" className="mt-1" />
                <div className="flex-shrink pl-5 w-full">
                  <div className="bold text-lg">Update Billing Details</div>
                  <div className="pt-0">
                    <span className="">{user.business?.billing_name}</span>
                  </div>
                </div>
                <div className="flex justify-end items-center">
                  <RightArrow size="1.5em" />
                </div>
              </a>

              {user.business.subscription?.status !== "canceled" && (
                <a
                  onClick={handleCancelSubscription}
                  className="w-full flex border-t border-gray-200 pt-3 pb-4 px-4 cursor-pointer hover:text-gray-700"
                >
                  <CloseIcon size="1.5em" className="mt-1" />
                  <div className="flex-shrink pl-5 w-full">
                    <div className="bold text-lg">Cancel Subscription</div>
                    <div className="pt-0">
                      <span className="">
                        You will still have access to your plan until{" "}
                        {moment(user.business.subscription?.ends_at).format(
                          "YYYY-MM-DD"
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-end items-center">
                    <RightArrow size="1.5em" />
                  </div>
                </a>
              )}

              {user.business.subscription?.status === "canceled" && (
                <div
                  className="w-full flex border-t border-gray-200 pt-3 pb-4 px-4 cursor-pointer hover:text-gray-700"
                >
                  <div className="flex-shrink pl-10 w-full">
                    <div className="bold text-lg">Canceled</div>
                    <div className="pt-0">
                      {moment(user.business.subscription.ends_at).isAfter(moment()) 
                        && user.business.subscription.credit?.used < user.business.subscription.credit?.total && (
                            <span className="">
                              You will still have access to your plan until{" "}
                                {moment(user.business.subscription?.ends_at).format(
                                "YYYY-MM-DD"
                                )}
                            </span>
                        )}
                    </div>
                  </div>
                  <div className="flex justify-end items-center">
                    <RightArrow size="1.5em" />
                  </div>
                </div>
              )}
            </>
          )}
        </CardBody>
      </Card>

      <Modal
        hideFooter={true}
        title="Update Payment Method"
        visible={showPaymentModal}
        onCancel={() => setShowPaymentModal(false)}
      >
        <Create
          onCreated={(e) => {
            setShowPaymentModal(false);
            message.success("Payment method has been updated successfully.");
            window.location.reload();
          }}
        />
      </Modal>

      <Modal
        hideFooter={true}
        title="Update Billing Details"
        visible={showBillingModal}
        onCancel={() => setShowBillingModal(false)}
      >
        <BillingForm
          user={user}
          onSaved={() => {
            setShowBillingModal(false);
            message.success("Billing details has been updated successfully.");
            window.location.reload();
          }}
        />
      </Modal>
    </div>
  );
};

export default Billing;
