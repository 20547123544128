import React, { useState, useEffect } from "react";
import Card, { CardBody } from "../../common/Card";
import "antd/dist/antd.css";
import { Select, Icon } from "antd";
import Word from "./Word";
import classnames from "classnames";
import axios from "axios";
import { InspectletSendApiError } from "../../common/Inspectlet";

const { Option } = Select;

const TextSettings = ({
  sentence,
  user,
  editedSentence,
  saveTextSettings,
  refreshParagraphs,
  customEmotions,
}) => {
  const [selectedWordId, setSelectedWordId] = useState(null);
  const [formData, setFormData] = useState(editedSentence);
  const [editSentence, setEditSentence] = useState(false);
  const [emotionList, setEmotionList] = useState(null);

  useEffect(() => {
    setFormData({
      ...formData,
      words: formData.words.map((w, i) => ({ ...w, active: false })),
    });
  }, []);

  useEffect(() => {
    if(customEmotions && customEmotions.length > 0){
       const _emotionList = [{title: "No emotion", value: null}].concat(customEmotions);
       setEmotionList(_emotionList);
    }else{
      setEmotionList([
        {title: "No emotion", value: null},
        {title: "Anger", value: "anger"},
        {title: "Disgust", value: "disgust"},
        {title: "Excited", value: "excited"},
        {title: "Fear", value: "fear"},
        {title: "Happy", value: "happy"},
        {title: "Sad", value: "sad"},
        {title: "Surprise", value: "surprise"}
      ])
    }
  }, [customEmotions]);

  const intensityList = [null, "low", "medium", "high"];
  const intensityLabelList = ["No intensity", "Low", "Medium", "High"];
  const intensityChildren = [];
  for (let i = 0; i < intensityList.length; i++) {
    intensityChildren.push(
      <Option key={intensityList[i]} value={intensityList[i]}>
        {intensityLabelList[i]}
      </Option>
    );
  }

  const pauseList = [
    null,
    "short",
    "medium",
    "medium-long",
    "long",
    "extra-long",
  ];
  const pauseLabelList = [
    "No pause",
    "Short",
    "Medium",
    "Medium-Long",
    "Long",
    "Extra-Long",
  ];
  const pauseChildren = [];
  for (let i = 0; i < pauseList.length; i++) {
    pauseChildren.push(
      <Option key={pauseList[i]} value={pauseList[i]}>
        {pauseLabelList[i]}
      </Option>
    );
  }

  const emphasisList = [true, false];
  const emphasisLabelList = ["Yes", "No"];
  const emphasisChildren = [];
  for (let i = 0; i < emphasisList.length; i++) {
    emphasisChildren.push(
      <Option key={emphasisList[i]} value={emphasisList[i]}>
        {emphasisLabelList[i]}
      </Option>
    );
  }

  const onClickWord = (id) => {
    setSelectedWordId(id);
    setFormData({
      ...formData,
      words: formData.words.map((w, i) =>
        i === id ? { ...w, active: true } : { ...w, active: false }
      ),
    });
  };

  const getSentenceArray = () => {
    axios
      .post(`voicemaker/text-analysis/`, { text: formData.text })
      .then((res) => {
        let d = {
          ...formData,
          text: res.data[0][0].text,
          words: res.data[0][0].words.map((w, i) => ({ ...w, active: false })),
        };
        refreshParagraphs(d);
        setFormData(d);
        setEditSentence(false);
        setSelectedWordId(null);
      })
      .catch((e) => {
        console.error(e);
        InspectletSendApiError(e);
      });
  };

  const onClickSentenceTextSave = () => {
    getSentenceArray();
  };

  const onClickSentenceTextClose = () => {
    setEditSentence(false);
    setFormData({ ...formData, text: editedSentence.text });
  };

  if (!sentence) return null;

  return (
    <div className="h-full">
      <Card
        title="Emphasis & Word Pause Settings"
        headerSize="small"
        headerColor="gray"
        flatContainer
        className="py-3"
      >
        <CardBody>
          <div>
            <div className="w-full flex px-2">
              <label className="text-gray-900 font-medium mb-0.5 block text-xs break-normal">
                Emphasis and word pauses can only be changed at a word level
              </label>
            </div>

            {!editSentence && formData.words && (
              <div className="w-full px-2 py-5">
                {formData.words.map((word, index) => {
                  return (
                    <Word
                      key={index}
                      wordId={index}
                      clickable={true}
                      onClickWord={onClickWord}
                      isActive={word.active}
                      editedWord={word}
                      showDefaultData={true}
                      word={sentence.words[index]}
                    />
                  );
                })}
                <div className="inline-block">
                  <button
                    type="button"
                    onClick={(e) => setEditSentence(true)}
                    className="relative inline-flex items-center px-3 py-0 border-none text-sm font-medium text-sm text-gray-500 focus:outline-none transition ease-in-out duration-150"
                  >
                    <Icon
                      type="edit"
                      className="text-blue-600"
                      style={{ paddingRight: 5, fontSize: 12 }}
                    />
                    <span className="text-xs text-blue-600">Edit</span>
                  </button>
                </div>
              </div>
            )}

            {editSentence && (
              <div className="relative focus-within:z-10 flex-grow px-2 py-5">
                <div className="text-gray-500 font-medium mb-0.5 flex justify-between text-xs">
                  <span>Text (T)</span>
                  <div className="flex justify-end px-1">
                    <button
                      type="button"
                      disabled={formData && formData.text ? false : true}
                      onClick={(e) => onClickSentenceTextSave()}
                      className={classnames(
                        "relatve inline-flex items-center px-3 py-0 mr-2 rounded-md text-white leading-5 font-medium text-xs transition ease-in-out duration-150",
                        formData && formData.text
                          ? "focus:border-blue-300 bg-blue-600 hover:bg-blue-500 focus:bg-blue-700 active:bg-blue-700 focus:shadow-outline-blue"
                          : "bg-gray-400 pointer-events-none focus:ring-gray-300"
                      )}
                    >
                      <span>Save</span>
                    </button>
                    <button
                      type="button"
                      onClick={(e) => onClickSentenceTextClose()}
                      className="relatve inline-flex items-center px-3 py-0 rounded-md text-white leading-5 font-medium text-xs transition ease-in-out duration-150 focus:border-red-300 bg-red-500 hover:bg-red-400 focus:bg-red-500 active:bg-red-500 focus:shadow-outline-red"
                    >
                      <span>Close</span>
                    </button>
                  </div>
                </div>
                <textarea
                  className="block bg-white break-normal outline-none sm:text-sm shadow-sm form-input w-full sm:leading-5 focus:shadow-outline-blue py-1 px-2"
                  onChange={(e) =>
                    setFormData({ ...formData, text: e.target.value })
                  }
                  rows="4"
                  defaultValue={formData.text}
                />
                <div className="px-1">
                  <label className="text-red-600 font-small mb-0.5 text-xs break-normal">
                    Warning: Sentence can be changed, but a new sentence cannot
                    be added.
                  </label>
                </div>
              </div>
            )}

            <div className="w-full flex px-2 grid sm:grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
              <div className="">
                <label className="text-gray-500 font-medium mb-0.5 block text-xs">
                  Emphasis (E)
                </label>
                <Select
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      words: formData.words.map((v, i) =>
                        i === selectedWordId ? { ...v, emphasis: e } : v
                      ),
                    })
                  }
                  value={
                    selectedWordId !== null
                      ? formData.words[selectedWordId].emphasis
                        ? formData.words[selectedWordId].emphasis
                        : emphasisList[1]
                      : emphasisList[1]
                  }
                  size="small"
                  disabled={selectedWordId === null}
                  style={{ width: "100%" }}
                >
                  {emphasisChildren}
                </Select>
              </div>

              <div className="">
                <label className="text-gray-500 font-medium mb-0.5 block text-xs">
                  Pause (P)
                </label>
                <Select
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      words: formData.words.map((v, i) =>
                        i === selectedWordId ? { ...v, pause: e } : v
                      ),
                    })
                  }
                  value={
                    selectedWordId !== null
                      ? formData.words[selectedWordId].pause
                        ? formData.words[selectedWordId].pause
                        : pauseList[0]
                      : pauseList[0]
                  }
                  size="small"
                  disabled={selectedWordId === null}
                  style={{ width: "100%" }}
                >
                  {pauseChildren}
                </Select>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>

      <Card
        title="Emotion & Sentence Pause Settings"
        headerSize="small"
        headerColor="gray"
        flatContainer
        className="pb-3"
      >
        <CardBody>
          <div className="w-full flex px-2 pb-5">
            <label className="text-gray-900 font-medium mb-0.5 block text-xs break-normal">
              Emotion and sentence pauses can only be changed at a sentence
              level
            </label>
          </div>

          <div className="w-full flex grid sm:grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-3 px-2 pt-1 pb-4">
            <div className="">
              <label className="text-gray-500 font-medium mb-0.5 block text-xs">
                Emotion (EM)
              </label>
              <Select
                onChange={(e) => setFormData({ ...formData, emotion: e })}
                value={
                  formData
                    ? formData.emotion
                      ? formData.emotion
                      : emotionList ? emotionList[0].value : null
                    : emotionList ? emotionList[0].value : null
                }
                size="small"
                style={{ width: "100%" }}
              >
                {emotionList && emotionList.map((emotion) => (
                    <Option key={emotion.value} value={emotion.value}>
                      {emotion.title}
                    </Option>
                ))}
                
              </Select>
            </div>

            <div className="">
              <label className="text-gray-500 font-medium mb-0.5 block text-xs">
                Intensity (I)
              </label>
              <Select
                onChange={(e) =>
                  setFormData({ ...formData, emotion_intensity: e })
                }
                value={
                  formData
                    ? formData.emotion_intensity
                      ? formData.emotion_intensity
                      : intensityList[0]
                    : intensityList[0]
                }
                size="small"
                style={{ width: "100%" }}
              >
                {intensityChildren}
              </Select>
            </div>

            <div className="">
              <label className="text-gray-500 font-medium mb-0.5 block text-xs break-normal">
                Pause After Sentence (P)
              </label>
              <Select
                onChange={(e) => setFormData({ ...formData, pause: e })}
                value={
                  formData
                    ? formData.pause
                      ? formData.pause
                      : pauseList[0]
                    : pauseList[0]
                }
                size="small"
                style={{ width: "100%" }}
              >
                {pauseChildren}
              </Select>
            </div>
          </div>
        </CardBody>
      </Card>

      <div className="flex px-5 mb-5">
        <button
          type="button"
          disabled={formData ? (formData.text ? false : true) : true}
          onClick={(e) => saveTextSettings(formData)}
          className={classnames(
            "relative inline-flex items-center rounded-md focus:outline-none transition ease-in-out duration-150 px-4 py-1.5 text-md font-medium leading-6 text-white w-full justify-center",
            formData && formData.text
              ? "focus:border-blue-300 bg-blue-600 hover:bg-blue-500 focus:bg-blue-700 active:bg-blue-700 focus:shadow-outline-blue"
              : "bg-gray-400 pointer-events-none focus:ring-gray-300"
          )}
        >
          Save & Close
        </button>
      </div>
    </div>
  );
};

export default TextSettings;
