import React, { useState, useEffect } from "react";
import { useUser } from "../../common/auth";
import Card, { CardBody } from "../../common/Card";
import PageHeader from "../../common/PageHeader";
import { NavLink } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import {
  Table,
  TableRow,
  TableHead,
  TableHeadCell,
  TableCell,
  TableBody,
} from "../../common/Table";
import Spinner from "../../common/Spinner";
import { message } from "antd";
import Alert from "../../common/Alert";
import { SubscriptionInfo } from "../../common/SubscriptionInfo";
import { InspectletSendApiError } from "../../common/Inspectlet";

const Members = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const user = useUser();

  const reload = () => {
    setLoading(true);
    axios
      .get("subusers/")
      .then((res) => setData(res.data))
      .catch((e) => {
        message.error("An error occurred.");
        InspectletSendApiError(e);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    reload();
  }, []);

  const handleDeleteInvitation = (invitation) => {
    if (window.confirm("Are you sure?")) {
      axios
        .delete(`subusers/${invitation.id}/`)
        .then((res) => {
          message.success("Invitation has been removed");
          reload();
        })
        .catch((e) => {
          message.error("An error occurred.");
          InspectletSendApiError(e);
        });
    }
  };

  if (!user) return null;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

      <SubscriptionInfo user={user} />
      
      <PageHeader
        className="mt-12"
        title="Members"
        extra={
          user.business.subscription?.plan !== "professional" ? (
            <NavLink
              to="/members/create"
              className="relative inline-flex items-center px-4 py-1.5 my-1 self-end border border-transparent text-sm leading-5 font-medium rounded-full text-white bg-blue-600 shadow-sm hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition ease-in-out duration-150"
            >
              <svg
                className="-ml-1 mr-2 h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                  clipRule="evenodd"
                />
              </svg>
              <span>Invite</span>
            </NavLink>
          ) : (
            <></>
          )
        }
      />

      <Card>
        <CardBody padding="p-0">
          {data?.users.length > 0 ? (
            <Table className="border-t border-gray-200">
              <TableHead>
                <TableRow>
                  <TableHeadCell>E-Mail</TableHeadCell>
                  <TableHeadCell>Name</TableHeadCell>
                  <TableHeadCell>Joined At</TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.users.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell className="font-medium pt-3">
                      <span className="inline-block max-w-md truncate">
                        {row.email}
                      </span>
                    </TableCell>
                    <TableCell className="font-medium pt-3 flex">
                      <span className="inline-block max-w-md truncate">
                        {row.first_name} {row.last_name}
                      </span>
                    </TableCell>
                    <TableCell className="font-medium pt-3">
                      <div className="flex justify-between">
                        <span className="inline-block max-w-md truncate">
                          {moment(row.created_at).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )}
                        </span>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <div className="p-4">
              {loading ? (
                <div className="flex justify-start items-center p-4">
                  <Spinner size={6} />
                  <span className="ml-3">Loading members...</span>
                </div>
              ) : (
                <div className="flex justify-center items-center p-4">
                  <span className="text-lg">No data</span>
                </div>
              )}
            </div>
          )}
        </CardBody>
      </Card>

      {user.business.subscription?.plan === "professional" && (
        <Alert type="warning" className="my-1" link="/pricing">
          <span>
            Upgrade your account to Start-Up/Scale-Up to add more members
          </span>
        </Alert>
      )}

      {data?.invitations.length > 0 && (
        <>
          <PageHeader title="Pending Invitations" className="mt-12" />

          <Card>
            <Table className="border-t border-gray-200">
              <TableHead>
                <TableRow>
                  <TableHeadCell>E-Mail</TableHeadCell>
                  <TableHeadCell>Invited By</TableHeadCell>
                  <TableHeadCell>Date</TableHeadCell>
                  <TableHeadCell></TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.invitations.map((row) => (
                  <TableRow key={row.email}>
                    <TableCell className="font-medium pt-3">
                      <span className="inline-block max-w-md truncate">
                        {row.email}
                      </span>
                    </TableCell>
                    <TableCell className="font-medium pt-3 flex">
                      <span className="inline-block max-w-md truncate">
                        {row.inviter.first_name} {row.inviter.last_name}
                      </span>
                    </TableCell>
                    <TableCell className="font-medium pt-3">
                      <div className="flex justify-between">
                        <span className="inline-block max-w-md truncate">
                          {moment(row.created_at).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell className="font-medium pt-3 text-right">
                      <a onClick={() => handleDeleteInvitation(row)}>Remove</a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        </>
      )}
    </div>
  );
};

export default Members;
