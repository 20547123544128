import React, {useState, forwardRef, useImperativeHandle}  from "react";
import Search from "../Search";
import Spinner from "../Spinner";
import Modal from "../Modal";
import SearchIcon from "../../Icons/SearchIcon";
import SettingsIcon from "../../Icons/SettingsIcon";
import Button from "../Button";
import classNames from "classnames";
import axios from "axios";
import { Menu, Dropdown, message } from "antd";
import { InspectletSendApiError } from "../Inspectlet";
import { DEFAULT_COLLECTION_NAME } from "./bulkActions";

const collectionActionTypes = {
  edit: "1",
  delete: "2",
  new: "3"
};

export const getCollections = (type, search) => axios.get(`collections/?search=${search}&type=${type}`);

const Collections = forwardRef(({ type, handleCollection, handleDefaultCollectionId }, ref) => {
    
    const [collections, setCollections] = useState(null);
    const [loading, setLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState(null);
    const [selectedCollection, setSelectedCollection] = useState(null);
    const [error, setError] = useState(null);
    const [collectionActionType, setCollectionActionType]=useState(null);
    const [editedCollections, setEditedCollections] = useState({});
    const [newCollection, setNewCollection] = useState(null);
    const [warningModalVisible, setWarningModalVisible] = useState(false);


    useImperativeHandle(ref, () => ({
            reload() {
                fetchCollections(searchQuery); 
            },
            unSelectCollection(){
              setSelectedCollection(null);
              handleCollection(null);
            }
    }));

    const handleSearch = (e) => {
      setLoading(true);
      setSearchQuery(e);
      fetchCollections(e);
    }

    const fetchCollections = (s) => {
        setLoading(true);
        setCollections(null);
        setError(null);
        setCollectionActionType(null);
        setEditedCollections({});
        setFormLoading(false);
        getCollections(type, s)
            .then((res) => {
                setLoading(false);
                setCollections(res.data.results);
                
                if(res.data.results && res.data.results.length > 0){
                  const defaultCollection = res.data.results.filter((c) => c.name === DEFAULT_COLLECTION_NAME)[0];
                  handleDefaultCollectionId(
                    defaultCollection ? defaultCollection.id : null);

                  if(selectedCollection){
                    const uptoDateCollection = res.data.results.filter((r) => r.id === selectedCollection.id)[0];
                    setSelectedCollection(uptoDateCollection);
                    handleCollection(uptoDateCollection);
                  }
                  
                }
            })
            .catch((e) => {
                setLoading(false);
                setError("An error occurred while fetching collections.");
                InspectletSendApiError(e);
            });
    }
    
    const onClickCollection = (e) => {
      setSelectedCollection(e);
      handleCollection(e);
    }

    const onEditCollection = (e, value) => {
      editedCollections[e.id] = {...e, name:value};
      setEditedCollections({...editedCollections});
    }

    const onChecked = (c, e) => {
      editedCollections[c.id] = e.target.checked ? c : null;
      setEditedCollections({...editedCollections});
    }

    const onClickSave = () => {
      const editedCollectionArray = Object.values(editedCollections);
      const emptyCollection = editedCollectionArray.filter((ec) => !ec.name)[0];
      if(emptyCollection){
        message.error("Collection name can not be empty!");
      }else{
        setFormLoading(true);
        axios
          .put(`collections/`, {
            renames: editedCollectionArray,
            type: type
          })
          .then((res) => {
            setFormLoading(false);
            setCollectionActionType(null);
            setEditedCollections({});
            message.success("Successful");
            fetchCollections(searchQuery);
          })
          .catch((e) => {
            setFormLoading(false);
            message.error("An error occurred.");
            InspectletSendApiError(e);
        });
      }
    };

    const onClickDelete = () => {
      
      const editedCollectionArray = Object.values(editedCollections);
      const filteredCollectionArray = editedCollectionArray.filter((ec) => ec !== null);
      if(!filteredCollectionArray){
        message.error("Please select at least one collection!");
      }else{
        const filteredCollectionIdArray = filteredCollectionArray.map((fc) => fc.id);
        setFormLoading(true);
        axios
          .delete(`collections/`, {
            data: {
              items: filteredCollectionIdArray,
              type: type
            }
          })
          .then((res) => {
            setFormLoading(false);
            setCollectionActionType(null);
            setEditedCollections({});
            setWarningModalVisible(false);
            message.success("Successful");
            fetchCollections(searchQuery);
          })
          .catch((e) => {
            setFormLoading(false);
            message.error("An error occurred.");
            InspectletSendApiError(e);
        });
      } 
    };

    const onClickNewCollection = () => {
      if(!newCollection){
        message.error("Please type collection name!");
      
      }else if (newCollection === DEFAULT_COLLECTION_NAME){
        message.warning("This name is reserved, please type different name.");
        
      }else{
        setFormLoading(true);
        axios
          .post(`collections/`, {
            name: newCollection,
            type: type
          })
          .then((res) => {
            setFormLoading(false);
            setCollectionActionType(null);
            setEditedCollections({});
            message.success("Successful");
            fetchCollections(searchQuery);
          })
          .catch((e) => {
            setFormLoading(false);
            message.error("An error occurred.");
            InspectletSendApiError(e);
        });
      }
    };

    const onClickCancel = () => {
      setCollectionActionType(null);
      setEditedCollections({});
      setFormLoading(false);
    };

    const onClickCollectionActionType = (t) => {
      setEditedCollections({});
      setCollectionActionType(t);
    }

    const confirmWarningModal = (e) => {
    if (e) {
      onClickDelete();
    } else {
      setWarningModalVisible(false);
    }
  };


    return (
        <div className="px-2 py-6">
            <h1 className="text-left text-white text-3xl font-bold pb-1 flex justify-between">
                Collections
                <Dropdown overlay={
                    <Menu onClick={(e) => onClickCollectionActionType(e.key)}>
                        <Menu.Item key={collectionActionTypes.edit}><span className="pl-3">Edit Collections</span></Menu.Item>
                        <Menu.Item key={collectionActionTypes.delete}><span className="pl-3">Delete Collections</span></Menu.Item>
                        <Menu.Item key={collectionActionTypes.new}><span className="pl-3">Create New</span></Menu.Item>
                    </Menu>
                } trigger={["click"]}>
                    <a className="ant-dropdown-link flex justify-center items-center text-gray-700 font-medium" href="#">
                      <SettingsIcon className="h-8 w-8 text-cool-gray-300" />
                    </a>
                    
                </Dropdown>
                
            </h1>
          
          <Search label="Search..." onSearch={(e) => handleSearch(e)} />

          {loading && (
            <div className="mt-2">
              <Spinner color="cool-gray-200" size={5} />
              <span className="ml-2 text-cool-gray-400">Loading collections...</span>
            </div>
          )} 

          {!loading && error && (
            <h3 className="text-base text-red-400 mt-5 px-1">
                <div>{error}</div>
            </h3>
          )}

          {collectionActionType && collectionActionType === collectionActionTypes.new && (
                  <div>
                    <h3 className="text-base text-cool-gray-400 mt-5 px-1 flex justify-between items-center">
                      <div>{!formLoading ? 'Create New Collection': 'Loading..'}</div>
                    </h3>
                    <div className="relative w-full text-gray-400 focus-within:text-gray-600 mt-3">
                        <input 
                            onChange={(e) => setNewCollection(e.target.value)}
                            autocomplete="off" 
                            className={classNames(
                                "block w-full text-gray-900 h-full px-2 py-3 rounded-lg border-cool-gray-400 placeholder-gray-500 shadow-outline-gray outline-none focus:outline-none focus:shadow-outline-gray focus:placeholder-gray-400 focus:ring-0 focus:border sm:text-sm bg-white"
                            )}
                            placeholder="Collection name" 
                            type="input" 
                            name="createNew" />
                    </div>
                    <div className="text-base w-full text-cool-gray-400 mt-5 px-1 flex justify-end items-center">
                        <Button 
                          type="button" 
                          className={classNames(
                              "flex w-1/2 rounded-lg bg-cool-gray-700 p-2 mr-1 justify-center items-center hover:shadow-outline-blue hover:bg-cool-gray-700"
                          )} 
                          onClick={(e) => onClickNewCollection()}
                          disabled={formLoading}
                        >
                          Create    
                        </Button>
                        <Button 
                          type="button" 
                          className={classNames(
                              "flex w-1/2 rounded-lg bg-cool-gray-700 p-2 justify-center items-center hover:shadow-outline-blue hover:bg-cool-gray-700"
                          )} 
                          onClick={(e) => onClickCancel()}
                          disabled={formLoading}
                          >
                          Cancel   
                        </Button>
                      </div>
                   </div> 
          )}

          {(!loading && collections && collections.length > 0) && (
            <div>
                {!collectionActionType && (
                  <h3 className="text-base text-cool-gray-400 mt-5 px-1">
                    {searchQuery && (
                        <div>Search Results ({collections.length})</div>
                    )}
                    {!searchQuery && (
                      <div>Recently edited collections</div>
                    )}
                  </h3>
                )}

                {collectionActionType && collectionActionType === collectionActionTypes.edit && (
                  <h3 className="text-base text-cool-gray-400 mt-5 px-1 flex justify-between items-center">
                      <div>{!formLoading ? 'Edit Collections': 'Loading..'}</div>
                      <div>
                        <Button 
                          type="button" 
                          className={classNames(
                              "flex rounded-lg bg-cool-gray-700 p-2 mr-1 items-center hover:shadow-outline-blue hover:bg-cool-gray-700"
                          )} 
                          onClick={(e) => onClickSave()}
                          disabled={formLoading || (Object.keys(editedCollections).length === 0)}
                          >
                          Save    
                        </Button>
                        <Button 
                          type="button" 
                          className={classNames(
                              "flex rounded-lg bg-cool-gray-700 p-2 items-center hover:shadow-outline-blue hover:bg-cool-gray-700"
                          )} 
                          onClick={(e) => onClickCancel()}
                          disabled={formLoading}
                          >
                          Cancel   
                        </Button>
                      </div>
                  </h3>
                )}

                {collectionActionType && collectionActionType === collectionActionTypes.delete && (
                    <h3 className="text-sm text-cool-gray-400 mt-5 px-1 flex justify-between items-center">
                      <div>{!formLoading ? 'Delete Collections': 'Loading..'}</div>
                      <div>
                        <Button 
                          type="button" 
                          className={classNames(
                              "flex rounded-lg bg-cool-gray-700 p-2 mr-1 items-center hover:shadow-outline-blue hover:bg-cool-gray-700"
                          )} 
                          onClick={(e) => setWarningModalVisible(true)}
                          disabled={formLoading || (Object.keys(editedCollections).length === 0)}
                          >
                          Delete    
                        </Button>
                        <Button 
                          type="button" 
                          className={classNames(
                              "flex rounded-lg bg-cool-gray-700 p-2 items-center hover:shadow-outline-blue hover:bg-cool-gray-700"
                          )} 
                          onClick={(e) => onClickCancel()}
                          disabled={formLoading}
                          >
                          Cancel   
                        </Button>
                      </div>
                  </h3>
                )}

                {collectionActionType !== collectionActionTypes.new && collections.map((c) => {
                    switch (collectionActionType) {
                        case collectionActionTypes.edit:
                            return (
                                <div className="relative w-full text-gray-400 focus-within:text-gray-600 mt-3">
                                    <input 
                                        key={`edit-${c.id}`}
                                        defaultValue={c.name}
					                              onChange={(e) => onEditCollection(c, e.target.value)}
                                        autocomplete="off" 
                                        className={classNames(
                                          "block w-full h-full px-2 py-3 rounded-lg border-cool-gray-400 placeholder-gray-500 shadow-outline-gray outline-none focus:outline-none focus:shadow-outline-gray focus:placeholder-gray-400 focus:ring-0 focus:border sm:text-sm bg-white",
                                          (editedCollections[c.id]) ? "text-red-500" : "text-gray-900"
                                        )}
                                        placeholder="Collection name" 
                                        type="input" 
                                        name="edit" />
                                </div>
                            );
                        case collectionActionTypes.delete:
                            return (
                                <div className="relative w-full text-gray-400 focus-within:text-gray-600 mt-3">
                                     <label class="inline-flex w-full items-center rounded-lg bg-cool-gray-700 p-2 text-cool-gray-300">
                                        <input 
                                          type="checkbox"
                                          checked={editedCollections[c.id]}
                                          onClick={(e) => e.stopPropagation()}
                                          onChange={(e) => onChecked(c, e)}
                                          className="focus:ring-indigo-500 h-4 w-4 mx-1 text-indigo-600 border-gray-300 rounded indeterminate:ring-500 text-indigo-600"/> 
                                          <div className="flex justify-between w-full">
                                              <span className="text-base">{c.name}</span>  
                                              <div className="">
                                                <span className="inline-flex items-center rounded-full font-medium bg-cool-gray-500 text-cool-gray-300 px-3 py-0.5 leading-5">
                                                  {c.items ? c.items.length : 0}
                                                </span>
                                              </div>   
                                          </div>   
                                    </label>    
                                </div>
                            );    
                        default:
                           return ( 
                                <Button 
                                    key={c.id} 
                                    type="button" 
                                    className={classNames(
                                        "flex w-full rounded-lg bg-cool-gray-700 p-2 mt-3 items-center hover:shadow-outline-blue hover:bg-cool-gray-700",
                                        (selectedCollection && selectedCollection.id === c.id) && "shadow-outline-blue"
                                    )} 
                                    onClick={(e) => onClickCollection(c)}>
                                    <div className="flex-grow w-3/4 truncate text-left text-base text-cool-gray-300">{c.name}</div>
                                    <div className="w-1/4 text-right">
                                        <span className="inline-flex items-center rounded-full font-medium bg-cool-gray-500 text-cool-gray-300 px-3 py-0.5 leading-5">
                                            {c.items ? c.items.length : 0}
                                        </span>
                                    </div>
                                </Button>
                            );
                    }  
                })}
            </div>
            
          )}
          {(!loading && !error && (!collections || collections.length == 0)) && (
            <div className="flex flex-col py-8">
              <div className="flex justify-center">
                <SearchIcon className="h-13 w-13 text-cool-gray-400" />
              </div>  
              <div className="flex justify-center">
                <span className="ml-2 text-lg text-cool-gray-400">No collections found</span>
              </div>  
              {(!loading && searchQuery) && (
                <div className="flex justify-center">
                  <span className="ml-2 text-sm py-2 text-cool-gray-400">Try changing the search term</span>
                </div>  
              )}
              
            </div>
          )}

          <Modal
            hideFooter={false}
            title="Alert!"
            visible={warningModalVisible}
            confirmButtonText="Yes"
            closeButtonText="No"
            loading={formLoading}
            onCancel={() => confirmWarningModal(false)}
            onConfirm={() => confirmWarningModal(true)}
          >
            <div>
                <p className="flex py-5 px-6 font-normal text-xl">
                    Are you sure you want to delete the collections?
                </p>
            </div>
          </Modal>
        </div>
    );
});

export default Collections;
