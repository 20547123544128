import io from "socket.io-client";
import {STREAMING_URL} from "../../constants";

function Streamer(id, configuration, audioUrl) {
  let stream = null;
  let playing = false;

  let currentAudioIndex = 0;
  let currentAudioTime = 0;

  let audioEl = document.createElement("audio");

  // Mount (not needed for prod)
  /*
    audioEl.controls = true;
    audioEl.style = "position: absolute; bottom: 0; right: 0";
    document.body.appendChild(audioEl);
  */

  const destroy = () => {
    audioEl.src = null;
    audioEl.remove();
  };

  const updatePlaying = (value) => {
    if (playing === value) return;

    // Store
    playing = value;

    // Event
    const cb = value ? "onPlay" : "onPause";
    if (configuration && configuration[cb]) configuration[cb]();
  };

  const getProgress = () => {
    // Aggregate total numbers using all sections
    let completed = currentAudioTime,
      total = 0;

    stream.data.forEach((section, i) => {
      if (i < currentAudioIndex) {
        completed += section.duration;
      }

      total += section.duration;
    });

    return { completed, total };
  };

  const updateProgress = (value = null) => {
    // Store if a new value is given
    if (value !== null) {
      currentAudioTime = audioEl.currentTime;
    }

    const { completed, total } = getProgress();

    // Event
    if (completed <= total && configuration && configuration.onProgress)
      configuration.onProgress(completed, total);
  };

  // Audio tag listeners
  audioEl.addEventListener("play", () => updatePlaying(true));

  audioEl.addEventListener("ended", () => {
    // More chunks to play?
    if (currentAudioIndex + 1 < stream.data.length) {
      currentAudioIndex++;
      currentAudioTime = 0;

      play();
    } else {
      // Reset
      currentAudioIndex = 0;
      currentAudioTime = 0;

      updatePlaying(false);
      updateProgress();
    }
  });

  audioEl.addEventListener("timeupdate", () =>
    updateProgress(audioEl.currentTime)
  );

  const play = (seconds = null) => {
    if (seconds !== null) {
      currentAudioTime = seconds;
      currentAudioIndex = 0;

      for (let i = 0; i < stream.data.length; i++) {
        if (currentAudioTime > stream.data[i].duration) {
          currentAudioTime -= stream.data[i].duration;
          currentAudioIndex++;
        } else {
          break;
        }
      }
    }

    audioEl.src = stream.data[currentAudioIndex].data;
    audioEl.currentTime = currentAudioTime;
    audioEl.play().catch(() => null);
  };

  const seekTo = (percentageProgress) => {
    const { total } = getProgress();
    const seconds = (total * percentageProgress) / 100;

    play(seconds);
  };

  const pause = () => {
    audioEl.pause();
    updatePlaying(false);
  };

  const init = (data) => {
    if(audioUrl){
      audioEl.src = audioUrl;
      audioEl.addEventListener("loadeddata", () => {
        
        stream = {
          data: [
            {
              source: "url",
              data: audioUrl,
              duration: audioEl.duration
            }
          ]
        };

        if (configuration && configuration.onReady) configuration.onReady();
        updateProgress();
      });
      
    }else{
      stream = data;
      // Pre-load URL audio
      stream.data.forEach((section) => {
        if (section.source === "url") {
          const audio = new Audio();
          audio.src = section.data;
        }
      });
    
      if (configuration && configuration.onReady) configuration.onReady();
      updateProgress(); 
    }
  };

  if(!audioUrl){
    const socket = io(STREAMING_URL);

    socket.on("connect", () => {
      socket.emit("stream", id);

      socket.on("init", init);
      socket.on("update", (data) => {
        // Update
        stream.data.push(data);

        // Progress
        updateProgress();

        // Keep playing
        if (
          playing &&
          audioEl.paused &&
          currentAudioIndex + 1 === stream.data.length
        ) {
          audioEl.play();
        }
      });
    });
  }else{
    init();
  }

  this.play = play;
  this.pause = pause;
  this.seekTo = seekTo;
  this.destroy = destroy;
}

export default Streamer;
