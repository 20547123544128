import React from "react";
import Card, { CardBody } from "../../common/Card";
import VideoIcon from "../../Icons/VideoIcon";
import AudioIcon from "../../Icons/AudioIcon";
import RightArrow from "../../Icons/RightArrow";
import { Link } from "react-router-dom";

const Products = () => {

  return (
    <div className="max-w-7xl mx-auto px-5">
      <Card title="Products" className="mt-5">
        <CardBody>
            <div className="w-full"> 
                <Link to="/video" className="w-full flex pb-4 cursor-pointer hover:text-gray-700">
                  <VideoIcon size="1.5em" className="mt-1"/>
                  <div className="flex-shrink pl-5 w-full">
                    <div className="bold text-lg" >VideoMaker</div>
                    <div>You can create new video projects or track the progress of your existing projects.</div>
                  </div>
                  <div className="flex justify-end items-center" >
                    <RightArrow size="1.5em"/>
                  </div>
                </Link>

                <Link to="/voice" className="w-full flex border-t border-gray-200 pt-3 pb-0 cursor-pointer hover:text-gray-700">
                  <AudioIcon size="1.5em" className="mt-1" />
                  <div className="flex-shrink pl-5 w-full">
                    <div className="bold text-lg" >VoiceMaker</div>
                    <div>You can create new audio projects or track the progress of your existing projects.</div>
                  </div>
                  <div className="flex justify-end items-center" >
                    <RightArrow size="1.5em"/>
                  </div>
                </Link>
            </div>      
        </CardBody>
      </Card>
    </div>
  );
};

export default Products;
