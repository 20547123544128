import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import classNames from "classnames";
import Spinner from "../../common/Spinner";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { message } from "antd";
import { InspectletSendApiError } from "../../common/Inspectlet";
import {
  DEFAULT_COLLECTION_NAME, 
  addToExistingCollections, 
  addToNewCollections
} from "../../common/Collections/bulkActions";

const collectionType = "videoMaker";

const SlideShowCreate = () => {
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    setLoading(true);

    let formData = new FormData();
    formData.append("file", acceptedFiles[0]);

    axios
      .post("slideshows/", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {

        let defaultCollectionId = sessionStorage.getItem("videoMakerDefaultCollectionId");
        if(defaultCollectionId){
          addToExistingCollections({
                  items: [res.data.id],
                  collectionId: defaultCollectionId,
                  type: collectionType
              }).then((r) => setSuccess(res.data))
              .catch((e) => {
                message.error("An error occurred.");
                setSuccess(res.data)
              });
        }else{
          addToNewCollections({
                  items: [res.data.id],
                  name: DEFAULT_COLLECTION_NAME,
                  type: collectionType
              }).then((r) => setSuccess(res.data))
              .catch((e) => {
                message.error("An error occurred.");
                setSuccess(res.data)
              });
        }
      })
      .catch((e) => {
        message.error({
          content: e?.response?.data?.trial || "An error occurred",
          duration: 2,
        });
        setLoading(false);
        InspectletSendApiError(e);
      });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  if (success) {
    return <Redirect to={`/video/${success.id}/edit`} />;
  }

  return (
    <>
      <div className="py-16 bg-white">
        <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-screen-xl lg:px-8">
          <h2 className="text-4xl leading-9 font-extrabold text-gray-900 mb-8">
            How does it work?
          </h2>

          <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <div>
              <div className="flex items-center justify-center h-14 w-14 rounded-md bg-blue-500 text-white text-2xl font-black">
                1
              </div>
              <div className="mt-5">
                <h5 className="text-lg leading-6 font-medium text-gray-900">
                  Upload your presentation
                </h5>
                <p className="mt-2 text-base leading-6 text-gray-500">
                  You don't need special formats, just upload your usual
                  PowerPoint files or PDFs.
                </p>
              </div>
            </div>
            <div className="mt-10 lg:mt-0">
              <div className="flex items-center justify-center h-14 w-14 rounded-md bg-blue-500 text-white text-2xl font-black">
                2
              </div>
              <div className="mt-5">
                <h5 className="text-lg leading-6 font-medium text-gray-900">
                  Enter your texts
                </h5>
                <p className="mt-2 text-base leading-6 text-gray-500">
                  Type in what you want to be spoken by your artifical narrator
                  for each slide.
                </p>
              </div>
            </div>
            <div className="mt-10 lg:mt-0">
              <div className="flex items-center justify-center h-14 w-14 rounded-md bg-blue-500 text-white text-2xl font-black">
                3
              </div>
              <div className="mt-5">
                <h5 className="text-lg leading-6 font-medium text-gray-900">
                  You're done
                </h5>
                <p className="mt-2 text-base leading-6 text-gray-500">
                  It is literally this easy. Just wait for our text-to-speech
                  engine to do its magic.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-12 container mx-auto px-8 pb-12">
        <div
          className={classNames(
            "border-dashed border-4 rounded-lg bg-white p-12 text-gray-500",
            isDragActive && "border-gray-400",
            !loading && "cursor-pointer"
          )}
          {...getRootProps()}
        >
          <input disabled={loading} {...getInputProps()} />

          {loading ? (
            <div className="text-xl text-center">
              <Spinner size={6} />{" "}
              <span className="ml-2 text-black">
                Please wait while we are creating your project...
              </span>
            </div>
          ) : (
            <div className="text-center">
              <svg
                className="w-12 h-12 mx-auto"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                ></path>
              </svg>
              <p className="text-xl">
                Drop some files here, or click to select files
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SlideShowCreate;
