import axios from "axios";
import React, { useEffect, useState } from "react";
import Player from "./Player";

function makeId(length) {
  var result = [];
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result.push(
      characters.charAt(Math.floor(Math.random() * charactersLength))
    );
  }
  return result.join("");
}

const Streaming = () => {
  const [id, setId] = useState(makeId(9));
  const [text, setText] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const submit = () => {
    // For the case only id is given
    if (text.length > 0) {
      setSubmitted(false);

      axios
        .post(
          "https://api.deepzen.io/v1/sessions/",
          {
            sessions: [{ text, voice: "alice", quality: "standard" }],
            webhook_url: "https://enur9nblejzq.x.pipedream.net",
          },
          {
            headers: {
              Authorization: "ApiToken 3031bc3d-e366-41e3-9cfa-960bd07e4e21",
            },
          }
        )
        .then((res) => {
          if (res.data.status !== "fail") {
            setId(res.data.id);
            setTimeout(() => setSubmitted(true), 750);
          } else {
            alert("Job failed.");
          }
        });
    }
  };
  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center bg-blue-200">
      <div
        className={"bg-white rounded-lg shadow-md p-2 mb-2"}
        style={{ width: 400 }}
      >
        <input
          className="form-input w-full mb-2"
          value={id}
          onChange={(e) => setId(e.target.value)}
        />
        <textarea
          className="form-textarea w-full h-48"
          autoFocus
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        <button
          onClick={submit}
          className="w-full bg-green-600 mt-2 rounded-md py-2 text-white font-medium"
        >
          Synthesize
        </button>
      </div>

      {submitted && <Player id={id} />}
    </div>
  );
};

export default Streaming;
