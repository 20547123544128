import axios from "axios";
import { useEffect, useState } from "react";

export const useVoices = () => {
  const [voices, setVoices] = useState(null);
  
  useEffect(() => {
    (async () => setVoices(await getVoices()))();
  }, []);

  return voices;
};

export const getVoices = () => axios.get(`/voices/`).then((res) => res.data);    

export function getCustomEmotionsById(voices, voiceId) {
    if(voices && voices.results){
      const voice = voices.results.filter((voice) => voice.tts_voice_id == voiceId)[0];
      const sortedEmotions = voice.emotions.sort((a, b) => (a.title < b.title ? -1 : 1));
      return [...sortedEmotions, ];
    }
    return null;
}




