import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/logo-icon.png";
import classNames from "classnames";
import { useHistory, useLocation } from "react-router-dom";
import { AUTH_URL } from "../../constants";

const Header = ({ hideMenu, user }) => {
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const history = useHistory();
  const menuRef = useRef();
  const location = useLocation();

  useEffect(() => {
    const handleClickOutsideMenu = (e) => {
      if (menuRef && menuRef.current && !menuRef.current.contains(e.target)) {
        setProfileMenuOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutsideMenu);
    return () => document.removeEventListener("click", handleClickOutsideMenu);
  }, []);

  useEffect(() => {
    setProfileMenuOpen(false);
  }, [history.location.pathname]);

  if (!user) return null;
  
  return (
    <div>
    <nav className="bg-white shadow">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="-ml-2 mr-2 flex items-center md:hidden">
              <button
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                aria-label="Main menu"
                aria-expanded="false"
              >
                <svg
                  className="block h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>

                <svg
                  className="hidden h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="flex-shrink-0 flex items-center">
              <NavLink
                to="/dashboard"
                exact
                activeClassName="border-blue-500"
                className="inline-flex items-center px-1 pt-1 border-b-2 border-transparent font-medium leading-5 text-gray-900 focus:outline-none focus:border-blue-700 transition duration-150 ease-in-out mr-8"
              >
                <img className="h-8 w-auto" src={logo} alt="DeepZen logo" />
              </NavLink>
            </div>
            {!hideMenu && (
              <div className="hidden md:ml-6 md:flex">
                <NavLink
                  to="/video"
                  exact
                  activeClassName="border-blue-500"
                  className="inline-flex items-center px-1 pt-1 border-b-2 border-transparent font-medium leading-5 text-gray-900 focus:outline-none focus:border-blue-700 transition duration-150 ease-in-out mr-8"
                >
                  VideoMaker
                </NavLink>
                <NavLink
                  to="/voice"
                  exact
                  activeClassName="border-blue-500"
                  className="inline-flex items-center px-1 pt-1 border-b-2 border-transparent font-medium leading-5 text-gray-900 focus:outline-none focus:border-blue-700 transition duration-150 ease-in-out mr-8"
                >
                  VoiceMaker
                </NavLink>
                <NavLink
                  to="/voice-library"
                  exact
                  activeClassName="border-blue-500"
                  target="_blank"
                  className="inline-flex items-center px-1 pt-1 border-b-2 border-transparent font-medium leading-5 text-gray-900 focus:outline-none focus:border-blue-700 transition duration-150 ease-in-out mr-8"
                >
                  Voice Library
                </NavLink>

                <NavLink
                  to="/members"
                  exact
                  activeClassName="border-blue-500"
                  className="inline-flex items-center px-1 pt-1 border-b-2 border-transparent font-medium leading-5 text-gray-900 focus:outline-none focus:border-blue-700 transition duration-150 ease-in-out mr-8"
                >
                  Members
                </NavLink>

                <NavLink
                  to="/developers"
                  exact
                  activeClassName="border-blue-500"
                  className="inline-flex items-center px-1 pt-1 border-b-2 border-transparent font-medium leading-5 text-gray-900 focus:outline-none focus:border-blue-700 transition duration-150 ease-in-out mr-8"
                >
                  Developers
                </NavLink>
              </div>
            )}
          </div>
          <div className="flex items-center">
            <div className="hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center">
              <div className="ml-3 relative" ref={menuRef}>
                <div>
                  <button
                    className="flex items-center text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition duration-150 ease-in-out"
                    id="user-menu"
                    aria-label="User menu"
                    aria-haspopup="true"
                    onClick={() => setProfileMenuOpen(!profileMenuOpen)}
                  >
                    <span className="pr-4">
                      {user?.first_name} {user?.last_name}
                    </span>
                    <span className="h-9 w-9 rounded-full bg-gray-400 text-white flex justify-center items-center">
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                        ></path>
                      </svg>
                    </span>
                  </button>
                </div>
                <div
                  className={classNames(
                    "origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg z-10",
                    profileMenuOpen ? "block" : "hidden"
                  )}
                >
                  <div
                    className="py-1 rounded-md bg-white shadow-xs"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu"
                  >
                    <Link
                      to="/billing"
                      className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                      role="menuitem"
                    >
                      Billing
                    </Link>
                    <a
                      target="_blank"
                      href={AUTH_URL}
                      className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                      role="menuitem"
                    >
                      Account Settings
                    </a>
                    <NavLink
                      to="/auth/logout"
                      className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                      role="menuitem"
                    >
                      Sign out
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div className={classNames(
      "mx-auto",
      (location.pathname !== '/pricing' && location.pathname !== '/dashboard' && location.pathname !== '/') 
      ? "max-w-7xl px-4 sm:px-6 lg:px-8" : ""
    )}>
    </div>
    </div>
  );
};

export default Header;
