import React, { useState, useEffect } from "react";
import Hero from "../../common/Hero";
import Products from "./Products";
import AccountInfo from "./AccountInfo";
import { getUser } from "../../common/auth";
import { SubscriptionInfo } from "../../common/SubscriptionInfo";

const completeSubTitle =
  "You can create new audio projects or track the progress of your existing projects.";

const Dashboard = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    (async () => setUser(await getUser()))();
  }, []);

  if (!user) return null;

  return (
    <div>
      <div className="w-auto mx-auto px-4 sm:px-6 lg:px-8 bg-cool-gray-800 pb-5">
        <Hero title="Welcome to DeepZen App" description={completeSubTitle} />
      </div>
      <div className="max-w-7xl mx-auto px-5 mt-5">
        <SubscriptionInfo user={user} />
      </div>
      <Products />
      <AccountInfo user={user} />
    </div>
  );
};

export default Dashboard;
