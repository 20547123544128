import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";
import { API_URL, SENTRY_DSN, ENVIRONMENT, SENTRY_SAMPLING_RATE } from "./constants";
import { setupAuth } from "./common/auth";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

axios.defaults.baseURL = API_URL;

if (typeof SENTRY_DSN != "undefined") {
	Sentry.init({
		dsn: SENTRY_DSN,
		integrations: [new BrowserTracing()],
		tracesSampleRate: SENTRY_SAMPLING_RATE,
		environment: ENVIRONMENT
	});
} 

setupAuth();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
