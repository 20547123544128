import React from "react";
import classNames from "classnames";

export const CardBody = ({ children, padding }) => (
  <div className={classNames("border-t border-gray-200", padding ? padding : "p-4")}>{children}</div>
);

const headerSizeMap = {
	large: "py-5 text-lg",
	small: "py-1 text-base",
	default: "py-5 text-lg",
};

const typeMap = {
  info: "bg-red-200",
  default: "bg-white"
};

const headerColorMap = {
	default: "bg-white text-gray-900",
	blue: "bg-blue-100 text-blue-900",
	green: "bg-green-100 text-green-900",
	red: "bg-red-100 text-red-900",
	yellow: "bg-yellow-100 text-yellow-900",
	indigo: "bg-indigo-100 text-indigo-900",
	gray: "bg-gray-100 text-gray-900",
  white: "bg-white text-gray-900",
};

const Card = ({ title, headerActions, headerSize, headerColor, flatContainer, className, children, type }) => (
  <div
    className={classNames(
      typeMap[type || "default"],
      (!className || className.indexOf("overflow-") === -1) &&
        "overflow-hidden",
      (flatContainer ? "rounded-none" : "rounded-lg shadow"),  
      className
    )}
  >
    {title && (
      <h3 className={classNames(
          "px-4 sm:px-6 leading-6 font-medium",
          headerSizeMap[headerSize || "default"],
          headerColorMap[headerColor || "default"],
      )}>
        {title}
        {headerActions && <div className="float-right">{headerActions}</div>}
      </h3>
    )}
    {children}
  </div>
);

export default Card;
