import React, { useEffect, useState } from "react";
import { authenticate } from "../../common/auth";
import { useHistory } from "react-router-dom";

const Finish = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const history = useHistory();

  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");

  useEffect(() => {
    authenticate(code)
      .then(() => {
        setLoading(false);
        history.replace({ pathname: "/dashboard" });
      })
      .catch((e) => {
        setLoading(false);
        setError(e);
      });
  }, []);

  return <div>Logging in...</div>;
};

export default Finish;
