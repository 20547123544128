import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { Icon, Tooltip, Drawer } from "antd";
import TaggedButtons from "./TaggedButtons";
import WordTagList from "./WordTagList";
import Modal from "../Modal";
import CloseIcon from "../../Icons/CloseIcon";
import Paragraph from "../../screens/TextSettings/Paragraph";
import TextSettings from "../../screens/TextSettings";
import { hasUserFullAccess } from "../../common/auth";
import { InspectletSendApiError } from "../../common/Inspectlet";
import { message } from "antd";

import "./drawer.scss";
import axios from "axios";
const key = "updatable";

const TextEditor = ({
  user,
  onEdit,
  viewMode,
  paragraphsData,
  lineHeight,
  text,
  isDrawerLarge,
  onDataChanged,
  onModeChanged,
  resetEvents,
  refreshParagraphs,
  customEmotions,
}) => {
  const [textLength, setTextLength] = useState(0);
  const [textData, setTextData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [enrichmentActive, setEnrichmentActive] = useState(false);
  const [warningModalVisible, setWarningModalVisible] = useState(false);
  const [selectedSentenceId, setSelectedSentenceId] = useState(null);
  const [selectedParagraphId, setSelectedParagraphId] = useState(null);
  const [paragraphs, setParagraphs] = useState(null);
  const [editedParagraphs, setEditedParagraphs] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(true);

  useEffect(() => {
    if (text) {
      setTextLength(text.length);
    } else {
      setParagraphs(null);
      setEditedParagraphs(null);
      setEnrichmentActive(false);
      setTextLength(0);
    }
    setTextData(text);
  }, [text]);

  useEffect(() => {
    if (paragraphsData) {
      setParagraphs([...paragraphsData]);
      setEditedParagraphs([...paragraphsData]);
      setEnrichmentActive(true);
      if (resetEvents) {
        setSelectedSentenceId(null);
        setSelectedParagraphId(null);
        if (onModeChanged) {
          onModeChanged(false);
        }
      }
    } else {
      if (resetEvents) {
        setSelectedSentenceId(null);
        setSelectedParagraphId(null);
        if (onModeChanged) {
          onModeChanged(false);
        }
      }
      setParagraphs(null);
      setEditedParagraphs(null);
      setEnrichmentActive(false);
    }
  }, [paragraphsData]);

  const onTextChange = (e) => {
    setTextLength(e.target.value.length);
    setTextData(e.target.value);
    onDataChanged({ text: e.target.value, synthesis_json: null });
  };

  const onClickEnrichText = () => {
    if (textLength > 0) {
      setWarningModalVisible(true);
    }
  };

  const confirmWarningModal = (e) => {
    if (e) {
      if (enrichmentActive) {
        setWarningModalVisible(false);
        setEnrichmentActive(false);
        setParagraphs(null);
        setEditedParagraphs(null);
        onDataChanged({ text: textData, synthesis_json: null });

        if (onModeChanged) {
          onModeChanged(false);
        }
      } else {
        setLoading(true);
        axios
          .post(`voicemaker/text-analysis/`, { text: textData })
          .then((res) => {
            setLoading(false);
            setParagraphs([...res.data]);
            setEditedParagraphs([...res.data]);
            onDataChanged({ text: textData, synthesis_json: res.data });
            setWarningModalVisible(false);
            setEnrichmentActive(true);
          })
          .catch((e) => {
            console.error(e);
            setLoading(false);
            setWarningModalVisible(false);
            const errorMessage = e?.response?.data;
            message.error({
              content: (errorMessage && (errorMessage.trial || errorMessage.detail)) || "An error occurred",
              duration: 2,
              key,
            });
            InspectletSendApiError(e);
          });
      }
    } else {
      setWarningModalVisible(false);
    }
  };

  const onClickSentence = (pId, sId) => {
    setSelectedSentenceId(sId);
    setSelectedParagraphId(pId);
    if (onModeChanged) {
      onModeChanged(true);
    }
    let _paragraphsData = [
      ...editedParagraphs.map((p, i) =>
        i === pId
          ? p.map((s, j) =>
              j === sId ? { ...s, active: true } : { ...s, active: false }
            )
          : p.map((s, j) => ({ ...s, active: false }))
      ),
    ];

    setEditedParagraphs(_paragraphsData);
    onDataChanged({ text: textData, synthesis_json: _paragraphsData });
    setDrawerVisible(true);
  };

  const saveTextSettings = (e) => {
    let _selectedSentence = paragraphs[selectedParagraphId][selectedSentenceId];
    let _paragraphsData = [
      ...editedParagraphs.map((p, i) =>
        i === selectedParagraphId
          ? p.map((s, j) => (j === selectedSentenceId ? { ...s, ...e } : s))
          : p.map((s, j) => s)
      ),
    ];

    setEditedParagraphs(_paragraphsData);
    onDataChanged({ text: textData, synthesis_json: _paragraphsData });
    setDrawerVisible(false);
    if (onModeChanged) {
      onModeChanged(false);
    }
  };

  const onCloseDrawer = () => {
    setDrawerVisible(false);
    if (onModeChanged) {
      onModeChanged(false);
    }
  };

  const onRefreshParagraphs = (e) => {
    let _paragraphsData = [
      ...paragraphs.map((p, i) =>
        i === selectedParagraphId
          ? p.map((s, j) =>
              j === selectedSentenceId
                ? { ...s, text: e.text, words: e.words }
                : s
            )
          : p.map((s, j) => s)
      ),
    ];
    setParagraphs(_paragraphsData);

    let _editedParagraphsData = [
      ...editedParagraphs.map((p, i) =>
        i === selectedParagraphId
          ? p.map((s, j) =>
              j === selectedSentenceId
                ? { ...s, text: e.text, words: e.words }
                : s
            )
          : p.map((s, j) => s)
      ),
    ];

    let _editedText = "";
    _editedParagraphsData.map((p, i) => {
      p.map((s, j) => {
        _editedText += s.text + " ";
      });
      _editedText += "\n";
    });
    setTextData(_editedText);
    setEditedParagraphs(_editedParagraphsData);
    if (refreshParagraphs) {
      refreshParagraphs({
        sentenceId: selectedSentenceId,
        paragraphId: selectedParagraphId,
        sentence: e,
        paragraphsText: _editedText,
      });
    }
  };

  const isButtonsActive = hasUserFullAccess(user);

  return (
    <div
      className={classnames(
        "border rounded-xl",
        enrichmentActive && "border border-blue-300"
      )}
    >
      <div className="flex justify-between p-3 border-b">
        <span className="block text-sm font-medium text-gray-700">
          Text {!viewMode && <span>(Max 15000 characters)</span>}
        </span>

        {viewMode && (
          <div className="w-full flex justify-end">
            <WordTagList className="px-5" /> 
            <button
              onClick={(e) => onEdit()}
              className="inline-flex items-center px-6 py-0 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Edit
            </button>
          </div>
        )}

        <div className="flex text-sm leading-5 text-gray-500">
          {enrichmentActive && !viewMode && (
            <div className="flex items-center">
              <WordTagList className="pr-5" /> 
              <button
                type="button"
                onClick={(e) => setWarningModalVisible(true)}
              >
                <CloseIcon size="1em" />
              </button>
            </div>
          )}
          {!enrichmentActive && !viewMode && (
            <Tooltip title={textLength === 0 ? "Please type something" : ""}>
              <button
                type="button"
                disabled={!isButtonsActive}
                className={classnames(
                  "relative inline-flex items-center border-none text-sm leading-5 font-medium text-gray-500 focus:outline-none transition ease-in-out duration-150",
                  !isButtonsActive && "cursor-not-allowed opacity-50 focus"
                )}
                onClick={(e) => onClickEnrichText()}
              >
                <div className="flex items-center justify-center">
                  <Icon type="edit" style={{ paddingRight: 5 }} />
                  <span>Edit</span>
                </div>
              </button>
            </Tooltip>
          )}
        </div>
      </div>
      {(enrichmentActive || viewMode) && (
        <div
          className={classnames(
            "overflow-y-auto site-drawer-render-in-current-wrapper break-all z-30 bg-gray-100",
            isDrawerLarge && "drawer-w-lg"
          )}
        >
          {paragraphs &&
            paragraphs.map((paragraph, index) => {
              return (
                <Paragraph
                  key={index}
                  paragraphId={index}
                  paragraph={paragraph}
                  editedParagraph={editedParagraphs[index]}
                  disabled={viewMode}
                  onClickSentence={(e) => onClickSentence(index, e)}
                />
              );
            })}

          {!paragraphs && (
            <div className="">
              <textarea
                name="text"
                value={textData}
                maxLength="15000"
                disabled={true}
                placeholder="Type something..."
                className="form-textarea focus:outline-none focus:shadow-none bg-gray-100 p-0 rounded-none border-none block w-full text-sm"
                rows={lineHeight ? lineHeight : 30}
              />
            </div>
          )}

          {selectedSentenceId !== null && drawerVisible && (
            <Drawer
              title="Editing Settings"
              placement="right"
              closable={true}
              onClose={(e) => onCloseDrawer()}
              visible={drawerVisible}
              maskClosable={false}
              key="top"
              getContainer={false}
              style={{ position: "absolute", userSelect: "none" }}
            >
              <TextSettings
                customEmotions={customEmotions}
                sentence={paragraphs[selectedParagraphId][selectedSentenceId]}
                user={user}
                editedSentence={
                  editedParagraphs[selectedParagraphId][selectedSentenceId]
                }
                saveTextSettings={(e) => saveTextSettings(e)}
                refreshParagraphs={(e) => onRefreshParagraphs(e)}
              />
            </Drawer>
          )}
        </div>
      )}
      {!enrichmentActive && !viewMode && (
        <div className="">
          <textarea
            name="text"
            maxLength="15000"
            value={textData ? textData : ""}
            onChange={onTextChange}
            placeholder="Type something..."
            className="form-textarea focus:outline-none focus:shadow-none rounded-none border-none block w-full text-sm"
            rows={lineHeight ? lineHeight : 10}
          />
        </div>
      )}

      <div
        className={classnames(
          "flex px-5 py-2 border-t",
          !viewMode && "justify-end"
        )}
      >
        <div
          className={classnames(
            "flex w-full",
            viewMode ? "justify-between" : "justify-end"
          )}
        >
          <span className="text-gray-500 font-medium mb-0.5 block text-xs">
            Characters: {textLength}
          </span>
          {viewMode && (
            <button
              onClick={(e) => onEdit()}
              className="inline-flex items-center px-6 py-0 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Edit
            </button>
          )}
        </div>
      </div>

      <Modal
        hideFooter={false}
        title="Alert!"
        visible={warningModalVisible}
        confirmButtonText="Yes"
        closeButtonText="No"
        loading={loading}
        onCancel={() => confirmWarningModal(false)}
        onConfirm={() => confirmWarningModal(true)}
      >
        {enrichmentActive ? (
          <div>
            <p className="flex py-5 px-6 font-normal text-xl">
              Are you sure you want to exit the edit mode?
            </p>
          </div>
        ) : (
          <div>
            <p className="flex py-5 px-6 font-normal text-xl">
              Are you ready to move to edit mode?
            </p>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default TextEditor;
