import React from "react";
import { Tooltip } from "antd";
import classnames from "classnames";
import Word from "./Word";
import { getPauseTag, getEmotionTag, getIntensityTag} from "./wordTags";

export function Sentence({
	sentence,
	disabled,
	editedSentence,
	sentenceId,
	paragraphId,
	onClickSentence,
    isActive
}) {

const sentenceChangesTags = {values: ""};

if(editedSentence.pause){
	sentenceChangesTags.values = 'Pause: ' + getPauseTag(editedSentence.pause);
}
if(editedSentence.emotion){
	if(sentenceChangesTags.values){
		sentenceChangesTags.values += ", Emotion: " + getEmotionTag(editedSentence.emotion); 
	}else{
		sentenceChangesTags.values += "Emotion: " + getEmotionTag(editedSentence.emotion); 
	}
}
if(editedSentence.emotion_intensity){
	if(sentenceChangesTags.values){
		sentenceChangesTags.values += ", Intensity: " + getIntensityTag(editedSentence.emotion_intensity); 
	}else{
		sentenceChangesTags.values += "Intensity: " + getIntensityTag(editedSentence.emotion_intensity); 
	}
}
if(editedSentence.text !== sentence.text){
	if(sentenceChangesTags.values){
		sentenceChangesTags.values += ", Text"; 
	}else{
		sentenceChangesTags.values += "Text"; 
	}
}

const tooltipTitle = (
       <div className="">
	   		<div className="">
			   <span>paragraph: #{paragraphId + 1} - sentence: #{sentenceId + 1}</span>
			</div>
			{sentenceChangesTags.values && (
				<div className="">
					<span>Changes: {" "}
						<span>{sentenceChangesTags.values}</span>
					</span>
				</div>
			)}
		</div>
);

    return (
		<Tooltip title={tooltipTitle}>
			{!disabled && (
				<a
					id={`sentence-${sentenceId}`}
					className={classnames(
						"mr-1 transition-none hover:bg-gray-200 hover:text-gray-900",
						editedSentence.text !== sentence.text ? "bg-red-200" : sentenceChangesTags.values ? "bg-blue-200" : isActive ? "bg-gray-200" : ""
					)}
					onClick={(event) => onClickSentence(sentenceId)}
				>

				{editedSentence.text === sentence.text ?
					editedSentence.words && (
                		editedSentence.words.map((word, index) => {
                        	return <Word 
                                key={index} 
                                wordId={index} 
                                clickable={false}
								editedWord={word}
                                word={sentence.words[index]}
								sentence={sentence}
								showDefaultData={true}
								editedSentence={editedSentence}
							/>
                    	})
                	) : (
						<span className="inline-block">
							{editedSentence.text}
						</span>
					)
				}
					
				</a>
			)}
			{disabled && (
				<span
					id={`sentence-${sentenceId}`}
					className={classnames(
						"mr-1 transition-none hover:bg-gray-200 hover:text-gray-900",
						editedSentence.text !== sentence.text ? "bg-red-200" : sentenceChangesTags.values ? "bg-blue-200" : isActive ? "bg-gray-200" : ""
					)}
				>
					{editedSentence.text === sentence.text ?
						editedSentence.words && (
                			editedSentence.words.map((word, index) => {
                        		return <Word 
                                	key={index} 
                                	wordId={index} 
                                	clickable={false}
									editedWord={word}
                                	word={sentence.words[index]}
									sentence={sentence}
									showDefaultData={true}
									editedSentence={editedSentence}
								/>
                    		})
                		) : (
							<span className="inline-block">
								{editedSentence.text}
							</span>
						)
				}
				</span>
			)}
			
		</Tooltip>
	);
}

export default Sentence;
