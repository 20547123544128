import React, {useState, useEffect}  from "react";
import { useDebounce } from "use-debounce";
import SearchIcon from "../Icons/SearchIcon";
import classNames from "classnames";

const typeMap = {
    primary: "block w-full h-full pl-8 pr-3 py-2 border border-gray-300 text-gray-900 placeholder-gray-500 outline-none focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border sm:text-sm bg-white",
    default: "block w-full h-full pl-8 pr-3 py-2 border-cool-gray-400 rounded-lg text-gray-900 placeholder-gray-500 shadow-outline-gray outline-none focus:outline-none focus:shadow-outline-gray focus:placeholder-gray-400 focus:ring-0 focus:border sm:text-sm bg-white"
}

const Search = ({label, className, type, onSearch }) => {
    
    const [searchQuery, setSearchQuery] = useState("");
	const [debouncedSearchValue] = useDebounce(searchQuery, 500);

    useEffect(() => {
        onSearch(debouncedSearchValue);
    }, [debouncedSearchValue]);

    return (
        <div className="flex-1 flex w-full bg-gray-100 rounded-lg">
            <label htmlFor="search-field" className="sr-only">{label}</label>
            <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none px-2">
                    <SearchIcon className="h-5 w-5" />
                </div>
                <input 
                    id="search-field" 
                    value={searchQuery}
					onChange={(e) => setSearchQuery(e.target.value)}
                    autoComplete="off" 
                    className={classNames(
                        typeMap[type || "default"]
                    )} 
                    placeholder={label} 
                    type="input" 
                    name="search" />
            </div>
        </div>
    );
};

export default Search;
