import React from "react";
import Card, { CardBody } from "../../common/Card";
import AccountBookIcon from "../../Icons/AccountBookIcon";
import RightArrow from "../../Icons/RightArrow";
import { Link } from "react-router-dom";
import { packageTitles } from "../Billing";

const AccountInfo = ({ user }) => {
  if (!user) return null;

  return (
    <div className="max-w-7xl mx-auto px-5 pb-10">
      <Card title="Account Info" className="mt-5">
        <CardBody>
          <div className="w-full">
            <Link
              to="/billing"
              className="w-full flex cursor-pointer hover:text-gray-700"
            >
              <AccountBookIcon size="1.5em" className="mt-1" />
              <div className="flex-shrink pl-5 w-full">
                <div className="bold text-lg">Subscription</div>
                <div className="capitalize">
                  {user.business.subscription?.plan &&
                    packageTitles[user.business.subscription.plan]}
                </div>
              </div>
              <div className="flex justify-end items-center">
                <RightArrow size="1.5em" />
              </div>
            </Link>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default AccountInfo;
