import React from "react";
import classnames from "classnames";

const containerColorMap = {
  warning: "bg-yellow-50 border-yellow-400",
  success: "bg-green-50 border-green-400",
  error: "bg-red-50 border-red-400",
  default: "bg-yellow-50 border-yellow-400",
};

const iconColorMap = {
  warning: "text-yellow-400",
  success: "text-green-400",
  error: "text-red-400",
  default: "text-yellow-400",
};

const linkColorMap = {
  warning: "text-yellow-700 hover:text-yellow-600",
  success: "text-green-700 hover:text-green-600",
  error: "text-red-700 hover:text-red-600",
  default: "text-yellow-700 hover:text-yellow-600",
};

const Alert = ({ type, children, link, className }) => (
  <div
    className={classnames(
      "border-l-4  p-4",
      containerColorMap[type || "default"],
      className
    )}
  >
    <div className="flex">
      <div className="flex-shrink-0">
        <svg
          className={classnames("h-5 w-5", iconColorMap[type || "default"])}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          ariaHidden="true"
        >
          <path
            fillRule="evenodd"
            d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      <div className="ml-3">
        <p className={classnames("text-sm")}>
          <a
            href={link}
            className={classnames(
              "font-medium",
              linkColorMap[type || "default"]
            )}
          >
            {children}
          </a>
        </p>
      </div>
    </div>
  </div>
);

export default Alert;
