import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Members from "../../screens/Members";
import MemberCreate from "../../screens/MemberCreate";

const MembersIndex = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact={true} path={`${match.url}/create`}>
        <MemberCreate />
      </Route>
      <Route exact={true} path={match.path}>
        <Members />
      </Route>
    </Switch>
  );
};

export default MembersIndex;
