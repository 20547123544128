import axios from "axios";
import { useEffect, useState } from "react";
import moment from "moment";

let token = localStorage.getItem("AUTH_TOKEN"),
  user = null;

export const isAuthenticated = () => !!token;

export const useUser = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    (async () => setUser(await getUser()))();
  }, []);

  return user;
};

// #Note: Enable this block of code in production
export const getUser = () =>
  user
    ? Promise.resolve(user)
    : axios
        .get(`auth/check/`)
        .then((res) => res.data)
        .catch((e) => {
          localStorage.removeItem("AUTH_TOKEN");
          window.location.reload();
        });

export const authenticate = (code) =>
  axios.post(`auth/token/`, { code }).then((res) => {
    localStorage.setItem("AUTH_TOKEN", res.data.access);
    token = res.data.access;
    setupAxiosForToken(res.data.access);

    return true;
  });

export const logout = () => {
  localStorage.removeItem("AUTH_TOKEN");
  user = null;
  token = null;
  axios.defaults.headers.common["Authorization"] = undefined;
};

export const hasUserFullAccess = (user) => {
  return (
      (user?.is_active && user.business.subscription && moment(user.business.subscription.ends_at).isAfter(moment()))
      && (user.business.subscription.status === "active" || user.business.subscription.credit?.used < user.business.subscription.credit?.total)
  );
}

const setupAxiosForToken = (token) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

// Initial
export const setupAuth = () => {
  if (isAuthenticated()) {
    setupAxiosForToken(token);
  }
};
