import React, { useState } from "react";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Button from "../../common/Button";
import axios from "axios";
import { message } from "antd";
import BillingForm from "../../common/BillingForm";
import { STRIPE_KEY } from "../../constants";
import Payment3D from "./Payment3D";
import { InspectletSendApiError } from "../../common/Inspectlet";

const stripePromise = loadStripe(STRIPE_KEY);

const StripeForm = ({
  loading,
  withBilling,
  show3ds,
  src3ds,
  setShow3ds,
  setSrc3ds,
  setupIntentId,
  onDone,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [billing, setBilling] = useState({
    name: "",
    country: "UK",
    line1: "",
    line2: "",
    city: "",
    state: "",
    postal_code: "",
  });

  const ELEMENT_OPTIONS = {
    style: {
      base: {
        fontSize: "16px",
        backgroundColor: "#ffffff",
        color: "#424770",
        letterSpacing: "0.025em",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#9e2146",
      },
    },
    hidePostalCode: true,
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    stripe
      .createPaymentMethod({
        type: "card",
        card: cardElement,
      })
      .then(({ error, paymentMethod }) => {
        if (error) {
          alert("An error occurred");
        } else {
          onDone({
            payment_method_id: paymentMethod.id,
            ...(withBilling ? billing : {}),
          });
        }
      });
  };

  const handle3dSuccess = () => {
    setShow3ds(false);
    setSrc3ds(null);

    onDone({
      setup_intent_id: setupIntentId,
      ...(withBilling ? billing : {}),
    });
  };

  return (
    <>
      {show3ds && <Payment3D src={src3ds} onSuccess={handle3dSuccess} />}
      <div className={show3ds ? "hidden" : "block"}>
        <form onSubmit={handleSubmit}>
          <CardElement className="p-5 bg-white" options={ELEMENT_OPTIONS} />

          {withBilling && (
            <div className="border-t">
              <BillingForm value={billing} onChange={setBilling} />
            </div>
          )}

          <div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
            <Button
              className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
              loading={loading}
              type="success"
              disabled={!stripe}
              style={{ transition: "all .15s ease" }}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

const Create = ({ onCreated, withBilling }) => {
  const [loading, setLoading] = useState(false);
  const [show3ds, setShow3ds] = useState(false);
  const [src3ds, setSrc3ds] = useState(null);
  const [setupIntentId, setSetupIntentId] = useState(null);

  const createCard = (values) => {
    setLoading(true);

    axios
      .post("billing/update-payment-method/", values)
      .then((res) => onCreated(res.data))
      .catch((e) => {
        setLoading(false);
        InspectletSendApiError(e);

        let errorMessage = "An error occurred.";

        try {
          const data = e.response.data;

          if (data.message) {
            errorMessage = data.message;
          }

          if (data.setup_intent) {
            setSetupIntentId(data.setup_intent.setup_intent_id);
            setSrc3ds(data.setup_intent.next_action.redirect_to_url.url);
            setShow3ds(true);
            return;
          }

          setSetupIntentId(null);
        } catch (e) {
          setSetupIntentId(null);
          console.error(e);
        }

        message.error(errorMessage);
      });
  };

  return (
    <Elements stripe={stripePromise}>
      <StripeForm
        withBilling={withBilling}
        loading={loading}
        onDone={createCard}
        show3ds={show3ds}
        setShow3ds={setShow3ds}
        src3ds={src3ds}
        setSrc3ds={setSrc3ds}
        setupIntentId={setupIntentId}
      />
    </Elements>
  );
};

export default Create;
