import React, { useState } from "react";
import Card, { CardBody } from "./Card";
import Button from "./Button";
import Modal from "./Modal";
import { hasUserFullAccess } from "./auth";
import moment from "moment";
import axios from "axios";
import { message } from "antd";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import ReactCodeInput from "react-code-input";
import { InspectletSendApiError } from "./Inspectlet";

const CodeForm = ({ user, onSuccess, onCancel }) => {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (code.length !== 6) {
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const res = await axios.post("/auth/activate/", { token: code });
      onSuccess(res.data);
    } catch (err) {
      message.error(
        err.response?.data?.non_field_errors?.join(" ") ?? "An error occurred."
      );
      InspectletSendApiError(e);
    }
    setLoading(false);
  };

  return (
    <Modal
      onCancel={onCancel}
      title="Activate Account"
      hideFooter
      visible
      loading={loading}
      containerClass="relative my-6 mx-auto max-w-3xl max-h-screen"
    >
      <form onSubmit={handleSubmit} className="p-4">
        <ReactCodeInput
          type="text"
          fields={6}
          onChange={setCode}
          value={code}
        />

        <div>
          <Button
            type="primary"
            htmlType="submit"
            className="w-full justify-center p-4 mt-4"
            loading={loading}
            disabled={loading}
          >
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export const SubscriptionInfo = ({ user }) => {
  const [showCodeForm, setShowCodeForm] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);

  const handleOnClick = () => {
    window.location.href = "/pricing?action=subscription";
  };

  const handleResend = () => {
    setLoading(true);

    axios
      .post("/auth/resend_activation/", { phone_number: phoneNumber })
      .then((res) => setShowCodeForm(true))
      .catch((e) => {
        message.error(
          e.response?.data?.non_field_errors?.join(" ") ?? "An error occurred."
        );
        InspectletSendApiError(e);
      })
      .finally(() => setLoading(false));
  };

  if (!user) return null;

  if (hasUserFullAccess(user)) {
    if (user.business.subscription.status === "trialing") {
      const trialEndsAt = moment(user.business.trial_ends_at);
      const remainingDay = trialEndsAt.diff(moment(), "days");
      const daysSuffix = remainingDay > 1 ? "days" : "day";
      const used = user.business.subscription.credit.used;
      const total = user.business.subscription.credit.total;
      const remainingMinutes = new Date((total - used) * 1000)
        .toISOString()
        .substr(11, 8);
      return (
        <Card
          className="mt-5"
          title="Trial"
          type="info"
          headerActions={[
            <Button onClick={handleOnClick}>
              End trial now and unlock limits
            </Button>,
          ]}
        >
          <CardBody>
            You're currently in trial period. You have {remainingDay}{" "}
            {daysSuffix} or {remainingMinutes} minutes remaining in your trial.
          </CardBody>
        </Card>
      );
    } else {
      return null;
    }
  } else if (user.is_active) {
    return (
      <Card
        className="mt-5"
        title={
          user.business.subscription?.status === "trialing"
            ? "Trial"
            : "Account Info"
        }
        type="info"
        headerActions={[<Button onClick={handleOnClick}>Subscribe</Button>]}
      >
        <CardBody>
          {user.business.subscription?.status === "trialing"
            ? "Your trial period has expired. Please subscribe to a paid plan to continue using."
            : "You have no active subscription. Please subscribe to a paid plan to continue using."}
        </CardBody>
      </Card>
    );
  } else {
    return (
      <Card className="mt-5" title="Account Activation" type="info">
        <CardBody>
          <div className="text-base mb-4">
            To earn your credits, you need to verify your phone number. Fill
            your phone number and we will send a confirmation code to your
            phone:
          </div>
          <div className="flex items-center space-x-2">
            <div className="w-48 bg-white h-8 rounded overflow-hidden flex items-center pl-2">
              <PhoneInput
                placeholder="Enter phone number"
                value={phoneNumber}
                onChange={setPhoneNumber}
              />
            </div>
            <Button onClick={handleResend}>Send confirmation</Button>

            {showCodeForm && (
              <CodeForm
                onSuccess={() => window.location.reload()}
                onCancel={() => setShowCodeForm(false)}
              />
            )}
          </div>
        </CardBody>
      </Card>
    );
  }
};

export default SubscriptionInfo;
