import React from "react";

export function getPauseTag(pause) {
    let pauseTag = "";
		switch (pause) {
			case "short":
				pauseTag = "[P-S]";
				break;
			case "medium":
				pauseTag = "[P-M]";
				break;
			case "medium-long":
				pauseTag = "[P-ML]";
				break;
			case "long":
				pauseTag = "[P-L]";
				break;
			case "extra-long":
				pauseTag = "[P-EL]";
				break;
			default:
				break;
		}
		return pauseTag;
}

export function getEmotionTag(emotion) {
    let emotionTag = "";
		switch (emotion) {
			case "down":
				emotionTag = "[EM-D]";
				break;
			case "serious":
				emotionTag = "[EM-S]";
				break;
			case "up":
				emotionTag = "[EM-U]";
				break;
			default:
				break;
		}
		return emotionTag;
}

export function getIntensityTag(intensity) {
    let intensityTag = "";
		switch (intensity) {
			case "low":
				intensityTag = "[I-L]";
				break;
			case "medium":
				intensityTag = "[I-M]";
				break;
			case "high":
				intensityTag = "[I-H]";
				break;
			default:
				break;
		}
		return intensityTag;
}

export const getEmphasisClass = (emphasis) => emphasis ? "underline" : "";	




