import React, { useEffect } from "react";
import { AUTH_CLIENT, AUTH_URL } from "../../constants";

const Auth = () => {
  useEffect(() => {
    const redirectUri = encodeURIComponent(
      `${window.location.origin}/auth/login/finish`
    );

    window.location = `${AUTH_URL}/oauth/authorize?client_id=${AUTH_CLIENT}&redirect_uri=${redirectUri}&scope=read%2Cwrite&response_type=code`;
  }, []);

  return null;
};

export default Auth;
