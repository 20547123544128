import React, { useState, useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import classNames from "classnames";
import Spinner from "../../common/Spinner";
import axios from "axios";
import PageHeader from "../../common/PageHeader";
import Card, { CardBody } from "../../common/Card";
import { useUser } from "../../common/auth";
import Alert from "../../common/Alert";
import ApiError from "../../common/ApiError";
import { message } from "antd";
import { InspectletSendApiError } from "../../common/Inspectlet";

const MemberCreate = () => {
  const user = useUser();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    axios
      .post(`/subusers/invite/`, { email: email })
      .then(() => {
        message.success("Invitation has been sent.");
        setSuccess(true);
      })
      .catch((e) => {
        setLoading(false);
        setError(e);
        InspectletSendApiError(e);
      });
  };

  if (!user) return null;
  if (success) return <Redirect to="/members" />;

  if (user.business.subscription?.plan === "professional") {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <Alert type="warning" className="my-10" link="/pricing">
          Upgrade your account to Start-Up/Scale-Up to add more members
        </Alert>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <PageHeader title="Invite Members" className="mt-12" />
      <Card>
        <CardBody>
          {error && (
            <div className="mb-2">
              <ApiError error={error} />
            </div>
          )}

          <form onSubmit={handleSubmit} className="px-1 flex items-end">
            <div className="w-10/12">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Email address
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="h-10 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </div>

            <button
              type="submit"
              className={classNames(
                "ml-4 h-10 flex-grow justify-center inline-flex items-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out",
                loading && "opacity-75 pointer-events-none"
              )}
            >
              {loading && <Spinner size={4} color="white" />}
              <span className={loading && "ml-2"}>Invite</span>
            </button>
          </form>
        </CardBody>
      </Card>
    </div>
  );
};

export default MemberCreate;
