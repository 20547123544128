import { message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import BillingForm from "../../common/BillingForm";
import Button from "../../common/Button";
import { InspectletSendApiError } from "../../common/Inspectlet";

const Form = ({ onSaved, user }) => {
  const [loading, setLoading] = useState(false);
  const [billing, setBilling] = useState({
    name: user.business?.billing_name,
    line1: user.business?.billing_line1,
    line2: user.business?.billing_line2,
    city: user.business?.billing_city,
    state: user.business?.billing_state,
    country: user.business?.billing_country,
    postal_code: user.business?.billing_postal_code,
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    axios
      .post("billing/details/", billing)
      .then((res) => onSaved())
      .catch((e) => {
        setLoading(false);
        message.error("An error occurred.");
        InspectletSendApiError(e);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <BillingForm value={billing} onChange={setBilling} />

      <div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
        <Button
          className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
          loading={loading}
          type="success"
          style={{ transition: "all .15s ease" }}
        >
          Save
        </Button>
      </div>
    </form>
  );
};

export default Form;
