import React from "react";

export const getApiErrors = (error) => {
  let message = "Error";
  let errors = ["Oops, an error occured."];

  if (error.response) {
    switch (error.response.status) {
      case 404:
        message = "Resource not found";
        errors = [];
        break;
      case 401:
        message = "Not authorized";
        errors = [error.response.data.detail];
        break;
      case 400:
        message = "Bad request";
        errors = [];
        for (let key in error.response.data) {
          const keyErrors = error.response.data[key];
          const list = Array.isArray(keyErrors) ? keyErrors : [keyErrors];

          errors = errors.concat(
            list.map((e) => {
              return key === "non_field_errors" ? e : <span>{e}</span>;
            })
          );
        }
        break;
      default:
        break;
    }
  }

  return [message, errors];
}

export default function ApiError({ error }) {
  
  let [message, errors] = getApiErrors(error);

  const description = (
    <ul>
      {errors.map((error, i) => (
        <li key={i}>{error}</li>
      ))}
    </ul>
  );

  return (
    <div className="rounded-md bg-red-50 p-4 mb-3">
      <div className="flex">
        <div className="flex-shrink-0">
          <svg
            className="h-5 w-5 text-red-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="ml-3">
          <h3 className="text-sm leading-5 font-medium text-red-800">
            {message}
          </h3>
          {description}
        </div>
      </div>
    </div>
  );
}
