import React from "react";
import classNames from "classnames";
import { getPauseTag, getEmphasisClass } from "./wordTags";

export function Word({ 
    wordId,
    onClickWord, 
    isActive,
	editedWord,
	word,
	clickable,
	showDefaultData
	}) {

	const emphasisClass = getEmphasisClass(editedWord?.emphasis ? editedWord.emphasis : null);

	return (
		<span className={classNames("inline-block", emphasisClass)}>
			{clickable && (
				<a
					onClick={(event) => onClickWord(wordId)}
					className={classNames(
						"hover:text-gray-900",
						isActive && "bg-gray-200"
					)}
				>
					{word.text}
				</a>
			)}

			{!clickable && (
				<>
					<span
						className={classNames(
							"hover:text-gray-900",
							isActive && "bg-gray-200"
						)}
					>
						{word.text}
					</span>
				
				</>
			)}
			
			<div className={classNames("inline-block", emphasisClass)}>
				{ editedWord.pause && (
					<small className="text-red-700" style={{fontSize: "8px"}}>
						{getPauseTag(editedWord.pause)}
					</small>
				)}
			</div>&nbsp;
				
		</span>
	);
}

export default Word;
